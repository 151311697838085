import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import {
  Phone,
  UserTitleSelect,
  FirstName,
  LastName,
  Email,
  Company,
} from "../../../../../../parts/form-inputs";

import { getValidationSchema, handleSubmit } from "./utils";

import { Button, InputWrapper, TwoInRow } from "../../../../../../ui";
import { Wrapper, ButtonWrapper } from "./ProfileForm.styles";
import {
  events,
  useAmplitudeState,
} from "../../../../../../context/AmplitudeContext";
import { isEqual } from "../../../../../../utils";

export default function ProfileForm({ profile, dispatchSetProfile }) {
  const { amplitude } = useAmplitudeState();
  const { title, firstName, lastName, phone, company, email } = profile;
  const [showButton, setShowButton] = useState(false);
  const initialValues = {
    title: title ? title : "Undefined",
    firstName,
    lastName,
    phone,
    company,
    email,
  };

  const logProfileSave = () => {
    amplitude.logEvent(events.profile_save);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      handleSubmit(values, { formik, dispatchSetProfile, logProfileSave });
    },
  });

  useEffect(() => {
    const equal = isEqual(initialValues, formik.values);

    if (equal) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [formik.values, initialValues]);

  return (
    <Wrapper>
      <form data-test="profile-form" onSubmit={formik.handleSubmit}>
        <TwoInRow>
          <div>
            <UserTitleSelect formik={formik} />
          </div>
          <div>
            <Company formik={formik} />
          </div>
          <div>
            <FirstName formik={formik} />
          </div>
          <div>
            <LastName formik={formik} />
          </div>
          <div>
            <Email formik={formik} disabled />
          </div>
          <div>
            <Phone formik={formik} />
          </div>
        </TwoInRow>

        {formik.errors.common ? (
          <div data-test="error-message">{formik.errors.common}</div>
        ) : null}

        {showButton ? (
          <ButtonWrapper>
            <Button
              type="success"
              fullWidth
              disabled={formik.isSubmitting}
              dataTestAttr="button-save"
            >
              Save
            </Button>
          </ButtonWrapper>
        ) : null}
      </form>
    </Wrapper>
  );
}
