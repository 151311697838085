import {
  LOGOUT,
  SET_LOGOUT,
  REQUEST_ADDRESSES,
  REQUEST_PROFILE,
  REQUEST_USER,
  SET_ADDRESSES,
  SET_ADDRESSES_LOADING,
  SET_PROFILE,
  SET_PROFILE_LOADING,
  SET_USER,
  SET_USER_LOADING,
  SET_USER_READY,
  SET_REFRESH_TOKEN,
  SET_PROFILE_ERROR,
  SET_IS_AUTH,
  SET_REDIRECT_TO,
  SET_CURRENT_USER,
  REQUEST_ADDRESSES_AND_PERSONS,
  SET_ADDRESSES_AND_PERSONS_LOADING,
  SET_ADDRESSES_AND_PERSONS,
  ADD_PERSON,
  SET_PERSONS_LOADING,
  REQUEST_ADD_PERSON,
  REQUEST_ADD_ADDRESS,
  ADD_ADDRESS,
  REQUEST_DELETE_ADDRESS,
  DELETE_ADDRESS,
  REQUEST_EDIT_ADDRESS,
  EDIT_ADDRESS,
  SET_ADDRESSES_ERROR,
  REQUEST_PERSONS,
  SET_PERSONS_ERROR,
  EDIT_PERSON,
  REQUEST_EDIT_PERSON,
  DELETE_PERSON,
  REQUEST_DELETE_PERSON,
  SET_PERSONS,
} from "./types";
import { PayloadActionWithEvents } from "../redux.types";

const requestUser = () => {
  return {
    type: REQUEST_USER,
  };
};

const requestAddresses = () => {
  return {
    type: REQUEST_ADDRESSES,
  };
};

const requestProfile = () => {
  return {
    type: REQUEST_PROFILE,
  };
};

const setUser = (info) => {
  return {
    type: SET_USER,
    payload: info,
  };
};

const setUserReady = (isReady) => {
  return {
    type: SET_USER_READY,
    payload: isReady,
  };
};

const setIsAuth = (isAuth) => {
  return {
    type: SET_IS_AUTH,
    payload: isAuth,
  };
};

const setRefreshToken = (refreshToken) => {
  return {
    type: SET_REFRESH_TOKEN,
    payload: refreshToken,
  };
};

const setProfile = (payload) => {
  return {
    type: SET_PROFILE,
    payload,
  };
};

const setAddresses = (payload) => {
  return {
    type: SET_ADDRESSES,
    payload,
  };
};

const logout = (payload) => {
  return {
    type: LOGOUT,
    payload,
  };
};

const setLogout = (payload) => {
  return {
    type: SET_LOGOUT,
    payload,
  };
};

const setRedirectTo = (payload) => {
  return {
    type: SET_REDIRECT_TO,
    payload,
  };
};

const setUserLoading = (isLoading) => {
  return {
    type: SET_USER_LOADING,
    payload: isLoading,
  };
};

const setProfileLoading = (isLoading) => {
  return {
    type: SET_PROFILE_LOADING,
    payload: isLoading,
  };
};

const setProfileError = (message) => {
  return {
    type: SET_PROFILE_ERROR,
    payload: message,
  };
};

const setCurrentUser = (currentUser) => {
  return {
    type: SET_CURRENT_USER,
    payload: currentUser,
  };
};

// V2

const requestAddressesAndPersons = () => {
  return {
    type: REQUEST_ADDRESSES_AND_PERSONS,
  };
};

const setAddressesAndPersons = (payload) => {
  return {
    type: SET_ADDRESSES_AND_PERSONS,
    payload,
  };
};

const setAddressesAndPersonsLoading = (isLoading) => {
  return {
    type: SET_ADDRESSES_AND_PERSONS_LOADING,
    payload: isLoading,
  };
};

const requestAddPerson = (payload) => {
  return {
    type: REQUEST_ADD_PERSON,
    payload,
  };
};

const addPerson = (payload) => {
  return {
    type: ADD_PERSON,
    payload,
  };
};

const setAddressesLoading = (isLoading) => {
  return {
    type: SET_ADDRESSES_LOADING,
    payload: isLoading,
  };
};

const requestAddAddress = (
  payload: PayloadActionWithEvents<{ newAddressData: any }>
) => {
  return {
    type: REQUEST_ADD_ADDRESS,
    payload,
  };
};

const addAddress = (payload) => {
  return {
    type: ADD_ADDRESS,
    payload,
  };
};

const requestDeleteAddress = (payload) => {
  return {
    type: REQUEST_DELETE_ADDRESS,
    payload,
  };
};

const deleteAddress = (payload) => {
  return {
    type: DELETE_ADDRESS,
    payload,
  };
};

const requestEditAddress = (payload) => {
  return {
    type: REQUEST_EDIT_ADDRESS,
    payload,
  };
};

const editAddress = (payload) => {
  return {
    type: EDIT_ADDRESS,
    payload,
  };
};

const setAddressesError = (errorMessage) => {
  return {
    type: SET_ADDRESSES_ERROR,
    payload: errorMessage,
  };
};

const requestPersons = (payload) => {
  return {
    type: REQUEST_PERSONS,
    payload,
  };
};

const setPersons = (payload) => {
  return {
    type: SET_PERSONS,
    payload,
  };
};

const requestDeletePerson = (payload) => {
  return {
    type: REQUEST_DELETE_PERSON,
    payload,
  };
};

const deletePerson = (payload) => {
  return {
    type: DELETE_PERSON,
    payload,
  };
};

const requestEditPerson = (payload) => {
  return {
    type: REQUEST_EDIT_PERSON,
    payload,
  };
};

const editPerson = (payload) => {
  return {
    type: EDIT_PERSON,
    payload,
  };
};

const setPersonsLoading = (isLoading) => {
  return {
    type: SET_PERSONS_LOADING,
    payload: isLoading,
  };
};

const setPersonsError = (errorMessage) => {
  return {
    type: SET_PERSONS_ERROR,
    payload: errorMessage,
  };
};

export default {
  requestUser,
  setUser,
  setUserLoading,
  setProfileLoading,
  setLogout,
  logout,
  setProfile,
  setUserReady,
  setAddresses,
  requestProfile,
  requestAddresses,
  setRefreshToken,
  setProfileError,
  setIsAuth,
  setRedirectTo,
  setCurrentUser,

  // V2
  requestAddressesAndPersons,
  setAddressesAndPersons,
  setAddressesAndPersonsLoading,

  requestAddAddress,
  addAddress,
  setAddressesLoading,
  requestDeleteAddress,
  deleteAddress,
  requestEditAddress,
  editAddress,
  setAddressesError,

  requestPersons,
  setPersons,
  requestAddPerson,
  addPerson,
  requestEditPerson,
  editPerson,
  requestDeletePerson,
  deletePerson,
  setPersonsLoading,
  setPersonsError,
};
