import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "redux/user";
import { ContactPersonPayload } from "shared/types/user";
import ContactPersons from "./ContactPersons";

const ContactPersonsContainer = () => {
  const contactPersons = useSelector(userSelectors.selectContactPersons);
  const contactPersonsLoading = useSelector(
    userSelectors.selectContactPersonsLoading
  );
  const [isOpenAddContactPerson, setIsOpenAddContactPerson] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contactPersons) {
      dispatch(userActions.requestPersons());
    }
  }, [contactPersons, dispatch]);

  const handleToggleModalOpen = () => {
    dispatch(userActions.setPersonsError(null));
    setIsOpenAddContactPerson((prevState) => !prevState);
  };

  const handleAddContactPerson = (formValues: ContactPersonPayload) => {
    dispatch(
      userActions.requestAddPerson({
        newPersonData: formValues,
        onSuccess: handleToggleModalOpen,
      })
    );
  };

  return (
    <ContactPersons
      isOpenAddContactPerson={isOpenAddContactPerson}
      setIsOpenAddContactPerson={handleToggleModalOpen}
      contactPersonsLoading={contactPersonsLoading}
      contactPersons={contactPersons}
      handleAddContactPerson={handleAddContactPerson}
    />
  );
};
export default ContactPersonsContainer;
