import { addressTypes } from "constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "redux/user";
import { AddressEntity } from "shared/types/user";
import {
  events,
  useAmplitudeState,
} from "../../../../context/AmplitudeContext";
import Addresses from "./Addresses";

const AddressesContainer = () => {
  const addresses = useSelector(userSelectors.selectAddresses);
  const addressesLoading = useSelector(userSelectors.selectAddressesLoading);
  const [isOpenAddAddress, setIsOpenAddAddress] = useState(false);
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();

  useEffect(() => {
    if (!addresses) {
      dispatch(userActions.requestAddresses());
    }
  }, [addresses, dispatch]);

  const handleToggleModalOpen = () => {
    dispatch(userActions.setAddressesError(null));
    setIsOpenAddAddress((prevState) => !prevState);
  };

  const logAction = (type: string) => {
    amplitude.logEvent(events.profile_address_add, { [type]: true });
  };

  const handleAddAddress = (formValues: AddressEntity) => {
    dispatch(
      userActions.requestAddAddress({
        newAddressData: formValues,
        onSuccess: handleToggleModalOpen,
      })
    );
    logAction(addressTypes.SHIPPING);
  };

  return (
    <Addresses
      isOpenAddAddress={isOpenAddAddress}
      setIsOpenAddAddress={handleToggleModalOpen}
      addressesLoading={addressesLoading}
      addresses={addresses}
      handleAddAddress={handleAddAddress}
    />
  );
};

export default AddressesContainer;
