import { OrderEventsOrderStatusTypes } from "../../../../../shared/types/Orders.types";

export const getTagTypesBasedOnOrderStatus = (
  // orderEventTypes: keyof OrderEventsOrderStatusTypes[]
  orderEventTypes: any[]
) => {
  let tagType = "primary";

  if (
    orderEventTypes?.some(
      (orderEventType) =>
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_SHIPPED ||
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_COMPLETED ||
        orderEventType.status ===
          OrderEventsOrderStatusTypes.ORDER_PARTIALLY_SHIPPED
    )
  ) {
    tagType = "success";
  } else if (
    orderEventTypes?.some(
      (orderEventType) =>
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_RETURNED ||
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
    )
  ) {
    tagType = "danger";
  }

  return tagType;
};

export const mapOrderEventTypes = (
  // orderEventTypes: keyof OrderEventsOrderStatusTypes[]
  orderEventTypes: any[]
) => {
  let result = "New";

  if (
    orderEventTypes?.some(
      (orderEventType) =>
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_SHIPPED ||
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_COMPLETED
    )
  ) {
    result = "Shipped";
  } else if (
    orderEventTypes?.some(
      (orderEventType) =>
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_RETURNED ||
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
    )
  ) {
    result = "Cancelled";
  } else if (
    orderEventTypes?.some(
      (orderEventType) =>
        orderEventType.status === OrderEventsOrderStatusTypes.ORDER_PROCESSING
    )
  ) {
    result = "Processing";
  }

  return result;
};
