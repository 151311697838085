import React, { useEffect, useState } from "react";
import { useQuery } from "../../../../../hooks";
import { toast } from "react-toastify";
import { fetchOrders } from "../api/Orders.api";
import { OrderListItem } from "../lib/Orders.types";
import Orders from "./Orders";
import OrdersUpdateModalContainer from "../../../../../shared/ui/OnBoard/OrdersUpdateModal/OrdersUpdateModal.container";

const OrdersContainer = () => {
  const query = useQuery();

  const [ordersList, setOrdersList] = useState([]);

  const [pagination, setPagination] = useState({
    page: parseInt(query.get("page")) || 0,
    pageSize: 10,
    pagesCount: 3,
  });

  const {
    data: ordersListResponse,
    refetch,
    isLoading,
  } = fetchOrders(pagination);

  const queryPage = query.get("page");

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      page: queryPage ? parseInt(queryPage) : 0,
    }));
  }, [queryPage]);

  useEffect(
    () => {
      if (ordersListResponse && ordersListResponse[0]) {
        const enrichedList = ordersListResponse[0].results.map(
          (orderItem: OrderListItem) => {
            const detailsLink = `/profile/orders/${orderItem.storeId}?fromPage=${pagination.page}`;

            return {
              ...orderItem,
              detailsLink,
            };
          }
        );
        setOrdersList(enrichedList);
        setPagination({
          page: ordersListResponse[0].pagination.page,
          pageSize: ordersListResponse[0].pagination.pageSize,
          pagesCount: ordersListResponse[0].pagination.pagesCount,
        });
      }

      if (ordersListResponse && ordersListResponse[1]) {
        toast.error(ordersListResponse[1]);
      }
    },
    // eslint-disable-next-line
    [ordersListResponse]);

  // @TODO Its a hotfix for issue https://enamine.atlassian.net/browse/SHOP-5708,
  // @TODO when we go to Orders page it provides cached data,
  // @TODO we have to figure out how to invalidate cache
  useEffect(
    () => {
      void refetch();
    },
    // eslint-disable-next-line
    []);

  return (
    <>
      <OrdersUpdateModalContainer />
      <Orders
        ordersList={ordersList}
        isLoading={isLoading}
        pagination={pagination}
      />
    </>
  );
};

export default OrdersContainer;
