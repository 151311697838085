import { ProductTypesV2 } from "constants/productTypes";
import { PaymentDetails, PricingDetails } from "./pricing";
import { ProductDetails, ProductWeight } from "./product";

export type BucketItem = {
  addedToBucket: AddedToBucketInfo;
  product: ProductDetails;
  prices: PricingDetails;
};

export type AddedToBucketInfo = {
  uuid: string;
  weight: ProductWeight;
  count: number;
  created: number[];
  updated: number[];
  pay: PaymentDetails;
  deliveryDecision: DeliveryDecision;
};

export enum DeliveryDecision {
  DANGEROUS = "DANGEROUS",
  NON_DANGEROUS = "NON_DANGEROUS",
}

export type BucketItemWithImage = BucketItem & {
  image: {
    url: string;
    code: string;
    catalog: ProductTypesV2;
  };
};
