import styled from "styled-components";
import { accentColor } from "../colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 20px;

  &:not(:last-child) {
    margin-right: 8px;
  }

  ${(props) => {
    if (props.active) {
      return `
        background-color: ${accentColor};
        color: #fff;
      `;
    } else {
      return `
        &:hover {
          background-color: ${accentColor};
          color: #fff;
        }
      `;
    }
  }};
`;
