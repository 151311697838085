import React from "react";
import { InputWrapper, Title } from "./AdditionalInfo.styled";
import { SectionWrapper } from "../../../../Checkout.styled";
import { InputLabelTypography } from "../../../../../../shared/ui/CreatePerson/CreatePerson.styled";
import { TextField } from "@mui/material";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";

type AdditionalInfoProps = {
  formik: FormikProps<FormikValues>;
};

const AdditionalInfo = ({ formik }: AdditionalInfoProps) => {
  return (
    <SectionWrapper>
      <Title>Additional Info</Title>
      <InputWrapper>
        <InputLabelTypography variant="body1">PO Number</InputLabelTypography>
        <TextField
          fullWidth
          id="purchaseOrderNumber"
          name="purchaseOrderNumber"
          placeholder="Enter PO Number"
          value={formik.values.purchaseOrderNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.purchaseOrderNumber &&
            Boolean(formik.errors.purchaseOrderNumber)
          }
          helperText={
            formik.touched.purchaseOrderNumber &&
            formik.errors.purchaseOrderNumber
          }
        />
      </InputWrapper>

      <InputWrapper>
        <InputLabelTypography variant="body1">Comment</InputLabelTypography>
        <TextField
          fullWidth
          id="comment"
          name="comment"
          placeholder="Enter comment"
          value={formik.values.comment}
          onChange={formik.handleChange}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment}
        />
      </InputWrapper>
    </SectionWrapper>
  );
};

export default AdditionalInfo;
