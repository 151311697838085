import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import OrdersUpdateModal from "./OrdersUpdateModal";

const OrdersUpdateModalContainer = () => {
  const [ordersUpdateModalOpen, setOrdersUpdateModalOpen] = useState(false);

  const onClose = () => {
    Cookies.set("user-checked-orders", "true", { expires: 365 * 20 });
    setOrdersUpdateModalOpen(false);
  };

  useEffect(
    () => {
      const userCheckedOrders = Cookies.get("user-checked-orders");

      if (userCheckedOrders !== "true") {
        setOrdersUpdateModalOpen(true);
      }
    },
    // eslint-disable-next-line
    []);

  return <OrdersUpdateModal open={ordersUpdateModalOpen} onClose={onClose} />;
};

export default OrdersUpdateModalContainer;
