import {
  ADD_ADDRESS,
  ADD_PERSON,
  DELETE_ADDRESS,
  DELETE_PERSON,
  EDIT_ADDRESS,
  EDIT_PERSON,
  SET_ADDRESSES,
  SET_ADDRESSES_AND_PERSONS,
  SET_ADDRESSES_AND_PERSONS_LOADING,
  SET_ADDRESSES_ERROR,
  SET_ADDRESSES_LOADING,
  SET_CURRENT_USER,
  SET_IS_AUTH,
  SET_LOGOUT,
  SET_PERSONS,
  SET_PERSONS_ERROR,
  SET_PERSONS_LOADING,
  SET_PROFILE,
  SET_PROFILE_ERROR,
  SET_PROFILE_LOADING,
  SET_REDIRECT_TO,
  SET_REFRESH_TOKEN,
  SET_USER,
  SET_USER_LOADING,
  SET_USER_READY,
} from "./types";

const initialState = {
  isAuth: false,
  user: null,
  userLoading: null,
  userReady: false,
  profile: null,
  profileLoading: null,
  profileError: "",
  refreshToken: null,
  redirectTo: null,
  current: null,

  addresses: null,
  addressesLoading: false,
  addressesError: null,

  persons: null,
  personsLoading: false,
  personsError: null,

  searchOptions: { includeAdditionalResults: false },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_READY:
      return {
        ...state,
        userReady: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...initialState,
        redirectTo: action.payload?.redirectTo,
      };
    case SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        userLoading: action.payload,
      };
    case SET_PROFILE_LOADING:
      return {
        ...state,
        profileLoading: action.payload,
      };
    case SET_PROFILE_ERROR:
      return {
        ...state,
        profileError: action.payload,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        current: action.payload,
      };

    // V2
    case SET_ADDRESSES_AND_PERSONS:
      return {
        ...state,
        addresses: action.payload?.addresses,
        persons: action.payload?.persons,
      };
    case SET_ADDRESSES_AND_PERSONS_LOADING: {
      return {
        ...state,
        personsLoading: action.payload,
      };
    }
    case SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };
    case ADD_ADDRESS: {
      return {
        ...state,
        addresses: [...state.addresses, action.payload],
      };
    }
    case DELETE_ADDRESS: {
      return {
        ...state,
        addresses: state.addresses.filter(
          (address) => address.uuid !== action.payload
        ),
      };
    }
    case EDIT_ADDRESS: {
      return {
        ...state,
        addresses: state.addresses.map((address) =>
          address.uuid === action.payload.oldUuid
            ? {
                ...action.payload.editedAddress,
              }
            : address
        ),
      };
    }
    case SET_ADDRESSES_LOADING:
      return {
        ...state,
        addressesLoading: action.payload,
      };
    case SET_ADDRESSES_ERROR:
      return {
        ...state,
        addressesError: action.payload,
      };
    case ADD_PERSON: {
      return {
        ...state,
        persons: [...state.persons, action.payload],
      };
    }
    case SET_PERSONS: {
      return {
        ...state,
        persons: action.payload,
      };
    }
    case SET_PERSONS_LOADING: {
      return {
        ...state,
        personsLoading: action.payload,
      };
    }
    case DELETE_PERSON: {
      return {
        ...state,
        persons: state.persons.filter(
          (person) => person.uuid !== action.payload
        ),
      };
    }
    case EDIT_PERSON: {
      return {
        ...state,
        persons: state.persons.map((person) =>
          person.uuid === action.payload.oldUuid
            ? {
                ...action.payload.editedPerson,
              }
            : person
        ),
      };
    }
    case SET_PERSONS_ERROR:
      return {
        ...state,
        personsError: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
