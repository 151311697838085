import { rootPages } from "constants";
import React from "react";
import { Currency } from "shared/types/pricing";
import { Button, CustomizedTooltip, Loader, Paper, Title } from "ui";
import {
  ButtonWrapper,
  InfoCell,
  InfoRow,
  InfoWrapper,
  LoadingWrapper,
  Wrapper,
} from "./Summary.styled";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";
import { events, useAmplitudeState } from "../../../context/AmplitudeContext";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../redux/user";
import { InfoIconWrapper } from "../CartItem/CartItem.styled";
import { InfoIcon2 } from "../../../ui/icons";

type SummaryProps = {
  subtotal: string;
  loading: boolean;
  discount: number;
  currency: Currency;
  hasFeasibleCompounds?: boolean;
};

const Summary = ({
  hasFeasibleCompounds,
  subtotal,
  loading,
  discount,
  currency,
}: SummaryProps) => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const isAuth = useSelector(userSelectors.selectIsAuth);

  const handleClickCheckout = () => {
    console.log("amplitude!!!");
  };

  const handleNavigateToQuote = () => {
    // @TODO Rethink this logic
    if (isAuth) {
      history.push(`/${rootPages.CHECKOUT}`, { isQuote: true });
      amplitude.logEvent(events.cart_request_quote_click);
    } else {
      history.push(`/${rootPages.CHECKOUT}`, { isQuote: true });
    }
  };

  const isDiscount = typeof discount === "number";

  return (
    <Wrapper>
      {loading ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : null}
      <Paper smallPaddings>
        <Title>Summary</Title>

        <InfoWrapper>
          <InfoRow>
            <InfoCell>
              <span>Subtotal</span>
              {hasFeasibleCompounds && (
                <CustomizedTooltip
                  title="Order includes feasible compounds which
will be confirmed by our sales representative"
                  placement="bottom"
                >
                  <InfoIconWrapper>
                    <InfoIcon2 width={20} height={20} />
                  </InfoIconWrapper>
                </CustomizedTooltip>
              )}
            </InfoCell>

            <span>
              <b>{subtotal}</b>
            </span>
          </InfoRow>
        </InfoWrapper>

        <Divider />

        <InfoWrapper>
          <InfoRow>
            <span>Delivery</span>
            <span>Calculated at checkout</span>
          </InfoRow>
          {isDiscount && (
            <InfoRow>
              <span>Discount</span>
              <span>
                {discount} {currency.abbr}
              </span>
            </InfoRow>
          )}
        </InfoWrapper>

        <ButtonWrapper>
          <Button
            type="outlined"
            fullWidth
            onClick={handleNavigateToQuote}
            bold
            data-test="request_quote_button"
            dataTestAttr="request_quote_button"
            href=""
            className=""
          >
            Request Quote
          </Button>
        </ButtonWrapper>

        <Button
          fullWidth
          onClick={handleClickCheckout}
          bold
          href={`/${rootPages.CHECKOUT}`}
        >
          Checkout
        </Button>
      </Paper>
    </Wrapper>
  );
};

export default Summary;
