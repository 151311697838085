import styled from "styled-components";
import { dangerColor } from "../../../../../../ui/colors";
import { Typography } from "@mui/material";

export const Wrapper = styled.div`
  ${({ disable }) => {
    if (disable) {
      return `
        opacity: 0.8;
        pointer-events: none;
      `;
    }
  }}
`;

export const ButtonWrapper = styled.div`
  padding-top: 10px;
`;

export const CardInner = styled.div`
  padding: 5px 7px 5px 14px;

  svg {
    max-width: 100%;
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 18px;
`;

export const CardErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 6px;
  color: ${dangerColor};
  margin-bottom: 12px;
  & svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${dangerColor};
    }
  }
`;

export const CardErrorTypo = styled(Typography)`
  font-size: 14px;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 500;

  b {
    margin-right: 12px;
  }
`;
