import React, { useEffect, createRef } from "react";
import { common } from "../../constants";

const SubmitPaymentForm = () => {
  const formRef = createRef();
  const paymentToken = localStorage.getItem("payment-token");
  const actionUrl =  `${common.PAYMENT_URL}`

  const handleSubmit = () => {
    localStorage.removeItem("payment-token");
    formRef.current.submit();
  };

  useEffect(() => {
    if (paymentToken) {
      handleSubmit();
    }
  }, [paymentToken]);

  return (
    <form ref={formRef} action={actionUrl} method="post">
      <input type="hidden" name="token" value={paymentToken} />
    </form>
  );
};

export default SubmitPaymentForm;
