import React from "react";
import { Button } from "ui/index";
import {
  Wrapper,
  ButtonWrapper,
  CardInner,
  CardTitle,
  CardWrapper,
  CardErrorWrapper,
  CardErrorTypo,
} from "./Payment.styled";
import {
  DefaultPaymentMethod,
  PaymentMethodIds,
} from "../../../../model/Checkout.types";
import RadioCardV2 from "../../../../../../shared/ui/RadioCardV2/RadioCardV2";
import { Typography } from "@mui/material";
import { InfoIcon2 } from "../../../../../../ui/icons";

type PaymentProps = {
  onSubmit: VoidFunction;
  selectedPaymentMethod: PaymentMethodIds;
  handleSelect: (paymentMethod: PaymentMethodIds) => void;
  paymentMethods: DefaultPaymentMethod[];
  isLoading?: boolean;
};

const Payment = ({
  onSubmit,
  selectedPaymentMethod,
  handleSelect,
  paymentMethods,
  isLoading,
}: PaymentProps) => {
  return (
    <Wrapper disable={isLoading}>
      {paymentMethods.map((paymentMethod, index) => {
        const isActive =
          paymentMethod.id.toUpperCase() ===
          selectedPaymentMethod.toUpperCase();

        return (
          <CardWrapper key={index}>
            <RadioCardV2
              isDisabled={paymentMethod.disabled}
              isActive={isActive}
              handleChange={() => {
                handleSelect(
                  paymentMethod?.id.toUpperCase() as PaymentMethodIds
                );
              }}
            >
              <CardInner data-element="card-wrapper">
                {paymentMethod.disabled && (
                  <CardErrorWrapper>
                    <InfoIcon2 />
                    <CardErrorTypo variant="body1">
                      Card payment disabled as price for one or more products
                      needs to be confirmed.
                    </CardErrorTypo>
                  </CardErrorWrapper>
                )}
                <CardTitle>
                  <b>{paymentMethod.title}</b> {paymentMethod.icon}
                </CardTitle>
                <Typography variant="body1">{paymentMethod.text}</Typography>
              </CardInner>
            </RadioCardV2>
          </CardWrapper>
        );
      })}

      <ButtonWrapper>
        <Button onClick={onSubmit} size="small" fullWidth>
          Next
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Payment;
