import React from "react";
import { useDispatch } from "react-redux";
import { CartMessage } from "ui/cart-message/cart-message";
import { useAmplitudeState } from "../../../../../context/AmplitudeContext";
import { checkoutActions } from "../../../../../redux/checkout";
import { AmplitudeEvents } from "../../../../../context/AmplitudeEvents";

type ThankProps = {
  title?: string;
  description?: string;
};

const Thank = ({ title, description }: ThankProps) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();

  const handleOrdersClick = () => {
    dispatch(checkoutActions.setInitial());
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_ORDERS);
  };

  const handleSearchCluck = () => {
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_SEARCH);
  };

  return (
    <CartMessage variant="success">
      <CartMessage.Title>
        {title || "Thank you, your order has been placed"}
      </CartMessage.Title>
      <CartMessage.Description>
        {description ||
          `Wait for order confirmation on your email address. \n
          We are already working as fast as we can to process and ship it to you.`}
      </CartMessage.Description>
      <CartMessage.ActionWrapper>
        <CartMessage.Link to={"/profile/orders"} onClick={handleOrdersClick}>
          Check my orders
        </CartMessage.Link>
        <CartMessage.Link to={"/"} onClick={handleSearchCluck}>
          Search compounds
        </CartMessage.Link>
      </CartMessage.ActionWrapper>
    </CartMessage>
  );
};

export default Thank;
