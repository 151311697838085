import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { events, useAmplitudeState } from "../context/AmplitudeContext";
import useQuery from "./useQuery";

function useCatchNewUser() {
  const query = useQuery();
  const history = useHistory();
  const { amplitude } = useAmplitudeState();

  useEffect(() => {
    const redirectFrom = query.get("redirectFrom");

    if (redirectFrom === "enaminestore") {
      amplitude.logEvent(events.new_design_enroll);
      amplitude.setUserProperties({
        beta_user: true,
      });

      setTimeout(() => {
        history.push("/");
      }, 300);
    }
  }, []);
}

export default useCatchNewUser;
