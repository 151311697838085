import styled from "styled-components";
import { accentColor, darkBlue } from "../../../ui/colors";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";

export const DialogTitleStyled = styled(DialogTitle)`
  padding: 28px 28px 14px 28px;
  // @TODO Remove after resolve global styles
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: ${darkBlue};
`;

export const DialogContentStyled = styled(Box)`
  padding: 14px 28px 28px 28px;
`;

export const CloseButtonStyled = styled(IconButton)`
  position: absolute;
  top: 26px;
  right: 28px;
  padding: 0;
  z-index: 21;
  line-height: 1;

  svg path {
    fill: ${darkBlue};
  }

  &:hover {
    background-color: transparent;
    svg path {
      fill: ${accentColor};
    }
  }
`;

export const InnerContentWrapper = styled.div`
  padding-top: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonWrapper = styled.div`
  padding-left: 16px;
`;

export const DialogCoreButtonsRight = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
