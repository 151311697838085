/* eslint-disable react-hooks/exhaustive-deps */
import animateScrollTo from "animated-scroll-to";
import { useDownloadSdfFromCart, usePagination } from "hooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencySelectors } from "redux/currency";
import { userSelectors } from "redux/user";
import { BucketItemWithImage } from "shared/types/cartItem";
import superCartActions from "../../redux/actions";

import superCartSelectors from "../../redux/selectors";
import { calculateTotal, generateSubtotal } from "../../redux/utils";
import CartPage from "../CartPage";
import useCartHandlers from "../../hooks/useCartHandlers";

const PAGE_SIZE = 10;

const CartPageContainer = () => {
  const dispatch = useDispatch();
  const superCart = useSelector(superCartSelectors.selectSuperCart);
  const currency = useSelector(currencySelectors.selectCurrent);
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const [curPage, setCurPage] = useState(1);
  const [list, setList] = useState<BucketItemWithImage[]>([]);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRest, setLoadingRest] = useState(true);
  const [isModalGroupUpdateOpen, setIsModalGroupUpdateOpen] = useState(false);

  const subtotal = generateSubtotal({
    price: calculateTotal(superCart.itemsWithImages),
    abbr: currency?.abbr,
  });

  // @TODO Check logic and remove/update this code
  const cartInfoLoading = superCart.itemsWithImagesLoading;

  const cartItems = superCart.itemsWithImages;

  const downloadSdf = useDownloadSdfFromCart({
    currency: currency?.abbr,
  });

  const [pagination] = usePagination({
    total: superCart.itemsWithImages?.length,
    pageSize: PAGE_SIZE,
    curPage,
  });

  const discount = superCart?.cartInfo?.data?.discount;

  const {
    handleDelete,
    handleChangeCount,
    handleChangeWeight,
    handleClearCart,
  } = useCartHandlers();

  const handleClickPagination = (page) => {
    setCurPage(page);
  };

  useEffect(() => {
    /*
            Decrease curPage, if pagination total more than cart total.
            This functionality actual, when user delete last item from last
            paginated page. For example: current page is 8 and the total
            items on this page is 1. After deleting this 1 item you 
            expect to see the current page is 7.
        */
    if ((curPage - 1) * PAGE_SIZE >= superCart.itemsWithImages?.length) {
      setCurPage((state) => {
        if (state > 1) {
          return state - 1;
        } else {
          return 1;
        }
      });
    }
  }, [superCart.itemsWithImages]);

  useEffect(() => {
    if (init) {
      setLoading(true);
      animateScrollTo(0);
    }
  }, [curPage]);

  useEffect(() => {
    if (init) {
      const start = (curPage - 1) * PAGE_SIZE;
      const currentList = cartItems
        ? cartItems.slice(start, start + PAGE_SIZE)
        : [];
      // @TODO BE has to implement cur param to refetch data for cart

      setList(currentList);
      setLoading(false);
      setLoadingRest(false);
    }
  }, [JSON.stringify(cartItems), curPage, currency?.abbr, init]);

  useEffect(() => {
    if (isAuth) {
      setInit(true);
      dispatch(superCartActions.requestItemsAndImages());
    } else {
      setInit(true);
    }
  }, [isAuth, currency]);

  const handleToggleGroupUpdateModal = () => {
    setIsModalGroupUpdateOpen((prevState) => !prevState);
  };
  const isAnyItemInquery = cartItems?.some(
    (item) => !item?.addedToBucket?.pay.priceExists
  );

  return (
    <CartPage
      totalItemsCount={cartItems?.length}
      allItems={cartItems}
      pageItems={list}
      subtotal={subtotal}
      pagination={pagination}
      loading={loading}
      loadingRest={loadingRest}
      currency={currency}
      handleClickPagination={handleClickPagination}
      handleChangeWeight={handleChangeWeight}
      handleChangeCount={handleChangeCount}
      handleDelete={handleDelete}
      handleClearCart={handleClearCart}
      handleGroupUpdate={handleToggleGroupUpdateModal}
      isModalGroupUpdateOpen={isModalGroupUpdateOpen}
      downloadSdf={downloadSdf}
      discount={discount}
      cartInfoLoading={cartInfoLoading}
      isAnyItemInquery={isAnyItemInquery}
    />
  );
};

export default CartPageContainer;
