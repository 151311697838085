const endpoints = {
  AUTH_SIGNIN: "auth/as-customer/sign-in",
  AUTH_SIGNUP: "auth/as-customer/sign-up",
  AUTH_LOGOUT: "auth/as-customer/log-out",
  AUTH_REFRESH: "auth/refresh",
  AUTH_CHECK_AUTHORIZE: "auth/checkAuthorize",
  AUTH_RESET_PASSWORD: "auth/reset-password",
  USER_ME: "user/me",
  SEARCH_ESTIMATE: "search/estimate",
  SEARCH_IS_EXISTS_SEARCH_RESULT: "search/isExistsSearchResult",
  CATALOG: "catalog",
  CATALOG_PRODUCTS: "catalog/products",
  CATALOG_PRICE: "catalog/price",
  CATALOG_RELATED: "catalog/relatedProducts",
  UTIL_CATALOGS: "util/catalogs",
  UTIL_IMAGE: "util/image",
  UTIL_CATALOG_WEIGHTS: "util/catalog-weights-mg",
  UTIL_CONVERT_FROM_MRV: "util/convert-from-mrv",
  UTIL_CONVERT_TO_MRV: "util/convert-to-mrv",
  UTIL_CARRIERS: "util/carriers",
  CART_NEW: "cart/new",
  CART_INFO: "cart/info",
  CART_CLEAR: "cart/clear",
  CART_GROUP_UPDATE: "cart/groupUpdate",
  CART_ADDITIONAL_COSTS: "cart/additional-costs",
  CART_PAYMENT_METHODS: "cart/payments",
  CART_ADD_BILLING_ADDRESS: "cart/addBillingAddress",
  ORDERS_QUOTE: "orders/quote",
  ORDERS_CREATE_REAL_QUOTE: "orders/createRealQuote",
  // DEPRECATED
  CUSTOMERS_ORDERS: "customers/current/orders",
  CUSTOMERS_INVOICES_PAYMENTS: "customers/current/invoices/payments",
  ORDERS_ORDER: "orders/order",
  ORDERS_SUBMIT: "orders/submit",
  // DEPRECATED
  ORDERS_ORDER_CONTENT: "orders/orderContent",
  APP_INFO: "app/info",
  CUSTOMERS_CURRENT: "customers/current",
  CERTIFICATES_COA: "customers/current/productCertificates/CoA/by/lotNumber",

  // APIv2
  CATALOG_FILTER_CLASSES: "/catalog/building-blocks/classes",
  CATALOG_FILTER_SUBCLASSES: "catalog/building-blocks/subclasses",
  CATALOG_FILTER_COLLECTIONS: "catalog/screening-compounds/collections",
  GROUP_UPDATE: "customers/current/bucket/weight-and-count-in-catalogs",
  CATALOG_ANALYZE_COMPOUNDS: "catalog/search/analyze-compounds",
  BUCKET_CONTENT: "/customers/current/bucket",
  BUCKET_BY_UUID: "/customers/current/bucket/by/uuid",
  BUCKET_BY_UUIDS: "/customers/current/bucket/by/uuids",
  BUCKET_DOWNLOAD_AS_FILE: "customers/current/bucket/as-file/",

  CURRENT_ADDRESSES_AND_PERSONS: "/customers/current/addresses-and-persons",
  CURRENT_ADDRESSES: "/customers/current/addresses",
  CURRENT_PERSONS: "/customers/current/persons",

  CHECKOUT_CALCULATIONS: "/customers/current/bucket/to-purchase/calculations",

  CONDITIONS_BY_SHIPPING_ADDRESS:
    "customers/current/bucket/to-purchase/conditions-by-shipping-address/by/",

  ORDER_SUBMIT: "/customers/current/bucket/to-purchase",
  ORDERS: "customers/current/purchases",
  ORDER_BY_UUID: "/customers/current/purchases/by/uuid",
  CATALOG_CATEGORIES_HOME: "catalog/categories/home",
  CATALOG_CATEGORIES_BY_PATH: "catalog/categories/by/path",
  USER_PROFILE: "customers/current",
  FROM_SEARCH: "/customers/current/bucket/from-search",
  GEO_VAT_PATTERNS: "geo/countries/groups",
  GEO_COUNTRIES: "geo/countries",
  GEO_US_STATES: "geo/countries/US/states",
};

export default endpoints;
