import React from "react";
import { AddressEntity, AddressPayload } from "shared/types/user";
import CreateAddressForm from "shared/ui/CreateAddress/CreateAddress.form";
import DialogCore from "shared/ui/DialogCore/DialogCore";

type AddressDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  formValues?: AddressEntity | AddressPayload;
  handleSubmit: (formValues: AddressEntity | AddressPayload) => void;
};

const AddressDialog = ({
  open,
  setOpen,
  title,
  formValues,
  handleSubmit,
}: AddressDialogProps) => {
  return (
    <DialogCore title={title} open={open} onClose={() => setOpen(false)}>
      <CreateAddressForm onSubmit={handleSubmit} formValues={formValues} />
    </DialogCore>
  );
};
export default AddressDialog;
