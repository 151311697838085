import React from "react";
import { OrderListItemWithDetailsLink } from "../lib/Orders.types";
import OrderItem from "./OrderItem/OrderItem";
// @TODO Move into shared cause its used across the project
import { FullWidthLoader } from "../../../../../parts";
// @TODO Move into shared cause its used across the project
import { EmptyMessage, Table } from "ui";
import { LinksWrapper, PaginationWrapper } from "./Orders.styled";
import { Link } from "react-router-dom";
import { rootPages } from "../../../../../constants";
import PaginationBE from "../../../../../ui/PaginationBE/PaginationBE";

type OrdersProps = {
  ordersList: any[];
  isLoading: boolean;
  pagination: any;
};

const Orders = ({ ordersList, isLoading, pagination }: OrdersProps) => {
  return (
    <>
      {isLoading ? (
        <FullWidthLoader />
      ) : (
        <>
          {ordersList.length ? (
            <Table variant="paper">
              {ordersList.map(
                (
                  orderListItem: OrderListItemWithDetailsLink,
                  index: number
                ) => (
                  <OrderItem key={index} orderListItem={orderListItem} />
                )
              )}
            </Table>
          ) : (
            <EmptyMessage variant="solidWhite">
              You haven’t made any order yet.
            </EmptyMessage>
          )}

          {pagination && (
            <PaginationWrapper>
              <PaginationBE pagination={pagination} />
            </PaginationWrapper>
          )}

          <LinksWrapper>
            <Link to={`/${rootPages.PROFILE}/archived-orders`}>
              Archived Orders
            </Link>
          </LinksWrapper>
        </>
      )}
    </>
  );
};

export default Orders;
