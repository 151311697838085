import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../redux/checkout";
import superCartSelectors from "../../../../../features/super-cart/redux/selectors";
import RestrictionDialog from "./RestrictionDialog";
import { mapRestrictedCartItems } from "./RestrictionDialog.utils";
import superCartActions from "../../../../../features/super-cart/redux/actions";
import { useAmplitudeState } from "../../../../../context/AmplitudeContext";
import { AmplitudeEvents } from "../../../../../context/AmplitudeEvents";

const RestrictionDialogContainer = ({ ...rest }: any) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const checkoutConditions = useSelector(
    checkoutSelectors.selectCheckoutConditions
  );
  const cartItemsWithImages = useSelector(
    superCartSelectors.selectCartItemsWithImages
  );

  const restrictedCartItems = useMemo(() => {
    const restrictedItems = checkoutConditions?.notAllowedProductsInBucket;
    return mapRestrictedCartItems(restrictedItems, cartItemsWithImages);
  }, [checkoutConditions, cartItemsWithImages]);

  const onSuccess = () => {
    checkoutActions.requestConditionsByShippingAddress();
  };

  const handleDeleteRestrictedItems = () => {
    const restrictedItems = checkoutConditions?.notAllowedProductsInBucket?.map(
      (restrictedItem) => restrictedItem.uuid
    );
    if (restrictedItems?.length) {
      dispatch(
        superCartActions.requestDeleteItems({
          payload: restrictedItems,
          onSuccess,
        })
      );
    }
  };

  useEffect(() => {
    if (amplitude && rest.open) {
      amplitude.logEvent(
        AmplitudeEvents.Checkout.CHECKOUT_SHIPPING_RESTRICTION
      );
    }
  }, [amplitude, rest.open]);

  return (
    <RestrictionDialog
      restrictedCartItems={restrictedCartItems}
      {...rest}
      onDeleteRestrictedItems={handleDeleteRestrictedItems}
    />
  );
};

export default RestrictionDialogContainer;
