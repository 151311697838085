// @ts-check
import styled from "styled-components";
import {
  CartSuccessIcon,
  CartWarningIcon,
  CartEmptyIcon,
  MarkSuccessIcon,
  MarkWarningIcon, PaidSuccessIcon,
} from "ui/icons";

export const PaidSuccess = styled(PaidSuccessIcon)``;
export const CartSuccess = styled(CartSuccessIcon)``;
export const CartWarning = styled(CartWarningIcon)``;
export const CartEmpty = styled(CartEmptyIcon)``;

export const MarkSuccess = styled(MarkSuccessIcon)``;
export const MarkWarning = styled(MarkWarningIcon)``;

export const IconWrapper = styled.div`
  position: relative;
  height: 150px;
  width: 150px;

  ${MarkSuccess}, ${MarkWarning} {
    position: absolute;
    top: 12px;
    right: -12px;
    width: 48px;
    height: 48px;
  }

  ${CartSuccess}, ${CartWarning}, ${PaidSuccess} {
    width: 150px;
    height: 150px;
  }
`;
