import { Alert, FormControl, TextField } from "@mui/material";
import { FormikProps, useFormik } from "formik";
import personSchema from "shared/ui/CreatePerson/CreatePersons.validation";
import React from "react";
import { useSelector } from "react-redux";
import { userSelectors } from "redux/user";
import getUserTitlesOptions from "../../../pages/CheckoutV2/lib/Checkout.utils";
import BaseNativeSelect from "../../../pages/CheckoutV2/ui/Content/StepperCheckout/AddressesContent/BaseNativeSelect/BaseNativeSelect";
import { Button } from "../../../ui";
import { ContactPersonPayload } from "../../types/user";
import {
  ErrorWrapper,
  FormRow,
  InputLabelTypography,
  InputWrapper,
  SubmitWrapper,
} from "./CreatePerson.styled";

const initialValues: ContactPersonPayload = {
  title: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  company: "",
};

type CreatePersonFormProps = {
  formik?: FormikProps<ContactPersonPayload>;
  onSubmit: (formData: ContactPersonPayload) => void;
  formValues?: ContactPersonPayload;
};

const CreatePersonForm = ({ onSubmit, formValues }: CreatePersonFormProps) => {
  const userTitlesOptions = getUserTitlesOptions();
  const personError = useSelector(userSelectors.selectContactPersonsError);

  const formik: FormikProps<ContactPersonPayload> = useFormik({
    initialValues: formValues || initialValues,
    validationSchema: personSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormRow>
        <InputWrapper sx={{ maxWidth: "100px" }}>
          <InputLabelTypography variant="body1">Title</InputLabelTypography>
          <FormControl
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            sx={{ width: "100%" }}
          >
            <BaseNativeSelect
              fieldName="title"
              formik={formik}
              options={userTitlesOptions}
            />
          </FormControl>
        </InputWrapper>
        <InputWrapper>
          <InputLabelTypography variant="body1">
            First Name
          </InputLabelTypography>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            placeholder="Enter first name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabelTypography variant="body1">Last Name</InputLabelTypography>
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            placeholder="Enter last name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </InputWrapper>
      </FormRow>
      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">Email</InputLabelTypography>
          <TextField
            fullWidth
            id="email"
            name="email"
            placeholder="Enter email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </InputWrapper>
      </FormRow>
      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">
            Phone Number
          </InputLabelTypography>
          <TextField
            fullWidth
            id="phone"
            name="phone"
            placeholder="Enter phone number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </InputWrapper>
      </FormRow>
      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">Company</InputLabelTypography>
          <TextField
            fullWidth
            id="company"
            name="company"
            placeholder="Enter company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </InputWrapper>
      </FormRow>
      {personError && (
        <ErrorWrapper>
          <Alert variant="outlined" severity="error">
            {personError}
          </Alert>
        </ErrorWrapper>
      )}

      <SubmitWrapper>
        <Button fullWidth size="small" type="submit">
          Save
        </Button>
      </SubmitWrapper>
    </form>
  );
};

export default CreatePersonForm;
