// @ts-check

import React from "react";
import { Button } from "ui";
import { ResetPasswordLayout } from "pages/reset-password/components/layout/layout";
import { rootPages, common } from "../../../../constants";
import { events, useAmplitudeState } from "context/AmplitudeContext";
import { UserMessage } from "ui/user-message/user-message";
import { ResetPasswordIconType } from "pages/reset-password/components/icon/icon";
import { Buttons } from "./styles";

const ResetComplete = () => {
  const { amplitude } = useAmplitudeState();

  const handleLoginClick = () => {
    amplitude.logEvent(events.login_click);
  };

  return (
    <ResetPasswordLayout variant={ResetPasswordIconType.LOCK_SUCCESS}>
      <UserMessage.Title>
        Thank you, your password has been successfully reset
      </UserMessage.Title>
      <UserMessage.Description>
        You can now login using your updated credentials.
        <br />
        <br />
        If you have any further questions or concerns, feel free to reach out to{" "}
        <UserMessage.ExternalLink href={common.CONTACTS_HREF} target="_blank">
          our support team
        </UserMessage.ExternalLink>
        .
      </UserMessage.Description>
      <Buttons>
        <Button
          size="small"
          onClick={handleLoginClick}
          href={`/${rootPages.SIGNIN}`}
        >
          Login
        </Button>
        <Button size="small" href="/">
          Go to Homepage
        </Button>
      </Buttons>
    </ResetPasswordLayout>
  );
};

export default ResetComplete;
