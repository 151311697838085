import React from "react";
import { Loader } from "ui";
import {
  PageWrapper,
  SectionContent,
  SectionWrapper,
} from "./ArchivedOrder.styled";
import { paymentTypes } from "constants/index";
import {
  OrderDetails,
  OrderItemWithCurrencyAndImage,
} from "./ArchivedOrder.types";
import { Container, Typography } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import OrderHeader from "./OrderHeader/OrderHeader";
import OrderItems from "./OrderItems/OrderItems";
import OrderPrices from "./OrderPrices/OrderPrices";
import AddressContent from "./parts/AddressContent";

type OrderProps = {
  backToOrdersLink: string;
  items: OrderItemWithCurrencyAndImage[];
  details: OrderDetails;
  loading: boolean;
  orderId: string;
};

const ArchivedOrder = ({
  backToOrdersLink,
  loading,
  details,
  items,
  orderId,
}: OrderProps) => {
  const isAnyItemInquery = items?.some((item) => !!item?.isInquery);
  return (
    <Container maxWidth="lg">
      <PageWrapper>
        <OrderHeader
          orderId={orderId}
          loading={loading}
          backToOrdersLink={backToOrdersLink}
          date={details?.updated}
          type={details?.type}
          status={details?.status}
        />

        {loading ? (
          <div className="order-page__grid-item order-page__grid-item--full-width">
            <SectionWrapper>
              <Loader fullWidth />
            </SectionWrapper>
          </div>
        ) : (
          <Grid container spacing={2}>
            {Boolean(items?.length) && (
              <Grid xs={12}>
                <SectionWrapper>
                  <Typography variant="h2">Items</Typography>
                  <OrderItems orderItems={items} />
                </SectionWrapper>
              </Grid>
            )}
            <Grid xs={12}>
              <SectionWrapper>
                <Typography variant="h2">Price</Typography>
                <OrderPrices
                  details={details}
                  isAnyItemInquery={isAnyItemInquery}
                />
              </SectionWrapper>
            </Grid>
            <Grid xs={12}>
              <SectionWrapper>
                <Typography variant="h2">
                  Contact Person for Shipping and Billing
                </Typography>
                <SectionContent>
                  {details?.shippingPerson?.name}{" "}
                  {details?.shippingPerson?.email}{" "}
                  {details?.shippingPerson?.phone}
                </SectionContent>
              </SectionWrapper>
            </Grid>
            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Typography variant="h2">Billing Address</Typography>
                <SectionContent>
                  {details?.addresses?.billing ? (
                    <AddressContent item={details.addresses.billing} />
                  ) : null}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Typography variant="h2">Shipping Address</Typography>
                <SectionContent>
                  {details?.addresses?.shipping ? (
                    <AddressContent item={details.addresses.shipping} />
                  ) : null}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Typography variant="h2">Shipping Options</Typography>
                <SectionContent>
                  {details?.carrier?.name}
                  {details?.carrier?.id ? (
                    <>. Carrier ID: {details?.carrier?.id}</>
                  ) : null}
                </SectionContent>
              </SectionWrapper>
            </Grid>

            <Grid xs={12} md={6}>
              <SectionWrapper>
                <Typography variant="h2">Payment Type</Typography>
                <SectionContent>
                  {paymentTypes?.[details?.paymentType]}
                </SectionContent>
              </SectionWrapper>
            </Grid>
          </Grid>
        )}
      </PageWrapper>
    </Container>
  );
};

export default ArchivedOrder;
