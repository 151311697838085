import endpoints from "../../../../../constants/endpoints";
import { useQuery } from "@tanstack/react-query";
import { customFetchDelayedMiddleware } from "../../../../../context/sseContext";
import { RequestMethods } from "../../../../../utils/customFetch";
import { toast } from "react-toastify";

export const fetchOrders = (pagination: {
  page: number | string;
  pageSize: number | string;
  pagesCount: number | string;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery({
    queryKey: [
      `${endpoints.ORDERS}?page=${pagination.page}&pageSize=${pagination.pageSize}&includeEvents=true&includePayments=true&includeItemEvents=true`,
    ],
    queryFn: async () => {
      const response = await customFetchDelayedMiddleware({
        method: RequestMethods.GET,
        url: `${endpoints.ORDERS}?page=${pagination.page}&pageSize=${pagination.pageSize}&includeEvents=true&includePayments=true&includeItemEvents=true`,
      });

      if (response[1]) {
        toast.error(response[1]);
      }

      return response;
    },
    enabled: Boolean(pagination.page !== undefined && pagination.pageSize),
  });
};
