import styled from "styled-components";
import { lineColor } from "../../../ui/colors";
import { Box } from "@mui/material";

export const Wrapper = styled.div`
  display: flex;
  column-gap: 32px;
  padding-bottom: 100px;
`;
Wrapper.displayName = "CartPageWrapper";

export const Column = styled.div`
  ${({ main }) => {
    if (main) {
      return `
                flex-grow: 1;
            `;
    }
  }}
`;

export const PaginationWrapper = styled.div`
  ${({ mt, mb }) => {
    if (mt) {
      return `
                margin-top: 8px;
            `;
    } else if (mb) {
      return `
                margin-bottom: 24px;
            `;
    }
  }}
`;

export const CartItemsWrapper = styled(Box)`
  & > * {
    border-bottom: 1px solid ${lineColor};

    &:last-child {
      border-bottom: none;
    }
  }
`;
