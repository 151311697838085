import {
  CLOSE_ACCORDION,
  OPEN_ACCORDION,
  SET_DATA,
  SET_INITIAL,
  CHANGE_INPUT,
  REQUEST_ADDITIONAL_COSTS,
  SET_ADDITIONAL_COSTS_LOADING,
  SET_ADDITIONAL_COSTS,
  SET_ADDITIONAL_COSTS_ERROR,
  SET_PAYMENT_METHODS_LOADING,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_METHODS_ERROR,
  REQUEST_PAYMENT_METHODS,
  SET_BILLING_PERSON_UUID,
  SET_SHIPPING_PERSON_UUID,
  SET_ADDRESS_SHIPPING_UUID,
  SET_ADDRESS_BILLING_UUID,
  SET_REQUEST_DATA,
  REQUEST_CALCULATIONS,
  SET_CALCULATIONS,
  REQUEST_CONDITIONS_BY_SHIPPING_ADDRESS,
  SET_CONDITIONS_BY_SHIPPING_ADDRESS,
  REQUEST_PROMO_CODE_CALCULATIONS,
} from "./types";

const setData = (payload) => {
  return {
    type: SET_DATA,
    payload,
  };
};

const requestAdditionalCosts = (payload) => {
  return {
    type: REQUEST_ADDITIONAL_COSTS,
    payload,
  };
};

const requestPaymentMethods = (payload) => {
  return {
    type: REQUEST_PAYMENT_METHODS,
    payload,
  };
};

const setAdditionalCostsLoading = (payload) => {
  return {
    type: SET_ADDITIONAL_COSTS_LOADING,
    payload,
  };
};

const setAdditionalCosts = (payload) => {
  return {
    type: SET_ADDITIONAL_COSTS,
    payload,
  };
};

const setAdditionalCostsError = (payload) => {
  return {
    type: SET_ADDITIONAL_COSTS_ERROR,
    payload,
  };
};

const setPaymentMethodsLoading = (payload) => {
  return {
    type: SET_PAYMENT_METHODS_LOADING,
    payload,
  };
};

const setPaymentMethods = (payload) => {
  return {
    type: SET_PAYMENT_METHODS,
    payload,
  };
};

const setPaymentMethodsError = (payload) => {
  return {
    type: SET_PAYMENT_METHODS_ERROR,
    payload,
  };
};

const changeInput = (payload) => {
  return {
    type: CHANGE_INPUT,
    payload,
  };
};

const setInitial = () => {
  return {
    type: SET_INITIAL,
  };
};

const closeAccordion = (
  accordionKey: string,
  config?: {
    valid?: boolean;
    disabled?: boolean;
  }
) => {
  return {
    type: CLOSE_ACCORDION,
    payload: { accordionKey, config },
  };
};

const openAccordion = (accordionKey) => {
  return {
    type: OPEN_ACCORDION,
    payload: accordionKey,
  };
};

// V2
const setShippingPersonUuid = (payload) => {
  return {
    type: SET_SHIPPING_PERSON_UUID,
    payload,
  };
};

const setBillingPersonUuid = (payload) => {
  return {
    type: SET_BILLING_PERSON_UUID,
    payload,
  };
};

const setAddressShippingUuid = (payload) => {
  return {
    type: SET_ADDRESS_SHIPPING_UUID,
    payload,
  };
};

const setAddressBillingUuid = (payload) => {
  return {
    type: SET_ADDRESS_BILLING_UUID,
    payload,
  };
};

const setRequestData = (payload) => {
  return {
    type: SET_REQUEST_DATA,
    payload,
  };
};

const requestCalculations = (payload) => {
  return {
    type: REQUEST_CALCULATIONS,
    payload,
  };
};

const requestPromoCodeCalculations = (payload) => {
  return {
    type: REQUEST_PROMO_CODE_CALCULATIONS,
    payload,
  };
};

const setCalculations = (payload) => {
  return {
    type: SET_CALCULATIONS,
    payload,
  };
};

const requestConditionsByShippingAddress = () => {
  return {
    type: REQUEST_CONDITIONS_BY_SHIPPING_ADDRESS,
  };
};

const setConditionsByShippingAddress = (payload) => {
  return {
    type: SET_CONDITIONS_BY_SHIPPING_ADDRESS,
    payload,
  };
};

export default {
  setData,
  closeAccordion,
  openAccordion,
  setInitial,
  changeInput,
  requestAdditionalCosts,
  requestPaymentMethods,
  setAdditionalCostsLoading,
  setAdditionalCosts,
  setAdditionalCostsError,
  setPaymentMethodsLoading,
  setPaymentMethodsError,
  setPaymentMethods,

  // V2
  setRequestData,
  setShippingPersonUuid,
  setBillingPersonUuid,
  setAddressShippingUuid,
  setAddressBillingUuid,
  requestCalculations,
  setCalculations,
  requestPromoCodeCalculations,
  requestConditionsByShippingAddress,
  setConditionsByShippingAddress,
};
