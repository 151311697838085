export const closeAccordion = (accordion, { accordionKey, config }) => {
  const res = {
    ...accordion,
    [accordionKey]: {
      ...accordion[accordionKey],
      open: false,
      ...(config && config),
    },
  };

  return res;
};

export const openAccordion = (accordion, id) => {
  const res = {
    ...accordion,
    [id]: {
      ...accordion[id],
      open: true,
      disabled: false,
    },
  };

  return res;
};

export const prepareData = (data) => {
  const shipping = data.shipping;
  const billing = data.billing;

  const newData = {
    carrier: data.carrier?.title,
    carrierId: data.carrierId,
    rushDeliveryRequest: data?.carrierDeliveryOption === "RUSH",
    shippingAddress: {
      company: shipping.company,
      country: shipping.country,
      city: shipping.city,
      addressLine1: shipping.addressLine1,
      zip: shipping.zip,
      state: shipping.state,
    },
    billingAddress: {
      company: billing.company,
      country: billing.country,
      city: billing.city,
      addressLine1: billing.addressLine1,
      zip: billing.zip,
      state: billing.state,
    },
  };

  return newData;
};
