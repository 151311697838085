import React, { useEffect } from "react";
import { useAmplitudeState } from "../../../../../../context/AmplitudeContext";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../../../../../redux/user";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../../redux/checkout";
import AddressesContent from "../AddressesContent/AddressesContent";
import { AmplitudeEvents } from "../../../../../../context/AmplitudeEvents";

const StepAddressBillingContainer = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const addresses = useSelector(userSelectors.selectAddresses);

  const selectedAddressBillingUuid = useSelector(
    checkoutSelectors.selectAddressBillingUuid
  );

  const handleChangeAddressBillingUuid = (addressUuid?: string) => {
    dispatch(checkoutActions.setAddressBillingUuid(addressUuid));
  };

  const handleNextStep = () => {
    dispatch(checkoutActions.closeAccordion("step3"));
    dispatch(checkoutActions.openAccordion("step4"));
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_BILLING);
  };

  useEffect(
    () => {
      if (!selectedAddressBillingUuid && addresses?.length) {
        dispatch(checkoutActions.setAddressBillingUuid(addresses?.[0]?.uuid));
      }
    },
    // eslint-disable-next-line
    [addresses, selectedAddressBillingUuid]);

  return (
    <AddressesContent
      type={"Shipping"}
      selectedAddressUuid={selectedAddressBillingUuid}
      addresses={addresses}
      onChangeBillingAddressUuid={handleChangeAddressBillingUuid}
      onNextStep={handleNextStep}
    />
  );
};

export default StepAddressBillingContainer;
