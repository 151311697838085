import { events, useAmplitudeState } from "context/AmplitudeContext";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "redux/user";
import { AddressEntity } from "shared/types/user";
import { utilSelectors } from "../../../../../../redux/util";
import AddressCard from "./AddressCard";

type AddressCardContainerProps = {
  addressData: AddressEntity;
};

const AddressCardContainer = ({ addressData }: AddressCardContainerProps) => {
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);
  const [isOpenDeleteAddress, setIsOpenDeleteAddress] = useState(false);
  const [isOpenEditAddress, setIsOpenEditAddress] = useState(false);
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();

  const {
    phone,
    country,
    city,
    company,
    zip,
    europeVAT,
    usaState,
    uuid,
    address,
  } = addressData;

  const formattedAddresses = useMemo(() => {
    const countryItem = {
      id: "country",
      title: usaState ? "Country/State" : "Country",
      value: usaState ? `USA/${usStates?.[usaState]}` : countries?.[country],
    };

    const newList = [
      countryItem,
      { id: "city", title: "City", value: city },
      { id: "zip", title: "Postal Code", value: zip },
      { id: "phone", title: "Phone Number", value: phone },
      { id: "company", title: "Company", value: company },
      {
        id: "address",
        title: "Address",
        value: europeVAT ? `${address}. VAT ${europeVAT}` : address,
      },
    ];

    return newList;
  }, [
    europeVAT,
    phone,
    country,
    city,
    zip,
    company,
    usaState,
    address,
    countries,
    usStates,
  ]);

  const logAction = (type) => {
    amplitude.logEvent(events.profile_address_edit, { [type]: true });
  };

  const handleToggleEditOpen = () => {
    dispatch(userActions.setAddressesError(null));
    setIsOpenEditAddress((prevState) => !prevState);
  };

  const handleToggleDeleteOpen = () => {
    setIsOpenDeleteAddress((prevState) => !prevState);
  };

  const handleDelete = () => {
    dispatch(userActions.requestDeleteAddress(uuid));
    logAction("delete");
  };

  const handleEdit = (formValues: AddressEntity) => {
    dispatch(
      userActions.requestEditAddress({
        editedAddressData: formValues,
        onSuccess: handleToggleEditOpen,
      })
    );
    logAction("edit");
  };

  return (
    <AddressCard
      formattedAddresses={formattedAddresses}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      addressData={addressData}
      isOpenDeleteAddress={isOpenDeleteAddress}
      handleToggleDeleteOpen={handleToggleDeleteOpen}
      isOpenEditAddress={isOpenEditAddress}
      handleToggleEditOpen={handleToggleEditOpen}
    />
  );
};

export default AddressCardContainer;
