import React, { useState } from "react";
import { Button } from "@mui/material";
import DialogCore from "../../../../../../../shared/ui/DialogCore/DialogCore";
import { useDispatch } from "react-redux";
import { userActions } from "../../../../../../../redux/user";
import CreateAddressForm from "../../../../../../../shared/ui/CreateAddress/CreateAddress.form";
import { AddressPayload } from "../../../../../../../shared/types/user";
import { AmplitudeEvents } from "../../../../../../../context/AmplitudeEvents";
import { useAmplitudeState } from "../../../../../../../context/AmplitudeContext";

type CreateAddressProps = {
  type: "Shipping" | "Billing";
};

const CreateAddress = ({ type }: CreateAddressProps) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const [isCreateAddressModalOpen, setIsCreateAddressModalOpen] =
    useState(false);

  const handleToggleModalOpen = () => {
    dispatch(userActions.setAddressesError(null));
    setIsCreateAddressModalOpen((prevState) => !prevState);
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_NEW_ADDRESS, {
      address_type: type,
    });
  };

  const handleAddNewAddress = (formValues: AddressPayload) => {
    dispatch(
      userActions.requestAddAddress({
        newAddressData: formValues,
        onSuccess: handleToggleModalOpen,
      })
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleToggleModalOpen}
        fullWidth
        sx={{ marginTop: "16px" }}
      >
        Add new address
      </Button>

      <DialogCore
        title="Add new address"
        open={isCreateAddressModalOpen}
        onClose={handleToggleModalOpen}
      >
        <CreateAddressForm onSubmit={handleAddNewAddress} />
      </DialogCore>
    </>
  );
};

export default CreateAddress;
