// @ts-check
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Email,
  Password,
  Company,
  Phone,
  LastName,
  FirstName,
  UserTitleSelect,
  RepeatPassword,
} from "parts/form-inputs";
import rootPages from "constants/rootPages";
import userTitles from "constants/userTitles";
import { getValidationSchema, handleSubmit } from "../../utils";

import { events, useAmplitudeState } from "context/AmplitudeContext";
import {
  LoginSignupPanel,
  MessageWithLink,
  Button,
  InputWrapper,
  Checkbox,
  TextLink,
  ErrorMessage,
} from "ui";
import {
  ButtonWrapper,
  ErrorWrapper,
  CheckboxWrapper,
  CheckboxGroup,
} from "../../SignUp.styles";

import { FormLayout } from "ui/form-layout/form-layout";
import styled from "styled-components";

export default function SignUpForm({ setIsRegistered }) {
  const [disabled, setDisabled] = useState(false);
  const { amplitude } = useAmplitudeState();

  const logSignUpComplete = () => {
    amplitude.logEvent(events.sign_up, { complete: true });
  };

  const formik = useFormik({
    initialValues: {
      title: userTitles[0],
      firstName: "",
      lastName: "",
      phone: "",
      company: "",
      email: "",
      password: "",
      repeatPassword: "",
      subscribe: true,
      terms: false,
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      handleSubmit(values, {
        formik,
        setDisabled,
        setIsRegistered,
        logSignUpComplete,
      });
    },
  });

  useEffect(() => {
    amplitude.logEvent(events.sign_up, { start: true });
  }, []);

  return (
    <FormLayout
      title="Registration"
      footer={
        <MessageWithLink
          message={`Have an account?`}
          linkText="Login"
          linkHref={`/${rootPages.SIGNIN}`}
        />
      }
    >
      <Form onSubmit={formik.handleSubmit}>
        <UserTitleSelect formik={formik} />
        <FirstName formik={formik} autoFocus />
        <LastName formik={formik} />
        <Phone formik={formik} />
        <Company formik={formik} />
        <Email formik={formik} />
        <Password formik={formik} checkStrength />
        <RepeatPassword formik={formik} />
        <Checkbox
          name="subscribe"
          formik={formik}
          text={"I would like to receive proposals"}
        />
        <Checkbox
          name="terms"
          formik={formik}
          text={
            <>
              I have read and agree to the{" "}
              <TextLink to={"/terms-of-use"} target="_blank">
                Terms of use
              </TextLink>
              {" and "}
              <TextLink to={"/privacy-policy"} target="_blank">
                Privacy Policy
              </TextLink>
            </>
          }
        />
        {formik.errors.common ? (
          <ErrorWrapper>
            <ErrorMessage>{formik.errors.common}</ErrorMessage>
          </ErrorWrapper>
        ) : null}

        <Button
          type="submit"
          size="small"
          fullWidth
          disabled={disabled || formik.errors.password}
        >
          Register
        </Button>
      </Form>
    </FormLayout>
  );
}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;
