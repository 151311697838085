import React, { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Sidebar from "./ui/Sidebar/Sidebar";
import Content from "./ui/Content/Content";
import SendingOrder from "../checkout/parts/sending-order/SendingOrder";
import { CheckoutContext } from "./lib/Checout.context";
// @TODO Move into updated checkout module
import CheckOrderContainer from "./ui/Content/CheckOrder/CheckOrder.container";
import { CHECKOUT_MODES } from "./model/Checkout.contants";
import OrderError from "./ui/Content/OrderError/OrderError";
import Thank from "./ui/Content/Thank/Thank";
import ThankPay from "./ui/Content/Thank/ThankPay";
import ThankQuote from "./ui/Content/Thank/ThankQuote";
import ThankPaid from "./ui/Content/Thank/ThankPaid";
import { useQuery } from "../../hooks";

const Checkout = () => {
  const query = useQuery();
  const { currentMode, setCurrentMode } = useContext(CheckoutContext);

  useEffect(
    () => {
      const orderPaid = query.get("paid");
      if (orderPaid === "true") {
        setCurrentMode(CHECKOUT_MODES.THANK_PAID);
      }

      if (orderPaid === "false") {
        setCurrentMode(CHECKOUT_MODES.THANK_UNPAID);
      }
    },
    // eslint-disable-next-line
    [setCurrentMode]);

  switch (currentMode) {
    case CHECKOUT_MODES.SENDING_ORDER:
      return <SendingOrder />;
    case CHECKOUT_MODES.THANK:
      return <Thank />;
    case CHECKOUT_MODES.THANK_PAID:
    case CHECKOUT_MODES.THANK_UNPAID:
      return <ThankPaid />;
    case CHECKOUT_MODES.THANK_QUOTE:
      return <ThankQuote />;
    case CHECKOUT_MODES.THANK_PAY:
      return <ThankPay />;
    case CHECKOUT_MODES.ERROR:
      return <OrderError />;
    default:
      return (
        <Grid container spacing={4}>
          <Grid item xs={12} md={7} lg={8}>
            {currentMode === CHECKOUT_MODES.CHECK_ORDER && (
              <CheckOrderContainer />
            )}
            {currentMode === CHECKOUT_MODES.CHECKOUT && <Content />}
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Sidebar />
          </Grid>
        </Grid>
      );
  }
};

export default Checkout;
