// @ts-check

import React from "react";
import {
  MarkSuccess,
  CartSuccess,
  MarkWarning,
  CartWarning,
  CartEmpty,
  IconWrapper, PaidSuccess,
} from "./cart-image.styles";

/**
 * @type { React.FC<{ variant: "success" | "warning" | "empty" | "paid" | "unpaid" }> }
 */
export const CartImage = ({ variant }) => {
  return (
    <IconWrapper>
      {variant === "paid" ? (<>
          <MarkSuccess />
          <PaidSuccess />
        </>
      ) : variant === "unpaid" ? (<>
        <MarkWarning />
        <PaidSuccess />
      </>) : variant === "success" ? (
        <>
          <MarkSuccess />
          <CartSuccess />
        </>
      ) : variant === "warning" ? (
        <>
          <MarkWarning />
          <CartWarning />
        </>
      ) : variant === "empty" ? (
        <CartEmpty />
      ) : null}
    </IconWrapper>
  );
};
