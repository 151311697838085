import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useAmplitudeState } from "../../../../../../context/AmplitudeContext";
import { utilSelectors } from "../../../../../../redux/util";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../../redux/checkout";
import StepShippingOptions from "./StepShippingOptions";
import {
  CarrierItem,
  CarrierWithImage,
} from "../../../../../../shared/types/checkout";
import { CarrierImages } from "../../../../model/Checkout.contants";
import { getValidationSchema } from "./utils";
import { AmplitudeEvents } from "../../../../../../context/AmplitudeEvents";

const StepShippingOptionsContainer = ({ postalCodeIssue }) => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();

  // @TODO Updated
  const carriers = useSelector(utilSelectors.selectCarriers);
  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);
  const overnightDelivery = useSelector(
    checkoutSelectors.selectOvernightDelivery
  );

  const [carriersWithImage, setCarriersWithImage] = useState<
    CarrierWithImage[]
  >([]);

  // @TODO Move this logic into saga when we get carriers we have to add images
  useEffect(() => {
    if (carriers?.length) {
      const newItems = carriers.map((carrier: CarrierItem) => ({
        id: carrier.id,
        title: carrier.title,
        image: CarrierImages?.[carrier.id],
      }));

      setCarriersWithImage(newItems);
    }
  }, [carriers]);

  // @TODO Updated
  const [isLoading] = useState(false);

  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  const handleSelectShipmentOption = (shipmentOptionIsPartial: boolean) => {
    formik.setValues({
      ...formik.values,
      partialShipping: shipmentOptionIsPartial,
    });
  };

  const handleSelectDeliveryOption = (deliveryOptionIsRush: boolean) => {
    formik.setValues({
      ...formik.values,
      overnightDelivery: deliveryOptionIsRush,
    });
  };

  const handleSelectCarrier = (carrier: CarrierWithImage) => {
    formik.setValues({
      ...formik.values,
      shippingService: { name: carrier?.title, ...carrier },
    });
  };

  const formik = useFormik({
    initialValues: {
      ...(checkoutRequestData?.shippingService?.clientId && {
        clientId: checkoutRequestData?.shippingService?.clientId,
      }),
      shippingService: checkoutRequestData?.shippingService || {},
      overnightDelivery: checkoutRequestData?.overnightDelivery,
      partialShipping: checkoutRequestData?.partialShipping,
    },
    validationSchema: getValidationSchema(),
    onSubmit: (formValues) => {
      const payload = {
        ...formValues,
        shippingService: {
          ...formValues.shippingService,
          ...(formValues.clientId && { clientId: formValues.clientId }),
        },
        ...(formValues.shippingService?.id !== 0 && {
          partialShipping: false,
          overnightDelivery: false,
        }),
      };

      delete payload.clientId;
      delete payload.shippingService.image;

      dispatch(checkoutActions.requestCalculations(payload));

      amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_SHIPPING_OPTIONS, {
        carrier: payload.shippingService.name,
        deliveryoptions: payload.overnightDelivery ? "Rush" : "Standard",
        Shipmentoptions: payload.partialShipping ? "Partial" : "Full",
      });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <StepShippingOptions
      carrierItems={carriersWithImage}
      formik={formik}
      isLoading={isLoading}
      overnightDelivery={overnightDelivery}
      deliveryModalOpen={deliveryModalOpen}
      setDeliveryModalOpen={setDeliveryModalOpen}
      onSelectCarrier={handleSelectCarrier}
      onSelectShipmentOption={handleSelectShipmentOption}
      onSelectDeliveryOption={handleSelectDeliveryOption}
      onSubmit={handleSubmit}
    />
  );
};

export default StepShippingOptionsContainer;
