import styled from "styled-components";
import { blue } from "../../../../../ui/colors";

export const PaginationWrapper = styled.div`
  padding-bottom: 28px;
  padding-top: 28px;
`;

export const LinksWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > a {
    color: ${blue};
    text-decoration: none;
  }
`;
