import React, { useContext, useEffect } from "react";
import { Button } from "ui";
import common from "constants/common";
import { CartMessage } from "ui/cart-message/cart-message";
import { ButtonWrapper, ContactLink } from "./OrderError.styled";
import { CHECKOUT_MODES } from "../../../model/Checkout.contants";
import { CheckoutContext } from "../../../lib/Checout.context";
import { AmplitudeEvents } from "../../../../../context/AmplitudeEvents";
import { useAmplitudeState } from "../../../../../context/AmplitudeContext";

const OrderError = () => {
  const { amplitude } = useAmplitudeState();
  const { setCurrentMode } = useContext(CheckoutContext);

  useEffect(() => {
    amplitude.logEvent(AmplitudeEvents.Payment.PAYMENT_ERROR);
  }, [amplitude]);

  return (
    <CartMessage variant="warning">
      <CartMessage.Title>
        Sorry, there was an issue when placing your order
      </CartMessage.Title>
      <CartMessage.Description>
        Please try again in a few moments or{" "}
        <ContactLink href={common.CONTACTS_HREF} target="_blank">
          contact our team.
        </ContactLink>
      </CartMessage.Description>
      <ButtonWrapper>
        <Button
          onClick={() => {
            setCurrentMode(CHECKOUT_MODES.CHECK_ORDER);
          }}
          size="small"
          fullWidth
        >
          Try again
        </Button>
      </ButtonWrapper>
    </CartMessage>
  );
};

export default OrderError;
