import { endpoints } from "constants";
import customFetch from "utils/customFetch";
import { ProductTypesV2 } from "../../constants/productTypes";
import { Catalog } from "../../shared/types/common";

type FetchImageProps = {
  code: string;
  catalog: ProductTypesV2 | Catalog;
};

const fetchImage = ({ code, catalog }: FetchImageProps) => {
  return customFetch({
    url: `${endpoints.CATALOG_PRODUCTS}/${catalog}/code/${code}/image`,
  });
};

export default fetchImage;
