import React from "react";
import RadioCardV2 from "../../../../../../../shared/ui/RadioCardV2/RadioCardV2";
import { Typography } from "@mui/material";
import { CarrierWithImage } from "../../../../../../../shared/types/checkout";
import Grid from "@mui/material/Grid";
import {
  CarrierImageWrapper,
  CarrierLabelWrapper,
} from "./CarrierLabels.styled";

type CarrierLabelsProps = {
  carriers: CarrierWithImage[];
  selectedCarrier: CarrierWithImage;
  onChangeCarrier: (carrier: CarrierWithImage) => void;
};

const CarrierLabels = ({
  carriers,
  selectedCarrier,
  onChangeCarrier,
}: CarrierLabelsProps) => {
  return (
    <Grid container spacing={2}>
      {carriers?.map((carrier, index) => {
        const isActive = carrier?.id === selectedCarrier?.id;
        const CarrierImage = carrier.image;

        return (
          <Grid key={index} item xs={12} md={6}>
            <CarrierLabelWrapper>
              <RadioCardV2
                isActive={isActive}
                handleChange={() => onChangeCarrier(carrier)}
              >
                <Typography variant="body1">{carrier.title}</Typography>
                <CarrierImageWrapper>
                  <CarrierImage />
                </CarrierImageWrapper>
              </RadioCardV2>
            </CarrierLabelWrapper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CarrierLabels;
