import React from "react";
import { Button } from "ui/index";
import {
  Wrapper,
  InputWrapper,
  Title,
  TextAsLink,
  SectionWrapper,
  AlertWrapper,
} from "./StepShippingOptions.styled";
import CarrierLabels from "./CarrierLabels/CarrierLabels";
import RadioCardV2 from "../../../../../../shared/ui/RadioCardV2/RadioCardV2";
import { Alert, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import RushDeliveryModal from "./RushDeliveryModal/RushDeliveryModal";
import { InputLabelTypography } from "../../../../../../shared/ui/CreatePerson/CreatePerson.styled";

const StepShippingOptions = ({
  formik,
  isLoading,
  deliveryModalOpen,
  setDeliveryModalOpen,
  // V2
  overnightDelivery,
  carrierItems,
  onSelectCarrier,
  onSelectShipmentOption,
  onSelectDeliveryOption,
  onSubmit,
}) => {
  const isOvernightDeliveryAllowed = Boolean(
    overnightDelivery?.isPossible &&
      Object.keys(overnightDelivery?.allowed?.bucketItems || {})?.length
  );

  return (
    <Wrapper disable={isLoading}>
      <SectionWrapper>
        <Title>Carrier</Title>
        <CarrierLabels
          carriers={carrierItems}
          selectedCarrier={formik.values.shippingService}
          onChangeCarrier={onSelectCarrier}
        />

        {formik.values.shippingService?.id !== 0 && (
          <InputWrapper>
            <InputLabelTypography variant="body1">
              Carrier ID
            </InputLabelTypography>
            <TextField
              fullWidth
              id="clientId"
              name="clientId"
              placeholder="Enter carrier ID"
              value={formik.values.clientId}
              onChange={formik.handleChange}
              error={formik.touched.clientId && Boolean(formik.errors.clientId)}
              helperText={formik.touched.clientId && formik.errors.clientId}
            />
          </InputWrapper>
        )}
      </SectionWrapper>

      {formik.values.shippingService?.id === 0 && (
        <>
          <SectionWrapper>
            <Title>Delivery Options</Title>
            <Grid container spacing={2}>
              <Grid item xs={12} md={isOvernightDeliveryAllowed ? 6 : 12}>
                <RadioCardV2
                  isActive={!formik.values.overnightDelivery}
                  handleChange={() => onSelectDeliveryOption(false)}
                >
                  <Typography variant="body1">
                    Standard Delivery (2-14 days)
                  </Typography>
                </RadioCardV2>
              </Grid>
              {isOvernightDeliveryAllowed && (
                <Grid item xs={12} md={6}>
                  <RadioCardV2
                    isActive={formik.values.overnightDelivery}
                    handleChange={() => onSelectDeliveryOption(true)}
                  >
                    <Typography variant="body1">
                      Rush Delivery (1-3 days)
                    </Typography>
                  </RadioCardV2>
                </Grid>
              )}
            </Grid>
            {isOvernightDeliveryAllowed && formik.values.overnightDelivery && (
              <AlertWrapper>
                <Alert severity="info">
                  Rush delivery may involve additional charges.{" "}
                  {isOvernightDeliveryAllowed && (
                    <TextAsLink onClick={() => setDeliveryModalOpen(true)}>
                      Review items
                    </TextAsLink>
                  )}
                </Alert>
                <RushDeliveryModal
                  overnightDelivery={overnightDelivery}
                  open={deliveryModalOpen}
                  setOpen={setDeliveryModalOpen}
                />
              </AlertWrapper>
            )}
          </SectionWrapper>

          <SectionWrapper>
            <Title>Shipment Options</Title>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <RadioCardV2
                  isActive={!formik.values.partialShipping}
                  handleChange={() => onSelectShipmentOption(false)}
                >
                  <Typography variant="body1">Standard Shipment</Typography>
                </RadioCardV2>
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioCardV2
                  isActive={formik.values.partialShipping}
                  handleChange={() => onSelectShipmentOption(true)}
                >
                  <Typography variant="body1">Partial Shipment</Typography>
                </RadioCardV2>
              </Grid>
            </Grid>
            {formik.values?.partialShipping && (
              <AlertWrapper>
                <Alert severity="info">
                  Your order may be fulfilled in more than one delivery to
                  facilitate shipping.
                </Alert>
              </AlertWrapper>
            )}
          </SectionWrapper>
        </>
      )}

      <Button
        disabled={Boolean(Object.keys(formik.errors)?.length)}
        onClick={onSubmit}
        size="small"
        fullWidth
        type="button"
      >
        Next
      </Button>
    </Wrapper>
  );
};

export default StepShippingOptions;
