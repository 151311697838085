import React from "react";

import { OvernightDeliveryData } from "./RushDeliveryModal.types";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { Title } from "../StepShippingOptions.styled";
import {
  TableCellHeaderRush,
  TableCellRush,
  TableHeadRush,
  TableRush,
} from "./RushDeliveryModal.styled";

const tableHeaderItems = [
  "Catalog ID",
  "Item Amount",
  "Delivery Time",
  "Packing",
];

type RushDeliveryModalTableProps = {
  overnightDeliveryList: OvernightDeliveryData[];
  title: string;
};

const RushDeliveryModalTable = ({
  overnightDeliveryList,
  title,
}: RushDeliveryModalTableProps) => {
  return (
    <>
      <Title variant="h2">{title}</Title>
      <TableContainer component={Paper}>
        <TableRush>
          <TableHeadRush>
            <TableRow>
              {tableHeaderItems.map((tableHeaderItem, index) => (
                <TableCellHeaderRush key={index}>
                  {tableHeaderItem}
                </TableCellHeaderRush>
              ))}
            </TableRow>
          </TableHeadRush>
          <TableBody>
            {overnightDeliveryList.map((deliveryData, index) => (
              <TableRow key={index}>
                <TableCellRush sx={{ fontWeight: 600 }}>
                  {deliveryData.code}
                </TableCellRush>
                <TableCellRush>{deliveryData.amount}</TableCellRush>
                <TableCellRush>{deliveryData.deliveryTime}</TableCellRush>
                <TableCellRush>{deliveryData.packing}</TableCellRush>
              </TableRow>
            ))}
          </TableBody>
        </TableRush>
      </TableContainer>
    </>
  );
};
export default RushDeliveryModalTable;
