import styled from "styled-components";
import { accentColor, borderColor } from "ui/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  border: 1px solid ${borderColor};
  border-radius: 6px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  padding-right: 12px;
  padding-left: 12px;
  width: 64px;

  &:hover,
  &:focus {
    border: 1px solid ${accentColor};
    outline: 0;
  }
`;
