import styled from "styled-components";
import { darkBlue } from "../../../../../../ui/colors";
import { Box } from "@mui/material";

// @TODO Change to Typography
export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${darkBlue};
  margin: 0 0 28px 0;
`;

export const InputWrapper = styled(Box)`
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
`;
