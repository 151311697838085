import React from "react";
import { DownArrowIcon } from "../../../../../../../ui/icons";
import {
  BaseNativeSelectStyled,
  BaseNativeSelectWrapper,
} from "./BaseNativeSelect.styled";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { NativeSelectProps } from "@mui/material/NativeSelect/NativeSelect";
import { FormHelperText } from "@mui/material";

type NativeSelectOption = {
  value: string | number;
  title: string;
};

type BaseNativeSelectProps = {
  label?: string;
  fieldName: string;
  formik: FormikProps<FormikValues>;
  options: NativeSelectOption[];
} & NativeSelectProps;

const BaseNativeSelect = ({
  label,
  fieldName,
  formik,
  options,
  ...props
}: BaseNativeSelectProps) => {
  return (
    <BaseNativeSelectWrapper>
      <BaseNativeSelectStyled
        fullWidth
        IconComponent={DownArrowIcon}
        id={fieldName}
        name={fieldName}
        value={formik.values?.[fieldName]}
        error={
          formik.touched?.[fieldName] && Boolean(formik.errors?.[fieldName])
        }
        onChange={formik.handleChange}
        {...props}
      >
        <option disabled style={{ display: "none" }} value="">
          {label || "Select"}
        </option>
        {options.map((option: NativeSelectOption, index: number) => {
          return (
            <option key={index} value={option.value}>
              {option.title}
            </option>
          );
        })}
      </BaseNativeSelectStyled>
      {formik.touched?.[fieldName] && formik.errors?.[fieldName] && (
        <FormHelperText>
          {formik.touched?.[fieldName] &&
            (formik.errors?.[fieldName] as string)}
        </FormHelperText>
      )}
    </BaseNativeSelectWrapper>
  );
};

export default BaseNativeSelect;
