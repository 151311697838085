import { select, put } from "redux-saga/effects";
import { productActions } from "./index";
import { fetchProduct } from "./api";
import { fetchImage, fetchImages } from "../util/api";

function* requestProductsImages(code, entityWithProducts, action) {
  const images = yield fetchImages({
    items: entityWithProducts.map((entity) => entity?.product),
  });

  const productsWithImages = entityWithProducts.map((product, index) => ({
    ...product,
    image: { ...images[index] },
  }));

  yield put(
    action({
      items: productsWithImages,
      code,
    })
  );
}

export function* requestProductWithPrices({ payload }) {
  const state = yield select();
  const currencyAbbr = state.currency.current.abbr;

  try {
    const { code, cat, includeAdditionalResults } = payload;
    const response = yield fetchProduct({
      code,
      cat,
      currencyAbbr,
      includeAdditionalResults,
    });

    if (response[0]) {
      const { equivalents, analogs, forms } = response[0].included;

      if (equivalents?.length) {
        yield requestProductsImages(
          code,
          equivalents,
          productActions.setInStockEquivalents
        );
      }

      if (analogs?.length) {
        yield requestProductsImages(
          code,
          analogs,
          productActions.setProductAnalogs
        );
      }

      if (forms?.length) {
        yield requestProductsImages(
          code,
          forms,
          productActions.setProductSaltForms
        );
      }

      yield put(productActions.setProductWithPrices(response[0]));
    }

    if (typeof payload?.onSuccess === "function") {
      yield payload?.onSuccess();
    }
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}

export function* requestImage({ payload }) {
  try {
    const state = yield select();
    const {
      item: { product },
    } = state.product;
    const { images } = state.util;
    let image = images.find((el) => el.code === product.code);
    if (!image) {
      const res = yield fetchImage({
        code: product.code,
        catalog: product.catalog,
      });
      if (res[0]) {
        image = { code: product.code, data: res[0].image };
      }
    }
    yield put(productActions.setImage(image));
  } catch (e) {
    console.log("Something went wrong!", e);
  } finally {
    if (typeof payload?.finally === "function") {
      yield payload?.finally();
    }
  }
}
