import { select } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import loadersActions from "../../features/Loaders/redux/actions";
import superCartActions from "../../features/super-cart/redux/actions";
import {
  fetchAddAddress,
  fetchAddresses,
  fetchEditAddress,
  fetchDeleteAddress,
  fetchAddressesAndPersons,
  fetchPersons,
  fetchAddPerson,
  fetchDeletePerson,
  fetchEditPerson,
  fetchProfile,
  fetchUser,
} from "./api";
import { userActions } from "./index";
import { toast } from "react-toastify";

export function* requestUser() {
  try {
    yield put(userActions.setUserLoading(true));
    const payload = yield call(fetchUser);
    yield put(userActions.setUser(payload));
    yield put(userActions.setUserReady(true));
  } catch (e) {
    console.log("Something went wrong!", e);
  } finally {
    yield put(userActions.setUserLoading(false));
  }
}

export function* requestProfile() {
  try {
    yield put(userActions.setProfileError(""));
    yield put(userActions.setProfileLoading(true));
    const payload = yield call(fetchProfile);

    yield put(userActions.setProfile(payload));
  } catch (e) {
    //@TODO update logic for error handling
    toast.error("Something went wrong. Please try again later.");
    yield put(userActions.setProfileError("Error while loading profile data"));
    console.log(e);
  } finally {
    yield put(userActions.setProfileLoading(false));
  }
}

export function* logout({ payload }) {
  try {
    const { cb, redirectTo } = payload || {};

    const state = yield select();

    const { isAuth } = state.user;

    if (isAuth) {
      yield put(superCartActions.clear());
    }

    yield put(loadersActions.setLoaderFullPage(true));

    yield put(userActions.setLogout({ redirectTo }));

    if (cb && typeof cb === "function") {
      yield cb();
    }

    yield put(loadersActions.setLoaderFullPage(false));
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}

// V2
export function* requestAddressesAndPersons() {
  try {
    yield put(userActions.setAddressesAndPersonsLoading(true));
    const response = yield fetchAddressesAndPersons();
    yield put(userActions.setAddressesAndPersons(response));
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error(
      "Something went wrong! Requesting Addresses And Persons",
      error
    );
  } finally {
    yield put(userActions.setAddressesAndPersonsLoading(false));
  }
}

export function* requestPersons() {
  try {
    yield put(userActions.setPersonsLoading(true));
    const response = yield fetchPersons();
    if (response[0]) {
      yield put(userActions.setPersons(response[0]));
    }
  } catch (error) {
    console.log("Something went wrong! Request contact persons", error);
  } finally {
    yield put(userActions.setPersonsLoading(false));
  }
}

export function* requestAddPerson({ payload: { newPersonData, onSuccess } }) {
  try {
    yield put(userActions.setPersonsLoading(true));
    const response = yield fetchAddPerson(newPersonData);
    if (response[0]) {
      yield put(userActions.addPerson(response[0]));
      yield onSuccess();
    } else {
      yield put(userActions.setPersonsError(response[1]));
    }
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Add person", error);
  } finally {
    yield put(userActions.setPersonsLoading(false));
  }
}

export function* requestDeletePerson({ payload }) {
  try {
    const response = yield fetchDeletePerson(payload);
    if (response[2] === 200) {
      yield put(userActions.deletePerson(payload));
    } else {
      toast.error(response[1]);
    }
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Delete contact person", error);
  }
}
export function* requestEditPerson({
  payload: { editedPersonData, onSuccess },
}) {
  try {
    const response = yield fetchEditPerson(editedPersonData);
    if (response[0]) {
      yield put(
        userActions.editPerson({
          editedPerson: response[0],
          oldUuid: editedPersonData.uuid,
        })
      );
      yield onSuccess();
    } else {
      yield put(userActions.setPersonsError(response[1]));
    }
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Edit contact person", error);
  }
}

//User Profile Addresses / Shipping Addresses

export function* requestAddresses() {
  try {
    yield put(userActions.setAddressesLoading(true));
    const response = yield fetchAddresses();
    if (response[0]) {
      yield put(userActions.setAddresses(response[0]));
    }
  } catch (error) {
    console.log("Something went wrong! Request addresses", error);
  } finally {
    yield put(userActions.setAddressesLoading(false));
  }
}

export function* requestAddAddress({ payload: { newAddressData, onSuccess } }) {
  try {
    yield put(userActions.setAddressesLoading(true));
    const response = yield fetchAddAddress(newAddressData);
    if (response[0]) {
      yield put(userActions.addAddress(response[0]));
      yield onSuccess();
    } else {
      yield put(userActions.setAddressesError(response[1]));
    }
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Add address", error);
  } finally {
    yield put(userActions.setAddressesLoading(false));
  }
}

export function* requestDeleteAddress({ payload }) {
  try {
    const response = yield fetchDeleteAddress(payload);
    if (response[2] === 200) {
      yield put(userActions.deleteAddress(payload));
    } else {
      toast.error(response[1]);
    }
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Delete address", error);
  }
}

export function* requestEditAddress({
  payload: { editedAddressData, onSuccess },
}) {
  try {
    const response = yield fetchEditAddress(editedAddressData);
    if (response[0]) {
      yield put(
        userActions.editAddress({
          editedAddress: response[0],
          oldUuid: editedAddressData.uuid,
        })
      );
      yield onSuccess();
    } else {
      yield put(userActions.setAddressesError(response[1]));
    }
  } catch (error) {
    // @TODO Rethink how to handle errors in a better way
    console.error("Something went wrong! Edit address", error);
  }
}
