import React from "react";
import DialogCore from "shared/ui/DialogCore/DialogCore";
import { ButtonWrapper } from "./DialogCancelOrder.styled";
import { Button } from "@mui/material";

type DeleteDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelOrder: VoidFunction;
};

const DialogCancelOrder = ({
  open,
  setOpen,
  onCancelOrder,
}: DeleteDialogProps) => {
  return (
    <DialogCore
      open={open}
      onClose={() => setOpen(false)}
      title="Are you sure you want to cancel order?"
      maxWidth="sm"
    >
      <ButtonWrapper>
        <Button
          variant="outlined"
          dataTestAttr="cancel-button"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          dataTestAttr="delete-button"
          onClick={onCancelOrder}
        >
          Yes, cancel order
        </Button>
      </ButtonWrapper>
    </DialogCore>
  );
};

export default DialogCancelOrder;
