import { PromoCodeRejectionReasons } from "./PromoCode.types";

export const PromoCodeRejectionReasonsMessages = {
  [PromoCodeRejectionReasons.PROMO_CODE_NOT_EXISTS]:
    "The promo code you entered has either expired or is not yet active",
  [PromoCodeRejectionReasons.PROM_CODE_HAS_BEEN_USED]:
    "This promo code has already been used",
  [PromoCodeRejectionReasons.BUCKET_SUM_IS_LESSER_THAN_PROMO_CODE_LIMIT]: (
    priceLimit: string,
    currencySymbol: string
  ) =>
    `Your order total lower than allowable minimal price of ${currencySymbol}${priceLimit}. Please update your cart to proceed with promo code`,

  [PromoCodeRejectionReasons.BUCKET_SUM_IS_GREATER_THAN_PROMO_CODE_LIMIT]: (
    priceLimit: string,
    currencySymbol: string
  ) =>
    `Your order total exceeds the allowable limit of ${currencySymbol}${priceLimit}. Please update your cart to proceed with promo code`,
  [PromoCodeRejectionReasons.BUCKET_CANNOT_BE_CALCULATED]:
    "Promo code could not be added as the order contains items which need price confirmation.",
};
