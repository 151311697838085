import { Box, Chip } from "@mui/material";
import styled from "styled-components";

export const PaymentTypeWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const OrderPayStatusChip = styled(Chip)`
  display: inline-flex;
  max-width: max-content;
  margin-left: 6px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  height: 20px !important;
  "& span": {
    padding: "0 10px";
  }
`;
