const rootPages = {
  PROFILE: "profile",
  SIGNIN: "login",
  SIGNUP: "signup",
  RESET_PASSWORD: "reset-password",
  DRAW_SEARCH: "draw-search",
  SEARCH: "search",
  CHECKOUT: "checkout",
  CATALOG: "catalog",
  CART: "cart",
  LOGOUT: "logout",
  SUBMIT_PAYMENT_FORM: "submit-payment-form",
  PAYMENT_STATUS: "payment-status",
  REDIRECT_TO_OLD_STORE: "redirect-to-old-store",


  // payment
  PAYMENT_CHECK: 'payment',
};

export default rootPages;
