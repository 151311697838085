import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "redux/user";
import { ContactPersonEntity, ContactPersonPayload } from "shared/types/user";
import ContactPersonCard from "./ContactPersonCard";

type ContactPersonCardContainerProps = {
  contactPersonData: ContactPersonEntity;
};

const ContactPersonCardContainer = ({
  contactPersonData,
}: ContactPersonCardContainerProps) => {
  const [isOpenDeleteContactPerson, setIsOpenDeleteContactPerson] =
    useState(false);
  const [isOpenEditContactPerson, setIsOpenEditContactPerson] = useState(false);
  const dispatch = useDispatch();

  const { uuid, title, firstName, lastName, phone, email, company } =
    contactPersonData;

  const contactPersonsConfig = useMemo(() => {
    return [
      { title: "Title", value: title, maxWidth: "50px" },
      { title: "First Name", value: firstName, maxWidth: "100px" },
      { title: "Last Name", value: lastName, maxWidth: "140px" },
      { title: "Email", value: email, maxWidth: "245px" },
      { title: "Phone Number", value: phone, maxWidth: "140px" },
      { title: "Company", value: company, maxWidth: "305px" },
    ];
  }, [title, firstName, lastName, phone, email, company]);

  const handleToggleEditOpen = () => {
    dispatch(userActions.setPersonsError(null));
    setIsOpenEditContactPerson((prevState) => !prevState);
  };

  const handleToggleDeleteOpen = () => {
    setIsOpenDeleteContactPerson((prevState) => !prevState);
  };

  const handleEdit = (formValues: ContactPersonPayload) => {
    dispatch(
      userActions.requestEditPerson({
        editedPersonData: formValues,
        onSuccess: handleToggleEditOpen,
      })
    );
  };

  const handleDelete = () => {
    dispatch(userActions.requestDeletePerson(uuid));
  };

  return (
    <ContactPersonCard
      contactPersonsConfig={contactPersonsConfig}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      contactPersonData={contactPersonData}
      isOpenDeleteContactPerson={isOpenDeleteContactPerson}
      handleToggleDeleteOpen={handleToggleDeleteOpen}
      isOpenEditContactPerson={isOpenEditContactPerson}
      handleToggleEditOpen={handleToggleEditOpen}
    />
  );
};
export default ContactPersonCardContainer;
