import styled from "styled-components";
import { Box, Chip } from "@mui/material";
import { blue } from "../../../../../../ui/colors";

export const OrderHeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;

export const OrderHeaderInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const OrderHeaderControlsWrapper = styled(Box)`
  display: flex;
  gap: 12px;
`;

export const OrderTitleWrapper = styled.header`
  display: flex;
  margin: 12px 0;
`;

export const OrderPayStatusChip = styled(Chip)`
  display: inline-flex;
  max-width: max-content;
  margin-left: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  height: 22px !important;
  span {
    padding: 0 12px;
  }
`;

export const DateTimeWrapper = styled(Box)`
  color: ${blue};
`;

export const TimeStatusWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
