import styled from "styled-components";
import { Box, Typography } from "@mui/material";

export const FormRow = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
  & > * {
    flex-grow: 1;
  }
`;
export const InputWrapper = styled(Box)``;
export const InputLabelTypography = styled(Typography)`
  font-size: 14px;
  margin-bottom: 6px;
`;

export const SubmitWrapper = styled(Box)`
  margin-top: 28px;
`;
export const ErrorWrapper = styled.div`
  margin-top: 16px;
  white-space: break-all;
`;
