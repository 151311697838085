import { endpoints } from "constants";
import { customFetchDelayedMiddleware } from "context/sseContext";
import { BucketItem } from "shared/types/cartItem";
import { CurrencyType } from "shared/types/pricing";
import customFetch, { RequestMethods } from "utils/customFetch";

export const fetchAllToCart = ({ searchParams, data, currency = "USD" }) => {
  return customFetchDelayedMiddleware({
    method: RequestMethods.PUT,
    url: `${endpoints.FROM_SEARCH}/${searchParams}?includeBucketInResponse=false&currency=${currency}`,
    data,
  });
};

export const fetchCartInfo = async (
  currency: CurrencyType = CurrencyType.USD
) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: `${endpoints.BUCKET_CONTENT}?currency=${currency}`,
  });
};

export const fetchAddItemToCart = async (
  items,
  currency: CurrencyType = CurrencyType.USD
) => {
  return await customFetchDelayedMiddleware({
    url: `${endpoints.BUCKET_CONTENT}?includeBucketInResponse=false&currency=${currency}`,
    method: RequestMethods.PUT,
    data: items,
  });
};

export const fetchDeleteItemFromCart = async (uuid: string) => {
  return await customFetchDelayedMiddleware({
    url: `${endpoints.BUCKET_BY_UUID}/${uuid}?includeBucketInResponse=false&currency=USD`,
    method: RequestMethods.DELETE,
  });
};

export const fetchUpdateCartItemCount = async (
  newCountData: BucketItem,
  currency: CurrencyType = CurrencyType.USD
) => {
  const { addedToBucket } = newCountData;
  return await customFetchDelayedMiddleware({
    url: `${endpoints.BUCKET_BY_UUID}/${addedToBucket.uuid}/count/${addedToBucket.count}?includeBucketInResponse=false&currency=${currency}`,
    method: RequestMethods.PATCH,
    data: {
      count: addedToBucket.count,
    },
  });
};

export const fetchUpdateCartItemWeight = async (
  newWeightData: BucketItem,
  currency: CurrencyType = CurrencyType.USD
) => {
  const { addedToBucket } = newWeightData;

  return await customFetchDelayedMiddleware({
    url: `${endpoints.BUCKET_BY_UUID}/${addedToBucket.uuid}/weight?includeBucketInResponse=false&currency=${currency}`,
    method: RequestMethods.PATCH,

    data: {
      amount: addedToBucket.weight.amount,
      measure: addedToBucket.weight.measure,
    },
  });
};

export const fetchClearCart = () => {
  return customFetchDelayedMiddleware({
    method: RequestMethods.DELETE,
    url: endpoints.BUCKET_CONTENT,
  });
};

export const addBillingAddress = async (data) => {
  return customFetch({
    method: RequestMethods.POST,
    url: `${endpoints.CART_ADD_BILLING_ADDRESS}`,
    data,
  });
};

export const fetchDeleteItems = async (uuids: string[]) => {
  return await customFetchDelayedMiddleware({
    url: `${endpoints.BUCKET_BY_UUIDS}?includeBucketInResponse=true&currency=USD`,
    method: RequestMethods.DELETE,
    data: uuids,
  });
};

export default {
  fetchAllToCart,
  fetchCartInfo,
  fetchDeleteItemFromCart,
  fetchUpdateCartItemCount,
  fetchClearCart,
  addBillingAddress,
  fetchAddItemToCart,
  fetchUpdateCartItemWeight,
  fetchDeleteItems,
};
