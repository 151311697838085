import { useEffect, useState } from "react";
import { useQuery } from "hooks";

const useBackToOrdersLink = (toOrdersLinkBase?: string) => {
  const toOrdersLink = toOrdersLinkBase ?? "/profile/orders";

  const query = useQuery();
  const fromPage = parseInt(query.get("fromPage"));
  const [backToOrdersLink, setBackToOrdersLink] = useState(toOrdersLink);

  useEffect(() => {
    if (fromPage) {
      const newLink = `${toOrdersLink}?curPage=${fromPage}`;
      setBackToOrdersLink(newLink);
    } else {
      setBackToOrdersLink(toOrdersLink);
    }
  }, [fromPage, toOrdersLink]);

  return backToOrdersLink;
};

export default useBackToOrdersLink;
