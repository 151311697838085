import React, { useMemo } from "react";
import { Tabs } from "../index";
import { links } from "./ProfileSidebar.constants";
import { getLinkHref } from "./ProfileSidebar.utils";
import { SideBarContainerLink } from "./ProfileSidebar.types";
import { useLocation } from "react-router";

type ProfileSidebarProps = {
  alias: string;
  title?: string | null;
  username?: string;
  avatarLetters?: string;
  handleClick: (link: SideBarContainerLink) => void;
  handleLogout?: () => void;
};

const ProfileSidebar = ({ alias, handleClick }: ProfileSidebarProps) => {
  const { pathname } = useLocation();

  const formattedLinks = useMemo(() => {
    if (pathname.match(/archived-orders/g)?.length) {
      return links.filter(
        (profileTabsLink) => profileTabsLink.alias !== "orders"
      );
    }

    return links.filter(
      (profileTabsLink) => profileTabsLink.alias !== "archived-orders"
    );
  }, [pathname]);

  return (
    <Tabs
      navigation={
        <>
          {formattedLinks.map((link, index) => {
            const active = alias === link.alias;

            return (
              <Tabs.Button
                key={index}
                active={active}
                onClick={() => handleClick(link)}
                to={getLinkHref(link.alias)}
              >
                {link.title}
              </Tabs.Button>
            );
          })}
        </>
      }
    />
  );
};

export default ProfileSidebar;
