import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import { accentColor, darkBlue } from "ui/colors";

export const Title = styled(Typography)`
  color: ${darkBlue};
  font-weight: 700;
  line-height: 28px;
  margin: 10px 0;
`;
export const ContactPersonsCardContainerWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  & > * {
    margin-bottom: 18px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;
export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;
export const StyledHeaderButton = styled(Button)`
  padding: 12px 26px;
  border-color: ${accentColor};
  font-size: 20px;
  line-height: 24px;
`;
