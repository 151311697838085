import React from "react";
import { ContactPersonEntity, ContactPersonPayload } from "shared/types/user";
import CreatePersonForm from "shared/ui/CreatePerson/CreatePerson.form";
import DialogCore from "shared/ui/DialogCore/DialogCore";

type ContactPersonDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  formValues?: ContactPersonEntity;
  handleSubmit: (
    formValues: ContactPersonPayload | ContactPersonEntity
  ) => void;
};

const ContactPersonDialog = ({
  open,
  setOpen,
  title,
  formValues,
  handleSubmit,
}: ContactPersonDialogProps) => {
  return (
    <DialogCore title={title} open={open} onClose={() => setOpen(false)}>
      <CreatePersonForm onSubmit={handleSubmit} formValues={formValues} />
    </DialogCore>
  );
};
export default ContactPersonDialog;
