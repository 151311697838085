import { useEffect } from "react";
import { useAmplitudeState } from "../../context/AmplitudeContext";
import { fireInitialEvent } from "./utils";

const AmplitudeInitialTrigger = () => {
  const { amplitude } = useAmplitudeState();

  useEffect(() => {
    const timeNow = Date.now();
    fireInitialEvent(amplitude, timeNow);
  }, [amplitude]);
};

export default AmplitudeInitialTrigger;
