import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Currency } from "../../../../shared/types/pricing";
import {
  AddToCartButton,
  TableCellCatalogList,
  TableHeadCatalogList,
} from "../CatalogListCard.styled";
import { CartIcon } from "../../../icons";
import { Price } from "../../../../typings/DTO/data-contracts";
import { BucketItem } from "shared/types/cartItem";
import { AvailabilityStatus } from "shared/types/delivery";

type CatalogListTableProps = {
  catalogItem: BucketItem;
  currency: Currency;
  handleAddListItemToCart: (
    cardItem: BucketItem,
    selectedCardItemPrice: Price
  ) => void;
  isAddingToCart: boolean;
};

const CatalogListTable = ({
  catalogItem,
  currency,
  handleAddListItemToCart,
  isAddingToCart,
}: CatalogListTableProps) => {
  const tableDataRows = catalogItem?.prices?.all?.map((itemPrice) => {
    const pack = `${itemPrice?.weight?.amount} ${itemPrice?.weight?.measure}`;
    const price = `${itemPrice.price} ${currency?.abbr}`;
    const availability =
      itemPrice?.weight?.available === AvailabilityStatus.IN_STOCK
        ? "In stock"
        : "Synthesis";
    const stockLocations = itemPrice?.weight?.stockLocations?.join(", ");

    return {
      pack,
      price,
      availability,
      stockLocations,
    };
  });

  const handleAddItemToCart = (cardItemSelectedPrice: any) => {
    handleAddListItemToCart(catalogItem, cardItemSelectedPrice);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, border: "none" }}>
        <TableHeadCatalogList>
          <TableRow>
            <TableCellCatalogList>Pack</TableCellCatalogList>
            <TableCellCatalogList align="right">Price</TableCellCatalogList>
            <TableCellCatalogList align="right">
              Availability
            </TableCellCatalogList>
            <TableCellCatalogList align="right">Location</TableCellCatalogList>
            <TableCellCatalogList align="right"></TableCellCatalogList>
          </TableRow>
        </TableHeadCatalogList>
        <TableBody>
          {tableDataRows?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCellCatalogList
                sx={{ fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {row.pack}
              </TableCellCatalogList>
              <TableCellCatalogList align="right">
                {row.price}
              </TableCellCatalogList>
              <TableCellCatalogList align="right">
                {row.availability}
              </TableCellCatalogList>
              <TableCellCatalogList align="right">
                {row.stockLocations}
              </TableCellCatalogList>
              <TableCellCatalogList align="right">
                <AddToCartButton
                  iconLeft={<CartIcon />}
                  fullWidth
                  size="small"
                  onClick={() =>
                    handleAddItemToCart(catalogItem?.prices?.all[index])
                  }
                  isLoading={isAddingToCart}
                  data-test="add_to_cart_button"
                />
              </TableCellCatalogList>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CatalogListTable;
