import { OrderEvents, OrderPaymentMethods } from "./Order.types";
import {
  OrderEventsOrderLabels,
  OrderEventsOrderStatusTypes,
} from "../../../../../shared/types/Orders.types";
import React from "react";

type GenerateOrderStepperProps = {
  events: OrderEvents;
  paymentMethod: OrderPaymentMethods;
};

type GenerateQuoteStepperProps = {
  events: OrderEvents;
};

export const generateOrderStepper = ({
  events,
  paymentMethod,
}: GenerateOrderStepperProps) => {
  return [
    {
      // Mock status for visual representation
      status: "ORDER_NEW",
      filledPercent:
        paymentMethod === "CARD" || events?.order?.length ? 100 : 50,
      label: "New",
    },
    {
      status:
        OrderEventsOrderLabels[OrderEventsOrderStatusTypes.ORDER_PROCESSING],
      filledPercent: events.order.some(
        ({ status }) =>
          status === OrderEventsOrderStatusTypes.ORDER_PROCESSING ||
          status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
      )
        ? 100
        : events.order.some(
            ({ status }) =>
              status === OrderEventsOrderStatusTypes.ORDER_ACTION_REQUIRED
          )
        ? 50
        : 0,
      label: events.order.some(
        ({ status }) =>
          status === OrderEventsOrderStatusTypes.ORDER_ACTION_REQUIRED
      ) ? (
        <>
          Processing <span style={{ color: "#FF8A00" }}>! Action required</span>
        </>
      ) : (
        OrderEventsOrderLabels[OrderEventsOrderStatusTypes.ORDER_PROCESSING]
      ),
    },
    ...(!events.order.some(
      ({ status }) => status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
    )
      ? [
          {
            status: OrderEventsOrderStatusTypes.ORDER_SHIPPED,
            filledPercent: events.order.some(
              ({ status }) =>
                status === OrderEventsOrderStatusTypes.ORDER_SHIPPED
            )
              ? 100
              : events.order.some(
                  ({ status }) =>
                    status ===
                    OrderEventsOrderStatusTypes.ORDER_PARTIALLY_SHIPPED
                )
              ? 50
              : 0,
            label: events.order.some(
              ({ status }) =>
                status === OrderEventsOrderStatusTypes.ORDER_PARTIALLY_SHIPPED
            )
              ? OrderEventsOrderLabels[
                  OrderEventsOrderStatusTypes.ORDER_PARTIALLY_SHIPPED
                ]
              : OrderEventsOrderLabels[
                  OrderEventsOrderStatusTypes.ORDER_SHIPPED
                ],
          },
        ]
      : []),
    ...(!events.order.some(
      ({ status }) => status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
    )
      ? [
          {
            status: OrderEventsOrderStatusTypes.ORDER_COMPLETED,
            filledPercent: events.order.some(
              ({ status }) =>
                status === OrderEventsOrderStatusTypes.ORDER_COMPLETED ||
                status === OrderEventsOrderStatusTypes.ORDER_RETURNED
            )
              ? 100
              : 0,
            label:
              OrderEventsOrderLabels[
                OrderEventsOrderStatusTypes.ORDER_COMPLETED
              ],
          },
        ]
      : []),
    ...(events.order.some(
      ({ status }) => status === OrderEventsOrderStatusTypes.ORDER_RETURNED
    )
      ? [
          {
            status: OrderEventsOrderStatusTypes.ORDER_RETURNED,
            filledPercent: 100,
            label:
              OrderEventsOrderLabels[
                OrderEventsOrderStatusTypes.ORDER_RETURNED
              ],
          },
        ]
      : []),
    ...(events.order.some(
      ({ status }) => status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
    )
      ? [
          {
            status: OrderEventsOrderStatusTypes.ORDER_CANCELLED,
            filledPercent: 100,
            label:
              OrderEventsOrderLabels[
                OrderEventsOrderStatusTypes.ORDER_CANCELLED
              ],
          },
        ]
      : []),
  ];
};

export const generateQuoteStepper = ({ events }: GenerateQuoteStepperProps) => {
  return [
    {
      // Mock status for visual representation
      status: "ORDER_NEW",
      filledPercent: 100,
      label: "New",
    },
    {
      status:
        OrderEventsOrderLabels[OrderEventsOrderStatusTypes.ORDER_PROCESSING],
      filledPercent: events.order.some(
        ({ status }) => status === OrderEventsOrderStatusTypes.QUOTE_USED
      )
        ? 100
        : 0,
      label:
        OrderEventsOrderLabels[OrderEventsOrderStatusTypes.ORDER_PROCESSING],
    },
    {
      status: OrderEventsOrderLabels[OrderEventsOrderStatusTypes.QUOTE_USED],
      filledPercent: events.order.some(
        ({ status }) => status === OrderEventsOrderStatusTypes.QUOTE_USED
      )
        ? 100
        : 0,
      label: OrderEventsOrderLabels[OrderEventsOrderStatusTypes.QUOTE_USED],
    },
  ];
};
