import {
  CLOSE_ACCORDION,
  OPEN_ACCORDION,
  SET_DATA,
  SET_INITIAL,
  CHANGE_INPUT,
  SET_ADDITIONAL_COSTS_LOADING,
  SET_ADDITIONAL_COSTS_ERROR,
  SET_ADDITIONAL_COSTS,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_METHODS_LOADING,
  SET_PAYMENT_METHODS_ERROR,
  SET_BILLING_PERSON_UUID,
  SET_SHIPPING_PERSON_UUID,
  SET_ADDRESS_SHIPPING_UUID,
  SET_ADDRESS_BILLING_UUID,
  SET_REQUEST_DATA,
  SET_CALCULATIONS,
  SET_CONDITIONS_BY_SHIPPING_ADDRESS,
} from "./types";
import { closeAccordion, openAccordion } from "./utils";

type InitialState = {
  data: any;
  paymentMethods: any;
  additionalCosts: any;
  // V2
  // @TODO Change naming: stepper
  accordion: {
    [key: string]: {
      open: boolean;
      valid: boolean;
      disabled: boolean;
    };
  };
  // @TODO Move requestData type
  requestData: {
    type?: "ORDER" | "QUOTE";
    persons?: {
      billingUuid?: string;
      shippingUuid?: string;
    };
    addresses?: {
      billingUuid?: string;
      shippingUuid?: string;
    };
    // @TODO Check how to optimise
    shippingService: { id: number; title: string; name: string };
    purchaseOrderNumber?: string;
    comment?: string;
    partialShipping: boolean;
    overnightDelivery: boolean;
  };
  // @TODO Specify types
  calculations: any;
  conditionsByAddressShipping: any;
};

const initialState: InitialState = {
  data: {},
  paymentMethods: {
    data: [],
    loading: "init",
    error: "",
  },
  additionalCosts: {
    data: null,
    loading: "init",
    error: "",
  },
  accordion: {
    step1: {
      open: true,
      valid: false,
      disabled: false,
    },
    step2: {
      open: false,
      valid: false,
      disabled: true,
    },
    step3: {
      open: false,
      valid: false,
      disabled: true,
    },
    step4: {
      open: false,
      valid: false,
      disabled: true,
    },
    step5: {
      open: false,
      valid: false,
      disabled: true,
    },
    step6: {
      open: false,
      valid: false,
      disabled: true,
    },
  },

  // V2
  requestData: {
    type: "ORDER",
    // @TODO Check how to optimise
    shippingService: { id: 0, title: "Enamine", name: "Enamine" },
    partialShipping: false,
    overnightDelivery: false,
  },

  calculations: null,
  conditionsByAddressShipping: null,
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case CHANGE_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          carrierId: action.payload,
        },
      };
    case CLOSE_ACCORDION:
      return {
        ...state,
        accordion: closeAccordion(state.accordion, action.payload),
      };
    case OPEN_ACCORDION:
      return {
        ...state,
        accordion: openAccordion(state.accordion, action.payload),
      };
    case SET_INITIAL:
      return {
        ...initialState,
      };
    case SET_ADDITIONAL_COSTS_LOADING:
      return {
        ...state,
        additionalCosts: {
          ...state.additionalCosts,
          loading: action.payload,
        },
      };
    case SET_ADDITIONAL_COSTS_ERROR:
      return {
        ...state,
        additionalCosts: {
          ...state.additionalCosts,
          error: action.payload,
        },
      };
    case SET_ADDITIONAL_COSTS:
      return {
        ...state,
        additionalCosts: {
          ...state.additionalCosts,
          data: action.payload,
        },
      };
    case SET_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: action.payload,
        },
      };
    case SET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          error: action.payload,
        },
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: action.payload,
        },
      };

    // V2
    case SET_SHIPPING_PERSON_UUID:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          persons: {
            ...state.requestData.persons,
            shippingUuid: action.payload,
          },
        },
      };
    case SET_BILLING_PERSON_UUID:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          persons: {
            ...state.requestData.persons,
            billingUuid: action.payload,
          },
        },
      };
    case SET_ADDRESS_SHIPPING_UUID:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          addresses: {
            ...state.requestData.addresses,
            shippingUuid: action.payload,
          },
        },
      };
    case SET_ADDRESS_BILLING_UUID:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          addresses: {
            ...state.requestData.addresses,
            billingUuid: action.payload,
          },
        },
      };

    case SET_REQUEST_DATA:
      // eslint-disable-next-line no-case-declarations
      const { data, removeFields } = action.payload;

      // eslint-disable-next-line no-case-declarations
      const updatedRequestData = { ...state.requestData, ...data };

      if (removeFields && Array.isArray(removeFields)) {
        removeFields.forEach((field) => {
          delete updatedRequestData[field];
        });
      }

      return {
        ...state,
        requestData: updatedRequestData,
      };

    case SET_CALCULATIONS:
      return {
        ...state,
        calculations: action.payload,
      };

    case SET_CONDITIONS_BY_SHIPPING_ADDRESS:
      return {
        ...state,
        conditionsByAddressShipping: action.payload,
      };

    default:
      return state;
  }
};

export default checkoutReducer;
