import {
  REQUEST_UTILS,
  REQUEST_IMAGES,
  RESET,
  SET_CARRIERS,
  SET_CATALOGS,
  SET_CATALOG_WEIGHTS,
  SET_COUNTRIES,
  SET_IMAGES,
  SET_US_STATES,
  SET_VAT_PATTERNS,
} from "./types";

const requestImages = (items) => {
  return {
    type: REQUEST_IMAGES,
    payload: items,
  };
};

const setCatalogs = (catalogs) => {
  return {
    type: SET_CATALOGS,
    payload: catalogs,
  };
};

const setCountries = (countries) => {
  return {
    type: SET_COUNTRIES,
    payload: countries,
  };
};

const setImages = (images) => {
  return {
    type: SET_IMAGES,
    payload: images,
  };
};

const setVatPatterns = (vatPatterns) => {
  return {
    type: SET_VAT_PATTERNS,
    payload: vatPatterns,
  };
};

const setCatalogWeights = (weights) => {
  return {
    type: SET_CATALOG_WEIGHTS,
    payload: weights,
  };
};

const setUsStates = (usStates) => {
  return {
    type: SET_US_STATES,
    payload: usStates,
  };
};

const setCarriers = (carriers) => {
  return {
    type: SET_CARRIERS,
    payload: carriers,
  };
};

const reset = () => {
  return {
    type: RESET,
  };
};

const requestUtils = () => {
  return {
    type: REQUEST_UTILS,
  };
};

export default {
  requestImages,
  setCatalogs,
  setCountries,
  setCarriers,
  setVatPatterns,
  setUsStates,
  setCatalogWeights,
  setImages,
  reset,
  requestUtils,
};
