import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { CartMessage } from "ui/cart-message/cart-message";
import { useAmplitudeState } from "../../../../../context/AmplitudeContext";
import { checkoutActions } from "../../../../../redux/checkout";
import { AmplitudeEvents } from "../../../../../context/AmplitudeEvents";
import { UserMessage } from "../../../../../ui/user-message/user-message";
import { common } from "../../../../../constants";
import { CheckoutContext } from "../../../lib/Checout.context";
import { CHECKOUT_MODES } from "../../../model/Checkout.contants";

const ThankPaid = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const { currentMode } = useContext(CheckoutContext);

  const handleOrdersClick = () => {
    dispatch(checkoutActions.setInitial());
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_ORDERS);
  };

  const handleSearchCluck = () => {
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_SEARCH);
  };

  return (
    <CartMessage
      variant={currentMode === CHECKOUT_MODES.THANK_PAID ? "paid" : "unpaid"}
    >
      <CartMessage.Title>
        {currentMode === CHECKOUT_MODES.THANK_PAID
          ? " Thank you, your order is successfully paid"
          : "Sorry, there is currently an issue with processing payment for your order"}
      </CartMessage.Title>
      {currentMode === CHECKOUT_MODES.THANK_PAID ? (
        <CartMessage.Description>
          Wait for payment confirmation on your email address.
          <br />
          If you don't receive an email within a few minutes or need further
          assistance, please contact our{" "}
          <UserMessage.ExternalLink href={common.CONTACTS_HREF} target="_blank">
            support team
          </UserMessage.ExternalLink>
        </CartMessage.Description>
      ) : (
        <CartMessage.Description>
          Please note that your order remains unpaid at this time. We kindly
          request that you attempt to complete the payment via your profile.
          <br />
          If the issue will persist feel free to contact us via the link below.
        </CartMessage.Description>
      )}
      <CartMessage.ActionWrapper>
        <CartMessage.Link to={"/profile/orders"} onClick={handleOrdersClick}>
          Check my orders
        </CartMessage.Link>
        {currentMode === CHECKOUT_MODES.THANK_PAID ? (
          <CartMessage.Link to={"/"} onClick={handleSearchCluck}>
            Search compounds
          </CartMessage.Link>
        ) : (
          <UserMessage.ExternalLink href={common.CONTACTS_HREF} target="_blank">
            Contact our support team
          </UserMessage.ExternalLink>
        )}
      </CartMessage.ActionWrapper>
    </CartMessage>
  );
};

export default ThankPaid;
