import React from "react";
import RadioCardV2 from "../../../../../../shared/ui/RadioCardV2/RadioCardV2";
import { Typography } from "@mui/material";
import { Button } from "../../../../../../ui";
import { AddressEntity } from "../../../../../../shared/types/user";
import CreateAddress from "./CreateAddress/CreateAddress";
import { useSelector } from "react-redux";
import { utilSelectors } from "../../../../../../redux/util";
import { AddressWrapper } from "./AddressesContent.styled";
import { combineAddressInfo } from "../../../../../../shared/lib/Addresses.utils";

type AddressesContentProps = {
  type: "Shipping" | "Billing";
  addresses: AddressEntity[];
  selectedAddressUuid: string | null;
  onChangeBillingAddressUuid: (addressUuid?: string) => void;
  onNextStep: VoidFunction;
};

const AddressesContent = ({
  type,
  addresses,
  selectedAddressUuid,
  onChangeBillingAddressUuid,
  onNextStep,
}: AddressesContentProps) => {
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);
  return (
    <>
      {addresses?.map((address, index) => {
        const isActive = address?.uuid === selectedAddressUuid;

        const addressCombinedInfo = combineAddressInfo(
          address,
          countries,
          usStates
        );

        return (
          <AddressWrapper key={index}>
            <RadioCardV2
              isActive={isActive}
              handleChange={() => onChangeBillingAddressUuid(address?.uuid)}
            >
              <Typography variant="body1">{addressCombinedInfo}</Typography>
            </RadioCardV2>
          </AddressWrapper>
        );
      })}

      {/* @TODO Communicate to understand do we really need this condition */}
      {/*{Boolean(contactPersons?.length < 5) && <CreatePerson />}*/}
      <CreateAddress type={type} />

      {/* @TODO Temporary solution, change to mui button */}
      <Button
        disabled={!addresses?.length || !selectedAddressUuid}
        onClick={onNextStep}
        size="small"
        fullWidth
        style={{ marginTop: "18px" }}
      >
        Next
      </Button>
    </>
  );
};

export default AddressesContent;
