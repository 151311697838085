export const OrderItemEventsStatusConfig = {
  ITEM_PROCESSING: { label: "Processing", color: "primary" },
  ITEM_REGISTERED_FOR_SHIPPING: { label: "Registered", color: "primary" },
  ITEM_ACTION_REQUIRED: { label: "Action required", color: "warning" },
  ITEM_SYNTHESIS_ALLOWED: { label: "Synthesis allowed", color: "primary" },
  ITEM_SYNTHESIS_COMPLETED: { label: "Synthesis completed", color: "warning" },
  ITEM_SYNTHESIS_FAILED: { label: "Synthesis failed", color: "warning" },
  ITEM_QUALITY_CONTROL_IN_PROGRESS: {
    label: "QC in progress",
    color: "primary",
  },
  ITEM_QUALITY_CONTROL_FINISHED: {
    label: "QC in finished",
    color: "primary",
  },
  ITEM_CANCELLED: {
    label: "Cancelled",
    color: "error",
  },
  ITEM_PREPARED_FOR_SHIPPING: {
    label: "Prepared for shipping",
    color: "success",
  },
  ITEM_LOCATED_AT_WAREHOUSE: {
    label: "Located",
    color: "primary",
  },
  ITEM_PACKED: {
    label: "Packed",
    color: "primary",
  },
  ITEM_RETURNED: {
    label: "Returned",
    color: "warning",
  },
  ITEM_SHIPPED: {
    label: "Shipped",
    color: "success",
  },
};
