import styled from "styled-components";
import {
  accentColor,
  blue,
  borderColor,
  lightBlue,
  lightGray,
} from "../../../ui/colors";
import { Box } from "@mui/material";

export const RadioCardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 19px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  min-height: 56px;
  &:hover {
    border-color: ${accentColor};
    & > *:first-child {
      color: ${accentColor};
    }
  }

  & > *:first-child {
    margin-right: 9px;
  }

  ${({ isActive, isDisabled }) => {
    if (isActive) {
      return `
        border-color: ${accentColor};
        background-color: ${lightBlue};
      `;
    }
    if (isDisabled) {
      return `
        pointer-events: none;
        user-select: none;
        color: ${blue};
        background-color: ${lightGray};
      `;
    }
  }};
`;

export const HintWrapper = styled(Box)`
  position: absolute;
  top: -11px;
  right: 7px;
  border-radius: 30px;
  background-color: ${accentColor};
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
`;
