import React from "react";
import { useParams } from "react-router-dom";
import useBackToOrdersLink from "./hooks/useBackToOrdersLink";
import useFetchOrderWithDetails from "./hooks/useFetchOrderWithDetails";
import ArchivedOrder from "./ArchivedOrder";

const ArchivedOrderContainer = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const backToOrdersLink = useBackToOrdersLink("/profile/archived-orders");
  const order = useFetchOrderWithDetails({ orderId });

  return (
    <ArchivedOrder
      backToOrdersLink={backToOrdersLink}
      loading={order?.loading}
      details={order?.data?.details}
      items={order?.data?.items}
      orderId={orderId}
    />
  );
};

export default ArchivedOrderContainer;
