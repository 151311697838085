import AddressDialog from "pages/profile/pages/Addresses/ui/AddressDialog/AddressDialog";
import React from "react";
import { AddressEntity } from "shared/types/user";
import DeleteDialog from "shared/ui/DeleteDialog/DeleteDialog";
import { DeleteIcon, PencilIcon } from "ui/icons";
import {
  CardContainer,
  CompanyColumn,
  CountryCityColumn,
  FullWidthColumn,
  IconColumn,
  Label,
  PhoneColumn,
  Row,
  StyledButton,
  Value,
  ZipColumn,
} from "./AddressCard.styled";

type FormattedAddress = {
  id: string;
  title: string;
  value?: string;
};

type AddressCardProps = {
  addressData: AddressEntity;
  formattedAddresses: FormattedAddress[];
  handleEdit: (updatedAddress: AddressEntity) => void;
  handleDelete: () => void;
  isOpenDeleteAddress: boolean;
  handleToggleDeleteOpen: () => void;
  isOpenEditAddress: boolean;
  handleToggleEditOpen: () => void;
};

const AddressCard = ({
  formattedAddresses,
  addressData,
  handleEdit,
  handleDelete,
  isOpenDeleteAddress,
  handleToggleDeleteOpen,
  isOpenEditAddress,
  handleToggleEditOpen,
}: AddressCardProps) => {
  const addressItem = formattedAddresses.find(
    (item) => item.title === "Address"
  );
  return (
    <CardContainer>
      <Row>
        {Boolean(formattedAddresses.length) &&
          formattedAddresses.map((address, index) => {
            if (address.id === "company") {
              return (
                <CompanyColumn Column key={index}>
                  <Label>{address.title}</Label>
                  <Value>{address.value}</Value>
                </CompanyColumn>
              );
            }
            if (address.id === "country" || address.id === "city") {
              return (
                <CountryCityColumn key={index}>
                  <Label>{address.title}</Label>
                  <Value>{address.value}</Value>
                </CountryCityColumn>
              );
            }
            if (address.id === "zip") {
              return (
                <ZipColumn key={index}>
                  <Label>{address.title}</Label>
                  <Value>{address.value}</Value>
                </ZipColumn>
              );
            }
            if (address.id === "phone") {
              return (
                <PhoneColumn key={index}>
                  <Label>{address.title}</Label>
                  <Value>{address.value}</Value>
                </PhoneColumn>
              );
            }
            return null;
          })}
        <IconColumn>
          <StyledButton size="small" onClick={handleToggleEditOpen}>
            <PencilIcon />
          </StyledButton>
          <StyledButton size="small" onClick={handleToggleDeleteOpen}>
            <DeleteIcon />
          </StyledButton>
        </IconColumn>
      </Row>
      {addressItem && (
        <Row>
          <FullWidthColumn>
            <Label>{addressItem.title}</Label>
            <Value>{addressItem.value}</Value>
          </FullWidthColumn>
        </Row>
      )}
      <AddressDialog
        open={isOpenEditAddress}
        setOpen={handleToggleEditOpen}
        title="Edit address"
        formValues={addressData}
        handleSubmit={(addressData) => handleEdit(addressData)}
      />
      <DeleteDialog
        open={isOpenDeleteAddress}
        setOpen={handleToggleDeleteOpen}
        title="Are you sure you want to delete address?"
        handleDelete={handleDelete}
      />
    </CardContainer>
  );
};

export default AddressCard;
