import { SearchAsTypes } from "constants/searchAsTypes";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import {
  Breadcrumbs,
  CatalogHeader,
  Container,
  Flex,
  Pagination,
  Title,
} from "ui";
import LinearProgressBar from "ui/LinearProgress/LinearProgressBar";
import { PageHeader, PageSidebar } from "ui/page-header/page-header.styles";
import { CatalogViewModes } from "../../constants/catalogViewModes";
import { listErrorTypes } from "./Catalog.container";
import {
  Group,
  GroupItem,
  List,
  ListItem,
  Page,
  PaginationWrapper,
  Paper,
} from "./Catalog.styled";
import { CatalogImage, CatalogPagination } from "./Catalog.types";
import { CatalogCardContainer } from "./components/Card/Card.container";
import { ActionBar } from "./parts/ActionBar/ActionBar";
import NotFound from "./parts/NotFound/NotFound";
import SidebarPart from "./parts/sidebar/Sidebar";
import { fetchAndUpdateCategoriesByPath } from "shared/utils/Breadcrumb.utils";
import { Breadcrumb } from "shared/types/breadcrumb";

type CatalogProps = {
  q: string;
  id: string;
  items: any[];
  images: CatalogImage[];
  isLoading: boolean;
  drawSearch: string;
  cat: string;
  sstype: SearchAsTypes;
  inclusions: string;
  isNothingFound: boolean;
  onHandleSetPageSize: (size: number) => void;
  onHandleSetViewMode: (mode: CatalogViewModes) => void;
  pageSize: number;
  viewMode: CatalogViewModes;
  pagination: CatalogPagination[];
  onHandleClickPagination: (id: number) => void;
  onHandleDownloadSdf: () => Promise<void>;
  isLoadingSdf: boolean;
  onHandleAllToCart: () => Promise<void>;
  isLoadingAllToCart: boolean;
  listName: string;
  listErrorType: string;
  completionState: string;
  completionPercent: number;
};

const Catalog = ({
  q,
  id,
  items = [],
  isLoading,
  images,
  drawSearch,
  cat,
  sstype,
  inclusions,
  isNothingFound,
  onHandleSetPageSize,
  onHandleSetViewMode,
  pageSize,
  viewMode,
  pagination,
  onHandleClickPagination,
  onHandleDownloadSdf,
  isLoadingSdf,
  onHandleAllToCart,
  isLoadingAllToCart,
  listName,
  listErrorType,
  completionState,
  completionPercent,
}: CatalogProps) => {
  const [updatedBreadcrumbItems, setUpdatedBreadcrumbItems] = useState<
    Breadcrumb[]
  >([]);
  const { pathname } = useLocation();

  const pathTokens = useMemo(() => {
    const paths = [];

    if (pathname === "/" || !pathname.match(/categories/g)?.length) {
      return undefined;
    }

    let updatedPathname = pathname;

    if (pathname.includes("categories") && pathname.includes("catalog")) {
      updatedPathname = updatedPathname.replace("/catalog", "");
    }

    updatedPathname.split("/").reduce((prev, curr, index, arr) => {
      if (curr) {
        const urlTitle = curr.split("-").join(" ");
        const title = `${urlTitle.charAt(0).toUpperCase()}${urlTitle.slice(1)}`;
        const currPath = `${prev}/${curr}`;

        const isLastSegment = index === arr.length - 1;

        if (curr.toLowerCase() !== "categories") {
          paths.push({
            href: isLastSegment ? "" : currPath,
            title,
          });
        }

        return currPath;
      } else {
        return prev;
      }
    }, "");

    return paths;
  }, [pathname]);

  useEffect(() => {
    const updateBreadcrumbs = async () => {
      const updatedItems = await fetchAndUpdateCategoriesByPath(pathTokens);
      setUpdatedBreadcrumbItems(updatedItems);
    };

    if (pathname.includes("categories") && pathname.includes("catalog")) {
      updateBreadcrumbs();
    } else {
      setUpdatedBreadcrumbItems(pathTokens);
    }
  }, [pathname, pathTokens]);

  const catalogTitle = useMemo(() => {
    return updatedBreadcrumbItems?.length
      ? updatedBreadcrumbItems[updatedBreadcrumbItems.length - 1].title
      : id
      ? listName
      : "Search results";
  }, [id, listName, updatedBreadcrumbItems]);

  const backToSearchHref = drawSearch ? "/draw-search" : "/";

  return (
    <Page>
      {updatedBreadcrumbItems && (
        <div style={{ marginBottom: "-14px" }}>
          <Breadcrumbs items={updatedBreadcrumbItems} />
        </div>
      )}
      <Container>
        {listErrorType ? (
          <>
            <Paper style={{ marginTop: "50px", paddingTop: "30px" }}>
              <NotFound
                message={
                  listErrorType === listErrorTypes.DISABLED
                    ? "Product list disabled"
                    : "Product list not found"
                }
              />
            </Paper>
          </>
        ) : (
          <>
            <PageHeader className="catalog-header">
              <Flex type="center-between">
                <Title variant="h1">{catalogTitle}</Title>
                {!isLoading && (
                  <ActionBar
                    onHandleDownloadSdf={onHandleDownloadSdf}
                    isLoadingSdf={isLoadingSdf}
                    onHandleAllToCart={onHandleAllToCart}
                    isLoadingAllToCart={isLoadingAllToCart}
                    isNothingFound={isNothingFound}
                    backToSearchHref={backToSearchHref}
                  />
                )}
              </Flex>
            </PageHeader>
            <Group>
              <GroupItem sidebar>
                <PageSidebar>
                  <SidebarPart
                    q={q}
                    sstype={sstype}
                    cat={cat}
                    drawSearch={drawSearch}
                    inclusions={inclusions}
                  />
                </PageSidebar>
              </GroupItem>
              <GroupItem main>
                <Paper contentBlock hasMinHeight={isLoading || isNothingFound}>
                  {!isLoading && !isNothingFound && (
                    <CatalogHeader
                      pageSize={pageSize}
                      onHandleSetPageSize={onHandleSetPageSize}
                      onHandleSetViewMode={onHandleSetViewMode}
                      viewMode={viewMode}
                    />
                  )}
                  {isLoading && (
                    <LinearProgressBar
                      progressValue={completionPercent}
                      progressStatus={completionState}
                    />
                  )}
                  {items && Boolean(items?.length) && !isLoading && (
                    <List
                      hasFooter={
                        !pagination && viewMode === CatalogViewModes.LIST
                      }
                    >
                      {items.map((item, index) => {
                        const { product } = item;
                        return (
                          <ListItem
                            key={product?.code || index}
                            fullWidth={viewMode === CatalogViewModes.LIST}
                          >
                            <CatalogCardContainer
                              item={item}
                              images={images}
                              listMode={viewMode === CatalogViewModes.LIST}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                  {isNothingFound && <NotFound />}
                  {pagination && (
                    <PaginationWrapper>
                      <Pagination
                        pagination={pagination}
                        onHandleClickPagination={onHandleClickPagination}
                      />
                    </PaginationWrapper>
                  )}
                </Paper>
              </GroupItem>
            </Group>
          </>
        )}
      </Container>
    </Page>
  );
};

export default Catalog;
