import * as yup from "yup";
import { validation } from "../../../../../../constants";

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    clientId: validation.CARRIER_ID,
  });

  return validationSchema;
};
