import React, { useEffect, useState } from "react";
import { fetchOrders } from "./utils";
import ArchivedOrders from "./ArchivedOrders";
import { useQuery } from "../../../../hooks";
import { toast } from "react-toastify";
import OrdersUpdateModalContainer from "../../../../shared/ui/OnBoard/OrdersUpdateModal/OrdersUpdateModal.container";

const OrdersContainer = () => {
  const query = useQuery();
  const [ordersList, setOrdersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: parseInt(query.get("page")) || 0,
    pageSize: 10,
    pagesCount: 3,
  });

  const queryPage = query.get("page");

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      page: queryPage ? parseInt(queryPage) : 0,
    }));
  }, [queryPage]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const response = await fetchOrders({ curPage: pagination.page, pageSize: pagination.pageSize });

      if (response[0]) {
        const enrichedList = response[0].results.map((order) => {
          const detailsLink = `/profile/archived-orders/${order.number}?fromPage=${pagination.page}`;

          return {
            ...order,
            detailsLink,
          };
        });
        setOrdersList(enrichedList);

        if (pagination.page === response[0].pagination.page) {
          setPagination({
            page: response[0].pagination.page,
            pageSize: response[0].pagination.pageSize,
            pagesCount: response[0].pagination.pagesCount,
          });
        }

      } else {
        toast.error(response[1]);
      }

      setIsLoading(false);
    })();
  }, [pagination.page]);

  return (
    <>
      <OrdersUpdateModalContainer />
      <ArchivedOrders
        list={ordersList}
        isLoading={isLoading}
        pagination={pagination}
      />
    </>
  );
};

export default OrdersContainer;
