import React, { useMemo } from "react";
import { addZeroes, convertToDateAndTime } from "utils";
import { IconButton, Table, Tag } from "ui";
import { ArrowRightIcon } from "ui/icons";
import {
  getTagTypesBasedOnOrderStatus,
  mapOrderEventTypes,
} from "../../lib/Orders.utils";
import {
  OrderEventsPayStatusLabels,
  OrderEventsPayStatusTypes,
  OrderListItemWithDetailsLink,
  PaymentTypes,
  PaymentTypesLabels,
} from "shared/types/Orders.types";
import { OrderPayStatusChip, PaymentTypeWrapper } from "./OrderItem.styled";

type OrderItemProps = {
  orderListItem: OrderListItemWithDetailsLink;
};

const OrderItem = ({ orderListItem }: OrderItemProps) => {
  const orderPayStatusLabel = useMemo(() => {
    if (orderListItem?.paymentMethod === PaymentTypes.CARD) {
      const orderEventsPay = orderListItem?.events?.pay;
      const status = orderEventsPay?.length
        ? orderEventsPay?.[orderEventsPay?.length - 1]?.status
        : OrderEventsPayStatusTypes.ORDER_PAY_ABSENT;
      return status ? OrderEventsPayStatusLabels[status] : "";
    }

    return "";
  }, [orderListItem]);

  const orderPayStatusChipColor = useMemo(() => {
    const orderEventsPay = orderListItem?.events?.pay;
    const status = orderEventsPay?.[orderEventsPay?.length - 1]?.status;
    switch (status) {
      case OrderEventsPayStatusTypes.ORDER_PAY_DONE:
        return "success";
      case OrderEventsPayStatusTypes.ORDER_PAY_RETURNED:
        return "warning";
      default:
        return "default";
    }
  }, [orderListItem.events]);

  return (
    <Table.Row dataTestAttr="order-list-item">
      <Table.Cell
        style={{ width: "auto" }}
        title={`${orderListItem?.type === "QUOTE" ? "Quote" : "Order"} Number`}
      >
        {orderListItem?.storeId}
      </Table.Cell>
      <Table.Cell title="Order Date">
        {convertToDateAndTime(orderListItem?.time)}
      </Table.Cell>
      <Table.Cell verticalCenter>
        <Tag
          style={{ minWidth: "125px" }}
          type={getTagTypesBasedOnOrderStatus(orderListItem.events.order)}
        >
          {mapOrderEventTypes(orderListItem.events.order)}
        </Tag>
      </Table.Cell>
      <Table.Cell title="PO Number">
        {orderListItem?.purchaseOrderNumber || "-"}
      </Table.Cell>
      <Table.Cell title="Payment Type">
        <PaymentTypeWrapper>
          {PaymentTypesLabels?.[orderListItem?.paymentMethod]}
          {orderPayStatusLabel && (
            <OrderPayStatusChip
              label={orderPayStatusLabel}
              variant="outlined"
              color={orderPayStatusChipColor}
              size="small"
            />
          )}
        </PaymentTypeWrapper>
      </Table.Cell>
      <Table.Cell title="Amount">
        <b>
          {addZeroes(orderListItem?.pay.sum?.total)}{" "}
          {orderListItem?.pay.currency}
        </b>
      </Table.Cell>
      <Table.Cell minWidth verticalCenter>
        <IconButton
          icon={<ArrowRightIcon />}
          to={orderListItem?.detailsLink}
          variant="transparent"
          dataTestAttr="details-link"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default OrderItem;
