import React from "react";
import { Button } from "@mui/material";
import DialogCore from "../../../../../shared/ui/DialogCore/DialogCore";
import { DescriptionWrapper } from "../RestrictionDialog/RestrictionDialog.styled";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import {
  ButtonWrapper,
  ButtonsWrapper,
  InnerContentWrapper,
} from "../../../../../shared/ui/DialogCore/DialogCore.styled";

type PostalCodeIssueDialogProps = {
  onCancel: VoidFunction;
  onChangeAddress: VoidFunction;
} & Partial<DialogProps>;

const PostalCodeIssueDialog = ({
  onCancel,
  onChangeAddress,
  ...rest
}: PostalCodeIssueDialogProps) => {
  return (
    <DialogCore
      title="Postal code issue"
      PaperProps={{
        sx: {
          maxWidth: "790px",
          width: "100%",
        },
      }}
      {...rest}
    >
      <InnerContentWrapper>
        <DescriptionWrapper>
          The postal code you’ve entered does not match the selected state.
          Please review your shipping details.
        </DescriptionWrapper>

        <ButtonsWrapper>
          <ButtonWrapper>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button variant="contained" onClick={onChangeAddress}>
              Change address
            </Button>
          </ButtonWrapper>
        </ButtonsWrapper>
      </InnerContentWrapper>
    </DialogCore>
  );
};

export default PostalCodeIssueDialog;
