import { DataResponse } from "../customFetch";
import { CodeWithCatalog, ImageObj } from "../../shared/types/common";

import fetchImage from "./fetchImage";
import { ProductTypesV2 } from "../../constants/productTypes";

const fetchImages = (items) => {
  const requests = [];
  const codes = [];
  const newImages = [];

  if (items?.length) {
    items.forEach((el) => {
      requests.push(
        fetchImage({
          code: el.product.code,
          catalog: el?.product?.catalog
            ? el?.product?.catalog
            : ProductTypesV2[el?.product.catalog],
        })
      );
      codes.push({
        code: el.product.code,
        catalog: el?.product?.catalog || ProductTypesV2[el?.product.catalog],
      });
    });
  }

  return Promise.all(requests).then((data) => {
    data.forEach(([res, err], idx) => {
      newImages.push({
        url: res?.image || "/not-found-product.png",
        ...codes[idx],
      });
    });

    return newImages;
  });
};

const fetchImagesAsObject = async (codeWithCatalogItems: CodeWithCatalog[]) => {
  if (codeWithCatalogItems.length) {
    const requests: Promise<DataResponse<ImageObj>>[] =
      codeWithCatalogItems.map(({ code, catalog }) =>
        fetchImage({ code, catalog })
      );

    try {
      const images = await Promise.all(requests);

      const imagesObj = images.reduce((acc, [imageObj], currentIndex) => {
        const currentItemCode = codeWithCatalogItems[currentIndex]?.code;

        if (acc[currentItemCode]) {
          return acc;
        } else {
          return {
            ...acc,
            [currentItemCode]: {
              url: imageObj?.image || "/not-found-product.png",
            },
          };
        }
      }, {});

      return imagesObj;
    } catch (error) {
      console.error(error);
    }
  }
};

export { fetchImages, fetchImagesAsObject };
export default fetchImages;
