import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { darkBlue } from "ui/colors";

type BootstrapTooltipProps = TooltipProps & {
  variant?: "default" | "future-variant";
  tooltipStyleOverrides?: Record<string, string>;
};

type CustomizedTooltipsProps = BootstrapTooltipProps & {
  children: React.ReactNode;
  tooltipStyles?: Record<string, string>;
  fullWidth?: boolean;
  title: string;
};

const BootstrapTooltip = styled(
  ({ className, tooltipStyleOverrides, ...props }: BootstrapTooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(({ theme, variant, tooltipStyleOverrides }) => {
  const tooltipStyles: React.CSSProperties = {
    cursor: "pointer",
    padding: "8px",
    borderRadius: "8px",
    boxSizing: "border-box",
    fontSize: "12px",
    lineHeight: "16px",
    top: "-5px",
    fontWeight: "400",
    maxWidth: "280px",
    textAlign: "center",
    ...(tooltipStyleOverrides ? { ...tooltipStyleOverrides } : {}),
  };
  const arrowBeforeStyles: React.CSSProperties = {
    borderTopLeftRadius: "2px",
  };

  if (variant !== "future-variant") {
    tooltipStyles.backgroundColor = darkBlue;
    arrowBeforeStyles.color = darkBlue;
  }

  return {
    [`& .${tooltipClasses.arrow}::before`]: arrowBeforeStyles,
    [`& .${tooltipClasses.tooltip}`]: tooltipStyles,
  };
});

const CustomizedTooltip = ({
  children,
  fullWidth,
  variant,
  title,
  tooltipStyleOverrides,
  ...rest
}: CustomizedTooltipsProps) => {
  return (
    <BootstrapTooltip
      title={title}
      variant={variant}
      tooltipStyleOverrides={tooltipStyleOverrides}
      {...rest}
    >
      <div
        style={{
          display: fullWidth ? "block" : "inline-flex",
          margin: "0 4px",
          cursor: "pointer",
        }}
      >
        {children}
      </div>
    </BootstrapTooltip>
  );
};

export default CustomizedTooltip;
