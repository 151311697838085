import styled from "styled-components";
import {
  accentColor,
  blue,
  darkBlue,
  darkWarningColor,
} from "../../../../../../../ui/colors";
import { Box, Chip, Typography } from "@mui/material";

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-left: 12px;
  & > img {
    width: 120px;
    height: auto;
  }
`;

export const OrderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 28px;
`;

export const OrderItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OrderItemCode = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  font-weight: normal;
  color: ${blue};
`;

export const OrderItemPrice = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: bold;
  margin-left: auto;
  svg {
    path {
      fill: ${darkBlue};
    }

    &:hover {
      path {
        fill: ${accentColor};
      }
    }
  }
`;

export const StatusesWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TrackingNumberTypo = styled(Typography)`
  color: ${blue};
  font-size: 14px;
`;

export const ClarifyOrderTypo = styled(Typography)`
  color: ${darkWarningColor};
  font-size: 14px;
`;

export const TrackingNumbersWrapper = styled(Typography)`
  display: inline;
  font-weight: 500;
`;

export const ItemStatusChip = styled(Chip)`
  font-size: 12px;
  font-weight: 500;
  height: 22px !important;
  display: inline-flex;
  max-width: max-content;
  & span {
    padding: 0 12px;
  }
`;
