import { isRegisteredUser as isRegisteredUserUtil } from "../../utils";
import { addressTypes } from "../../constants";

const isRegisteredUser = (state) => {
  return isRegisteredUserUtil(state.user.user?.roles);
};

const selectUser = (state) => {
  return state.user.user;
};

const selectUserReady = (state) => {
  return state.user.userReady;
};

const selectProfile = (state) => {
  return state.user.profile;
};

const selectIsAuth = (state) => {
  return state.user.isAuth;
};

const selectRedirectTo = (state) => {
  return state.user.redirectTo;
};

const selectProfileError = (state) => {
  return state.user.profileError;
};

const selectRefreshToken = (state) => {
  return state.user.refreshToken;
};

const selectBillingAddress = (state) => {
  const result = state.user.addresses?.find((item) => {
    return item.addressType === addressTypes.BILLING;
  });

  return result;
};

const selectShippingAddresses = (state) => {
  const result = state.user.addresses?.filter((item) => {
    return item.addressType === addressTypes.SHIPPING;
  });

  return result;
};

const selectProfileLoading = (state) => {
  return state.user.profileLoading;
};

const selectCurrentUser = (state) => {
  return state.user.current;
};

// V2

const selectContactPersons = (state) => {
  return state.user.persons;
};

const selectContactPersonsLoading = (state) => {
  return state.user.personsLoading;
};

const selectContactPersonsError = (state) => {
  return state.user.personsError;
};

const selectContactPersonsWithUserProfile = (state) => {
  return [
    ...(state.user.profile ? [{ ...state.user.profile }] : []),
    ...(state.user?.persons ? state.user.persons : []),
  ];
};

const selectAddresses = (state) => {
  return state.user.addresses;
};

const selectAddressesLoading = (state) => {
  return state.user.addressesLoading;
};

const selectAddressesError = (state) => {
  return state.user.addressesError;
};

const selectUserSearchOptions = (state) => {
  return state.user.searchOptions;
};

export default {
  isRegisteredUser,
  selectUser,
  selectProfile,
  selectUserReady,
  selectProfileLoading,
  selectBillingAddress,
  selectShippingAddresses,
  selectRefreshToken,
  selectProfileError,
  selectIsAuth,
  selectRedirectTo,
  selectCurrentUser,

  // V2
  selectContactPersons,
  selectContactPersonsLoading,
  selectContactPersonsError,
  selectContactPersonsWithUserProfile,
  selectAddresses,
  selectAddressesLoading,
  selectAddressesError,
  selectUserSearchOptions,
};
