export type DeliveryOptions = {
  available: AvailabilityStatus;
  leadTime: string;
  stockLocations: StockLocationOption[];
};

export enum AvailabilityStatus {
  IN_STOCK = "IN_STOCK",
  SYNTHESIS = "SYNTHESIS",
}

export enum StockLocationOption {
  EU = "EU",
  UA = "UA",
  US = "US",
}
