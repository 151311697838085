import { call, put, select } from "redux-saga/effects";
import { errors } from "../../constants";
import { errorActions } from "../error";
import { checkoutActions } from "./index";
import {
  fetchAvailablePaymentMethods,
  fetchCalculations,
  fetchConditionsByAddressShipping,
  fetchCosts,
} from "./api";
import { currencyActions } from "../currency";

export function* requestAdditionalCosts(payloadParam) {
  const payload = payloadParam.payload;
  try {
    yield put(errorActions.clearErrors());

    const state = yield select();
    const data = state.checkout.data;
    const currency = state.currency.current;

    yield put(checkoutActions.setAdditionalCosts(null));
    yield put(checkoutActions.setAdditionalCostsLoading(true));
    const res = yield call(() => fetchCosts(data, currency));

    if (res[1]) {
      if (res[2] === 400 && res[1]?.includes("zip")) {
        yield payload?.onError({
          type: errors.SHIPPING_ZIP_NOT_VALID_ERROR,
          data: res[1],
        });
      } else {
        yield payload?.onError({
          type: errors.COMMON_ERROR,
          data: res[1],
        });
      }
    } else {
      yield put(errorActions.clearErrors());
      yield put(checkoutActions.setAdditionalCostsError(""));
      yield put(checkoutActions.setAdditionalCosts(res[0]));
      yield payload?.onSuccess();
    }
  } catch (e) {
    yield put(
      checkoutActions.setAdditionalCostsError(
        "Error while loading additional costs data"
      )
    );
  } finally {
    yield put(checkoutActions.setAdditionalCostsLoading(false));
  }
}

export function* requestPaymentMethods() {
  try {
    const state = yield select();
    const currency = state.currency.current;

    yield put(checkoutActions.setPaymentMethodsLoading(true));
    const res = yield call(() => fetchAvailablePaymentMethods(currency));
    yield put(checkoutActions.setPaymentMethods(res[0]));
  } catch (e) {
    yield put(
      checkoutActions.setPaymentMethodsError(
        "Error while loading available payment methods"
      )
    );
  } finally {
    yield put(checkoutActions.setPaymentMethodsLoading(false));
  }
}

// V2
export function* requestCalculations({ payload }) {
  try {
    const state = yield select();
    const currency = state.currency.current;
    const shippingAddressUuid =
      state.checkout.requestData.addresses.shippingUuid;
    const billingAddressUuid = state.checkout.requestData.addresses.billingUuid;
    const orderType = state.checkout.requestData.type;

    yield put(checkoutActions.setRequestData({ data: payload }));

    const response = yield fetchCalculations({
      preferredCurrency: currency?.abbr,
      ...payload,
      shippingServiceName: payload.shippingService.name.toUpperCase(),
      shippingAddressUuid: shippingAddressUuid,
      billingAddressUuid: billingAddressUuid,
      type: orderType,
    });

    yield put(checkoutActions.setCalculations(response));
    yield put(checkoutActions.closeAccordion("step5"));
    yield put(checkoutActions.openAccordion("step6"));
    yield put(currencyActions.setDisableSelect(true));
  } catch (e) {
    console.log(e);
  } finally {
    // @TODO Think how we can operate with finally
  }
}

export function* requestConditionsByAddressShipping() {
  try {
    const state = yield select();
    const shippingAddressUuid =
      state.checkout.requestData?.addresses?.shippingUuid;
    if (shippingAddressUuid) {
      const response = yield fetchConditionsByAddressShipping(
        shippingAddressUuid
      );
      yield put(checkoutActions.setConditionsByShippingAddress(response));

      // @TODO Rethink logic, we are operating here steps, do we have to do it here or inside the component
      if (response?.checkout?.allowed) {
        yield put(checkoutActions.closeAccordion("step4"));
        yield put(checkoutActions.openAccordion("step5"));
      } else {
        yield put(checkoutActions.closeAccordion("step5", { disabled: true }));
        yield put(checkoutActions.closeAccordion("step6", { disabled: true }));
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    // @TODO Think how we can operate with finally
  }
}

export function* requestPromoCodeCalculations({
  payload: { promoCode, onSuccess },
}) {
  try {
    const state = yield select();
    const currency = state.currency.current;
    const shippingAddressUuid =
      state.checkout.requestData.addresses.shippingUuid;
    const billingAddressUuid = state.checkout.requestData.addresses.billingUuid;
    const shippingServiceName =
      state.checkout.requestData.shippingService.name.toUpperCase();
    const overnightDelivery = state.checkout.requestData.overnightDelivery;
    const partialShipping = state.checkout.requestData.partialShipping;
    const orderType = state.checkout.requestData.type;

    const response = yield fetchCalculations({
      preferredCurrency: currency?.abbr,
      shippingServiceName,
      shippingAddressUuid,
      billingAddressUuid,
      overnightDelivery,
      partialShipping,
      type: orderType,
      ...(promoCode?.promoCode && promoCode),
    });

    yield put(
      checkoutActions.setRequestData({
        data: promoCode,
        ...(!response?.pay.promoCode?.applied && {
          removeFields: ["promoCode"],
        }),
      })
    );

    if (response?.pay.promoCode?.applied) {
      yield onSuccess();
    }

    yield put(checkoutActions.setCalculations(response));
  } catch (e) {
    console.log(e);
  } finally {
    // @TODO Think how we can operate with finally
  }
}
