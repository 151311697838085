// @ts-check

import React from "react";
import { CartImage } from "./cart-image";
import {
  Wrapper,
  Title,
  Text,
  ButtonWrapper,
  LinkStyled,
} from "./cart-message.styles";

/**
 * @type { React.FC<{
 *   variant: "success" | "warning" | "empty" | "paid" | "unpaid"; children: import("react").ReactNode
 * }> & any }
 */
export const CartMessage = ({ variant, children }) => {
  return (
    <Wrapper>
      <CartImage variant={variant} />
      {children}
    </Wrapper>
  );
};

CartMessage.Title = Title;
CartMessage.Description = Text;
CartMessage.ActionWrapper = ButtonWrapper;
CartMessage.Link = LinkStyled;
