const selectSuperCart = (state) => {
  return state.superCart;
};

const selectCartItemsWithImages = (state) => state.superCart.itemsWithImages;
const selectCartSummary = (state) => state.superCart.summary;

const selectCartItemsInStock = (state) => state.superCart.itemsWithImages?.filter(
  (cartItem) => {
    const foundPrice = cartItem?.prices?.all.find((price) => price?.weight?.amount === cartItem?.addedToBucket?.weight?.amount);
    return foundPrice?.delivery.available === 'IN_STOCK'
  }
);

const selectCartItemsSynthesis = (state) => state.superCart.itemsWithImages?.filter(
  (cartItem) => {
    const foundPrice = cartItem?.prices?.all.find((price) => price?.weight?.amount === cartItem?.addedToBucket?.weight?.amount);
    return foundPrice?.delivery.available === 'SYNTHESIS'
  }
);


const superCartSelectors = {
  selectSuperCart,
  selectCartItemsWithImages,
  selectCartItemsInStock,
  selectCartItemsSynthesis,
  selectCartSummary,
};

export default superCartSelectors;
