import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkoutSelectors } from "redux/checkout";
import RushDeliveryModalTable from "./RushDeliveryModal.table";
import { OvernightDeliveryMappedData } from "./RushDeliveryModal.types";
import { TablesWrapper } from "./RushDeliveryModal.styled";
import superCartSelectors from "../../../../../../../features/super-cart/redux/selectors";
import { getOvernightDeliveryData } from "./RushDeliveryModal.utils";
import DialogCore from "../../../../../../../shared/ui/DialogCore/DialogCore";

type RushDeliveryModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  // @TODO Specify type
  overnightDelivery: any;
};

// @TODO Refactoring
const RushDeliveryModal = ({ open, setOpen }: RushDeliveryModalProps) => {
  const cartItemsWithImages = useSelector(
    superCartSelectors.selectCartItemsWithImages
  );
  const overnightDelivery = useSelector(
    checkoutSelectors.selectOvernightDelivery
  );

  const [overnightDeliveryData, setOvernightDeliveryData] =
    useState<OvernightDeliveryMappedData>();

  useEffect(() => {
    if (overnightDelivery && cartItemsWithImages) {
      setOvernightDeliveryData(
        getOvernightDeliveryData(overnightDelivery, cartItemsWithImages)
      );
    }
  }, [cartItemsWithImages, overnightDelivery]);

  return (
    <DialogCore
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          maxWidth: "1286px",
          width: "100%",
        },
      }}
    >
      <TablesWrapper>
        {Boolean(overnightDeliveryData?.allowed?.length) && (
          <RushDeliveryModalTable
            overnightDeliveryList={overnightDeliveryData?.allowed}
            title="Available for Rush Delivery"
          />
        )}

        {Boolean(overnightDeliveryData?.notAllowed?.length) && (
          <RushDeliveryModalTable
            overnightDeliveryList={overnightDeliveryData?.notAllowed}
            title="Regular Delivery only"
          />
        )}
      </TablesWrapper>
    </DialogCore>
  );
};

export default RushDeliveryModal;
