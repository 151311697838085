import React from "react";
import { ContactPersonEntity } from "shared/types/user";
import DeleteDialog from "shared/ui/DeleteDialog/DeleteDialog";
import { DeleteIcon, PencilIcon } from "ui/icons";
import ContactPersonDialog from "../ContactPersonDialog/ContactPersonDialog";
import {
  CardContainer,
  FullWidthBox,
  IconColumn,
  Label,
  Row,
  StyledButton,
  Value,
} from "./ContactPersonCard.styled";

type contactPersonsConfig = {
  title: string;
  value: string;
  maxWidth: string;
};

type ContactPersonCardProps = {
  contactPersonData: ContactPersonEntity;
  contactPersonsConfig: contactPersonsConfig[];
  handleEdit: (updatedContactPerson: ContactPersonEntity) => void;
  handleDelete: () => void;
  isOpenDeleteContactPerson: boolean;
  handleToggleDeleteOpen: () => void;
  isOpenEditContactPerson: boolean;
  handleToggleEditOpen: () => void;
};

const ContactPersonCard = ({
  contactPersonsConfig,
  contactPersonData,
  handleEdit,
  handleDelete,
  isOpenDeleteContactPerson,
  handleToggleDeleteOpen,
  isOpenEditContactPerson,
  handleToggleEditOpen,
}: ContactPersonCardProps) => {
  return (
    <CardContainer>
      <Row>
        {contactPersonsConfig?.map((contactPerson, index) => {
          return (
            <FullWidthBox key={index} sx={{ maxWidth: contactPerson.maxWidth }}>
              <Label>{contactPerson.title}</Label>
              <Value>{contactPerson.value}</Value>
            </FullWidthBox>
          );
        })}
        <IconColumn>
          <StyledButton size="small" onClick={handleToggleEditOpen}>
            <PencilIcon />
          </StyledButton>
          <StyledButton size="small" onClick={handleToggleDeleteOpen}>
            <DeleteIcon />
          </StyledButton>
        </IconColumn>
      </Row>

      <ContactPersonDialog
        open={isOpenEditContactPerson}
        setOpen={handleToggleEditOpen}
        title="Edit contact person"
        formValues={contactPersonData}
        handleSubmit={(contactPersonData) => handleEdit(contactPersonData)}
      />
      <DeleteDialog
        open={isOpenDeleteContactPerson}
        setOpen={handleToggleDeleteOpen}
        title="Are you sure you want to delete contact person?"
        handleDelete={handleDelete}
      />
    </CardContainer>
  );
};
export default ContactPersonCard;
