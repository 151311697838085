import React, {
  Context,
  createContext,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { catalogSelectors } from "redux/catalog";
import { DataResponse, RequestMethods } from "utils/customFetch";
import { v4 as uuidv4 } from "uuid";
import common from "../constants/common";
import { customFetch, isDev } from "../utils";

type DelayedResponse = {
  created: string;
  delayedResultUuid: string;
  type: string;
  success: boolean;
};

type SearchState = {
  completionState: string;
  completionPercent: number;
};

const INITIAL_SEARCH_STATE = {
  completionState: "FIND_COMPOUNDS_STARTED",
  completionPercent: 25,
};

const EVENT_SOURCE_LOCAL: string =
  "http://localhost:8090/api/v2/client-applications/async-events/sse";
const EVENT_SOURCE: string = `${window.origin}/api/v2/client-applications/async-events/sse`;
const CLIENT_APPLICATION_ID = uuidv4();
const RECONNECT_ATTEMPTS = 5;

const QueueObserver = {
  _queue: [] as DelayedResponse[],

  _listeners: {} as { [id: string]: () => void },

  push(parsedData: DelayedResponse) {
    this._queue.push(parsedData);
    this._notify(parsedData?.delayedResultUuid);
  },

  _notify(id: string) {
    const delayedResponse = this._queue.find(
      ({ delayedResultUuid }) => delayedResultUuid === id
    );
    this._listeners?.[id]?.call(null, id, delayedResponse);
  },

  _on(id: string, fn: (id: string, data: unknown) => void) {
    this._listeners[id] = fn;
  },

  async getCompletedResultById(id: string) {
    const delayedResult = this._queue.find(
      ({ delayedResultUuid }) => delayedResultUuid === id
    );

    if (delayedResult) {
      return await delayedResult;
    } else {
      return new Promise((resolve) => {
        this._on(id, function (delayedResultUuid: string, data: unknown) {
          if (id === delayedResultUuid) {
            resolve(data);
          }
        });
      });
    }
  },
};

export const SSEContext: Context<{
  clientApplicationId: string;
  searchState: SearchState;
  restartSSEConnection: () => void;
}> = createContext({
  clientApplicationId: CLIENT_APPLICATION_ID,
  searchState: INITIAL_SEARCH_STATE,
  restartSSEConnection: () => {},
});

interface SSEContextProvideProps {
  children: ReactElement;
}

export const customFetchDelayedMiddleware = async (fetchConfig: {
  method: RequestMethods;
  url: string;
  data?: Record<string, string | number | boolean> | FormData | any;
  config?: Record<string, any>;
}) => {
  const res: DataResponse<{ delayedResultUuid?: string }> = await customFetch(
    {
      config: {
        headers: {
          "client-application-uuid": CLIENT_APPLICATION_ID,
        },
      },
      ...fetchConfig,
    },
    { baseURL: common.API_V2_URL }
  );

  if (res[0]?.delayedResultUuid) {
    const { delayedResultUuid } = await QueueObserver.getCompletedResultById(
      res[0]?.delayedResultUuid
    );

    const res2 = await customFetch(
      {
        method: RequestMethods.GET,
        url: `delayed-results/by/uuid/${delayedResultUuid}`,
        config: {
          headers: {
            "client-application-uuid": CLIENT_APPLICATION_ID,
          },
          ...fetchConfig,
        },
      },
      { baseURL: common.API_V2_URL }
    );
    return Promise.resolve(res2);
  } else {
    return Promise.resolve(res);
  }
};

export const SSEProvider = ({ children }: SSEContextProvideProps) => {
  const isLoading = useSelector(catalogSelectors.selectLoadingItems);
  const [searchState, setSearchState] =
    useState<SearchState>(INITIAL_SEARCH_STATE);

  const eventSourceRef = useRef<EventSource>();
  const reconnectAttempt = useRef(1);

  const closeEventSource = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = undefined;
    }
  };

  const setUpEventSource = () => {
    if (window.EventSourcePolyfill) {
      if (!eventSourceRef.current) {
        const EventSource = window.EventSourcePolyfill;
        eventSourceRef.current = new EventSource(
          isDev() ? EVENT_SOURCE_LOCAL : EVENT_SOURCE,
          {
            headers: {
              "client-application-uuid": CLIENT_APPLICATION_ID,
            },
          }
        );

        eventSourceRef.current.onmessage = (event) => {
          const parsedData = JSON.parse(event?.data);

          if (parsedData?.type === "HEARTBEAT") {
            reconnectAttempt.current = 1;
            return;
          }

          switch (parsedData?.type) {
            case "DELAYED_RESULT_COMPLETED":
              if (parsedData?.delayedResultUuid) {
                QueueObserver.push(parsedData);
              }
              break;

            case "SEARCH_PHASE_NOTIFICATION":
              setSearchState((prevState) => {
                const { completionPercent, completionState } = parsedData;
                return prevState.completionPercent === completionPercent &&
                  prevState.completionState === completionState
                  ? prevState
                  : {
                      completionPercent,
                      completionState,
                    };
              });
              break;

            default:
              break;
          }
        };

        eventSourceRef.current.onerror = (ev: Event) => {
          if (reconnectAttempt.current >= RECONNECT_ATTEMPTS) {
            if (eventSourceRef.current.readyState !== EventSource.CLOSED) {
              eventSourceRef.current.close();
            }
            toast.error("Maximum SSE reconnection attempts reached.");
          } else {
            reconnectAttempt.current += 1;
            setTimeout(
              () => setUpEventSource(),
              1000 * reconnectAttempt.current
            );
          }
        };
      }
    }
  };

  const restartSSEConnection = () => {
    if (
      eventSourceRef.current &&
      eventSourceRef.current.readyState === EventSource.OPEN
    ) {
      return;
    }

    closeEventSource();
    setUpEventSource();
  };

  useEffect(() => {
    if (!isLoading) {
      setSearchState(INITIAL_SEARCH_STATE);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    setUpEventSource();
    // eslint-disable-next-line
  }, []);

  return (
    <SSEContext.Provider
      value={{
        clientApplicationId: CLIENT_APPLICATION_ID,
        searchState,
        restartSSEConnection,
      }}
    >
      {children}
    </SSEContext.Provider>
  );
};
