import React from "react";
import { EmptyMessage, Table } from "../../../../ui";
import { LinksWrapper, PaginationWrapper } from "./ArchivedOrders.styles";
import { FullWidthLoader } from "../../../../parts";
import ListItem from "./parts/ListItem";
import { Link } from "react-router-dom";
import { rootPages } from "../../../../constants";
import PaginationBE from "../../../../ui/PaginationBE/PaginationBE";

const Orders = ({ list, isLoading, pagination }) => {
  return (
    <>
      {isLoading ? (
        <FullWidthLoader />
      ) : (
        <>
          {!list.length ? (
            <EmptyMessage variant="solidWhite">
              You haven’t made any order yet.
            </EmptyMessage>
          ) : (
            <Table variant="paper">
              {list.map((item) => (
                <ListItem key={item.number} item={item} />
              ))}
            </Table>
          )}

          {pagination && (
            <PaginationWrapper>
              <PaginationBE pagination={pagination} />
            </PaginationWrapper>
          )}

          <LinksWrapper>
            <Link to={`/${rootPages.PROFILE}/orders`}>
              Back to Orders
            </Link>
          </LinksWrapper>
        </>
      )}
    </>
  );
};

export default Orders;
