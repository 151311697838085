import React, { useState } from "react";
import { Button } from "@mui/material";
import DialogCore from "../../../../../../shared/ui/DialogCore/DialogCore";
import CreatePersonForm from "../../../../../../shared/ui/CreatePerson/CreatePerson.form";
import { userActions } from "../../../../../../redux/user";
import { useDispatch } from "react-redux";
import { ContactPersonPayload } from "../../../../../../shared/types/user";
import { useAmplitudeState } from "../../../../../../context/AmplitudeContext";
import { AmplitudeEvents } from "../../../../../../context/AmplitudeEvents";

type CreatePersonProps = {
  type: "Shipping" | "Billing";
};

const CreatePerson = ({ type }: CreatePersonProps) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const [isCreatePersonModalOpen, setIsCreatePersonModalOpen] = useState(false);

  const handleToggleModalOpen = () => {
    setIsCreatePersonModalOpen((prevState) => !prevState);
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_CONTACT_ADD, {
      person_type: type,
    });
  };

  const handleAddNewPerson = (formValues: ContactPersonPayload) => {
    dispatch(
      userActions.requestAddPerson({
        newPersonData: formValues,
        onSuccess: handleToggleModalOpen,
      })
    );
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleToggleModalOpen}
        fullWidth
        sx={{ marginTop: "16px" }}
      >
        Add new person
      </Button>

      <DialogCore
        title="Add new person"
        open={isCreatePersonModalOpen}
        onClose={handleToggleModalOpen}
        PaperProps={{
          sx: { maxWidth: "700px" },
        }}
      >
        <CreatePersonForm onSubmit={handleAddNewPerson} />
      </DialogCore>
    </>
  );
};

export default CreatePerson;
