import React from "react";
import DialogCore from "shared/ui/DialogCore/DialogCore";
import { ButtonWrapper } from "./DialogConvertToOrder.styled";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { InputWrapper } from "../../../../../CheckoutV2/ui/Content/CheckOrder/AdditionalInfo/AdditionalInfo.styled";
import { InputLabelTypography } from "../../../../../../shared/ui/CreatePerson/CreatePerson.styled";

type DeleteDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConvertQuoteToOrder: (formValues: { purchaseOrderNumber: string }) => void;
};

const DialogConvertToOrder = ({
  open,
  setOpen,
  onConvertQuoteToOrder,
}: DeleteDialogProps) => {
  const formik = useFormik({
    initialValues: {
      purchaseOrderNumber: "",
    },
    onSubmit: (formValues) => {
      onConvertQuoteToOrder(formValues);
    },
  });

  return (
    <DialogCore
      open={open}
      onClose={() => setOpen(false)}
      title="Covnert to Order?"
      maxWidth="sm"
    >
      <InputWrapper>
        <InputLabelTypography variant="body1">
          PO number (Optional)
        </InputLabelTypography>
        <TextField
          fullWidth
          id="purchaseOrderNumber"
          name="purchaseOrderNumber"
          placeholder="Enter PO Number"
          value={formik.values.purchaseOrderNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.purchaseOrderNumber &&
            Boolean(formik.errors.purchaseOrderNumber)
          }
          helperText={
            formik.touched.purchaseOrderNumber &&
            formik.errors.purchaseOrderNumber
          }
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button
          sx={{ width: "100%" }}
          variant="contained"
          dataTestAttr="delete-button"
          onClick={formik.handleSubmit}
        >
          Convert to Order
        </Button>
      </ButtonWrapper>
    </DialogCore>
  );
};

export default DialogConvertToOrder;
