export const REQUEST_USER = "USER/REQUEST_USER";
export const REQUEST_PROFILE = "USER/REQUEST_PROFILE";
export const REQUEST_ADDRESSES = "USER/REQUEST_ADDRESSES";
export const SET_USER = "USER/SET_USER";
export const SET_USER_READY = "USER/SET_USER_READY";
export const LOGOUT = "USER/LOGOUT";
export const SET_LOGOUT = "USER/SET_LOGOUT";
export const SET_USER_LOADING = "USER/SET_USER_LOADING";
export const SET_PROFILE_LOADING = "USER/SET_PROFILE_LOADING";
export const SET_PROFILE_ERROR = "USER/SET_PROFILE_ERROR";
export const SET_PROFILE = "USER/SET_PROFILE";
export const SET_ADDRESSES = "USER/SET_ADDRESSES";
export const SET_REFRESH_TOKEN = "USER/SET_REFRESH_TOKEN";
export const SET_IS_AUTH = "USER/SET_IS_AUTH";
export const SET_REDIRECT_TO = "USER/SET_REDIRECT_TO";
export const SET_CURRENT_USER = "USER/SET_CURRENT_USER";

// V2
export const REQUEST_ADDRESSES_AND_PERSONS =
  "USER/REQUEST_ADDRESSES_AND_PERSONS";
export const SET_ADDRESSES_AND_PERSONS = "USER/SET_ADDRESSES_AND_PERSONS";
export const SET_ADDRESSES_AND_PERSONS_LOADING =
  "USER/SET_ADDRESSES_AND_PERSONS_LOADING";

export const REQUEST_PERSONS = "USER/REQUEST_PERSONS";
export const SET_PERSONS = "USER/SET_PERSONS";
export const REQUEST_ADD_PERSON = "USER/REQUEST_ADD_PERSON";
export const ADD_PERSON = "USER/ADD_PERSON";
export const REQUEST_DELETE_PERSON = "USER/REQUEST_DELETE_PERSON";
export const DELETE_PERSON = "USER/DELETE_PERSON";
export const REQUEST_EDIT_PERSON = "USER/REQUEST_EDIT_PERSON";
export const EDIT_PERSON = "USER/EDIT_PERSON";
export const SET_PERSONS_LOADING = "USER/SET_PERSONS_LOADING";
export const SET_PERSONS_ERROR = "USER/SET_PERSONS_ERROR";

export const REQUEST_ADD_ADDRESS = "USER/REQUEST_ADD_ADDRESS";
export const ADD_ADDRESS = "USER/ADD_ADDRESSES";
export const REQUEST_DELETE_ADDRESS = "USER/REQUEST_DELETE_ADDRESS";
export const DELETE_ADDRESS = "USER/DELETE_ADDRESSES";
export const REQUEST_EDIT_ADDRESS = "USER/REQUEST_EDIT_ADDRESS";
export const EDIT_ADDRESS = "USER/EDIT_ADDRESSES";
export const SET_ADDRESSES_LOADING = "USER/SET_ADDRESSES_LOADING";
export const SET_ADDRESSES_ERROR = "USER/SET_ADDRESSES_ERROR";
