import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import { blue, darkBlue, lightGray } from "../../../../../../../ui/colors";
import TableHead from "@mui/material/TableHead";

export const TablesWrapper = styled.div`
  & > *:first-child h2 {
    margin-bottom: 28px;
  }

  & > *:nth-child(3) h2 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;

export const TableRush = styled(Table)`
  border: 1px solid ${lightGray};
`;

export const TableCellHeaderRush = styled(TableCell)`
  font-size: 16px;
  padding: 12px 18px;
  line-height: 1;
  color: ${darkBlue};
  border: none;
`;

export const TableCellRush = styled(TableCell)`
  font-size: 16px;
  //  @TODO Bad solution, find a better one
  padding: 18px !important;
  border: none;
  color: ${blue};
`;

export const TableHeadRush = styled(TableHead)`
  background-color: ${lightGray};
`;
