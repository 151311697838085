import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoints, rootPages } from "constants";
import { checkoutActions, checkoutSelectors } from "redux/checkout";
import { useAmplitudeState } from "context/AmplitudeContext";
import superCartActions from "features/super-cart/redux/actions";
import { RequestMethods } from "../../../../utils/customFetch";
import { customFetchDelayedMiddleware } from "../../../../context/sseContext";
import superCartSelectors from "../../../../features/super-cart/redux/selectors";
import { currencySelectors } from "../../../../redux/currency";
import { CHECKOUT_MODES } from "../../model/Checkout.contants";
import { CartItemWithImage } from "../../../../shared/types/cart";
import { AmplitudeEvents } from "../../../../context/AmplitudeEvents";

type UseHandleConfirmCheckoutProps = {
  setCurrentMode: (currentMode: CHECKOUT_MODES) => void;
};

const useHandleConfirmCheckout = ({
  setCurrentMode,
}: UseHandleConfirmCheckoutProps) => {
  const history = useHistory();
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();

  const currency = useSelector(currencySelectors.selectCurrent);
  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);
  const checkoutCalculations = useSelector(
    checkoutSelectors.selectCalculations
  );
  const cartItemsWithImages = useSelector(
    superCartSelectors.selectCartItemsWithImages
  );

  const isPaymentTypeCard = checkoutRequestData?.paymentMethod === "CARD";
  const isQuoteOrder = checkoutRequestData?.type === "QUOTE";

  const handleConfirmOrder = async () => {
    setCurrentMode(CHECKOUT_MODES.SENDING_ORDER);

    const response = await customFetchDelayedMiddleware({
      method: RequestMethods.POST,
      url: endpoints.ORDER_SUBMIT,
      data: {
        preferredCurrency: currency?.abbr,
        ...checkoutRequestData,
      },
    });

    if (response[1]) {
      setCurrentMode(CHECKOUT_MODES.ERROR);
    } else if (response[0]) {
      // REVENUE AND EVENTS
      cartItemsWithImages.forEach((cartItem: CartItemWithImage) => {
        const revenue = new amplitude.Revenue()
          .setProductId(cartItem.product.code)
          .setPrice(cartItem.addedToBucket.pay.pricePerOneCount)
          .setQuantity(cartItem.addedToBucket.count)
          .setRevenueType("order");

        amplitude.logRevenueV2(revenue);
      }); // END. REVENUE AND EVENTS

      amplitude.logEvent(
        isQuoteOrder
          ? AmplitudeEvents.Quote.QUOTE_SENT
          : AmplitudeEvents.Checkout.CHECKOUT_ORDER,
        {
          total: checkoutCalculations?.pay?.sum?.total,
          currency: currency.abbr,
          ...(checkoutRequestData?.promoCode && {
            promo: "Promocode",
            code: checkoutRequestData?.promoCode,
          }),
        }
      );

      if (isPaymentTypeCard) {
        history.push(`/${rootPages.CHECKOUT}/${response?.[0]?.uuid}`);
        setCurrentMode(CHECKOUT_MODES.THANK_PAY);
      } else if (isQuoteOrder) {
        setCurrentMode(CHECKOUT_MODES.THANK_QUOTE);
      } else {
        setCurrentMode(CHECKOUT_MODES.THANK);
      }

      dispatch(superCartActions.clear());
      dispatch(checkoutActions.setInitial());
    }
  };

  return { handleConfirmOrder };
};

export default useHandleConfirmCheckout;
