import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  RestrictedCartItem,
  RestrictionReasons,
} from "../RestrictionDialog.types";
import RestrictedItem from "./RestictedItem/RestrictedItem";
import Box from "@mui/material/Box";

type RestrictedItemsTableProps = {
  restrictedCartItems: RestrictedCartItem[];
};

const RestrictedItemsTable = ({
  restrictedCartItems,
}: RestrictedItemsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restrictedCartItems.map((restrictedCartItem, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <RestrictedItem {...restrictedCartItem} />
              </TableCell>
              <TableCell component="th" scope="row">
                {restrictedCartItem.restrictionReasons.map((reason) => (
                  <Box>{RestrictionReasons[reason]}</Box>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RestrictedItemsTable;
