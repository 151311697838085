import React from "react";
import DialogCore from "../../DialogCore/DialogCore";
import { Box, Button, Typography } from "@mui/material";
import { DialogCoreButtonsRight } from "../../DialogCore/DialogCore.styled";

const OrdersUpdateModal = ({ onClose, ...rest }) => (
  <DialogCore
    title="Important update of your orders tab"
    hideClose
    PaperProps={{
      sx: {
        maxWidth: "710px",
        width: "100%",
      },
    }}
    {...rest}
  >
    <Box>
      <Box sx={{ marginBottom: "28px" }}>
        <Typography variant="body1" sx={{ marginBottom: "18px" }}>
          We are doing a set of updates to improve the order processing on
          EnamineStore.com and because of this we will be moving all of your
          previously placed orders under “Archived orders” tab to accommodate
          the change.
        </Typography>
        <Typography variant="body1">
          But don't worry, your past orders will always be available and all of
          the upcoming ones will be shown under the “Orders” tab as it has
          always been.
        </Typography>
      </Box>

      <DialogCoreButtonsRight>
        <Button variant="contained" onClick={onClose}>
          Got it!
        </Button>
      </DialogCoreButtonsRight>
    </Box>
  </DialogCore>
);

export default OrdersUpdateModal;
