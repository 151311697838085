import { ProductTypesV2 } from "../../../constants/productTypes";

export const infoListConfig = {
  [ProductTypesV2.SCR]: {
    config: {
      catalog: { title: "Product class", order: 1 },
      molecularWeight: { title: "MW", order: 6 },
      purity: { title: "Purity", order: 11 },
      stocks: { title: "Stock Availability", order: 12 },
      leadTime: { title: "Lead time", order: 13 },
    },
    separators: [11],
    limit: null,
  },
  DEFAULT: {
    config: {
      catalog: { title: "Product class", order: 1 },
      CAS: { title: "CAS number", order: 4 },
      molecularWeight: { title: "MW", order: 6 },
      purity: { title: "Purity", order: 11 },
      stocks: { title: "Stock Availability", order: 12 },
      leadTime: { title: "Lead time", order: 13 },
    },
    separators: [11],
    limit: null,
  },
};

export const getInfoListConfigByCatalog = (catalog: ProductTypesV2) => {
  return infoListConfig[catalog] || infoListConfig["DEFAULT"];
};
