import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Input, Wrapper } from "./DebouncedInput.styled";
import { Label } from "../../../ui/select/select.styles";

interface DebouncedInputProps {
  callback?: (value: number) => void;
  count: number;
  hideButtons?: boolean;
  disabled?: boolean;
  min?: number;
  label?: string;
}

const DebouncedInput = ({
  callback = () => {},
  count,
  hideButtons,
  disabled,
  min,
  label,
}: DebouncedInputProps) => {
  const [inputValue, setInputValue] = useState(count);
  const [debouncedValue] = useDebounce(inputValue, 500);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setInputValue(value === "" ? 1 : parseInt(value));
    }
  };

  useEffect(() => {
    setInputValue(count);
  }, [count]);

  useEffect(() => {
    if (inputValue !== count) {
      callback(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Wrapper>
      {!hideButtons && (
        <button
          onClick={() => setInputValue((prev) => --prev)}
          disabled={inputValue <= 1}
        >
          -
        </button>
      )}

      {label && <Label>{label}</Label>}

      <Input
        type="number"
        min={min || 1}
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
      />

      {!hideButtons && (
        <button onClick={() => setInputValue((prev) => ++prev)}>+</button>
      )}
    </Wrapper>
  );
};

export default DebouncedInput;
