import { Link } from "ui";
import { convertToDateAndTime } from "utils";
import React, { useMemo } from "react";
import {
  DateTimeWrapper,
  OrderHeaderControlsWrapper,
  OrderHeaderInfoWrapper,
  OrderHeaderWrapper,
  OrderPayStatusChip,
  OrderTitleWrapper,
  TimeStatusWrapper,
} from "./OrderHeader.styled";
import { Button, Typography } from "@mui/material";
import {
  OrderEventsOrderStatusTypes,
  OrderEventsPayStatusLabels,
  OrderEventsPayStatusTypes,
  OrderEventsType,
} from "../../../../../../shared/types/Orders.types";
import { OrderPaymentMethods, RelatedOrder } from "../../lib/Order.types";

type OrderHeaderProps = {
  orderId: string;
  orderTime: string;
  orderType: string;
  orderEventsPay: OrderEventsType<OrderEventsPayStatusTypes>[];
  orderEventsOrder: OrderEventsType<OrderEventsOrderStatusTypes>[];
  isLoading: boolean;
  backToOrdersLink: string;
  orderPaymentMethod: OrderPaymentMethods;
  onCancelOrder: VoidFunction;
  onPayOrder: VoidFunction;
  onDownloadPdf: VoidFunction;
  onConvertToOrder: VoidFunction;
  handleGoRelatedOrderQuote: VoidFunction;
  orderRelated?: RelatedOrder;
};

const OrderHeader = ({
  backToOrdersLink,
  isLoading,
  orderId,
  orderTime,
  orderType,
  orderEventsPay,
  orderEventsOrder,
  orderPaymentMethod,
  onCancelOrder,
  onPayOrder,
  onDownloadPdf,
  onConvertToOrder,
  orderRelated,
  handleGoRelatedOrderQuote,
}: OrderHeaderProps) => {
  const orderPayStatusLabel = useMemo(() => {
    const status = orderEventsPay?.[orderEventsPay?.length - 1]?.status;
    return status
      ? OrderEventsPayStatusLabels[status]
      : orderPaymentMethod === "CARD"
      ? OrderEventsPayStatusLabels.ORDER_PAY_ABSENT
      : "";
  }, [orderEventsPay, orderPaymentMethod]);

  const orderPayStatusChipColor = useMemo(() => {
    const status = orderEventsPay?.[orderEventsPay?.length - 1]?.status;
    switch (status) {
      case OrderEventsPayStatusTypes.ORDER_PAY_DONE:
        return "success";
      case OrderEventsPayStatusTypes.ORDER_PAY_RETURNED:
        return "warning";
      default:
        return "default";
    }
  }, [orderEventsPay]);

  const isQuote = orderType === "QUOTE";

  const isCancelOrderAvailable = !(
    orderEventsOrder?.some(
      (orderEventPay) =>
        orderEventPay.status === OrderEventsOrderStatusTypes.ORDER_CANCELLED
    ) ||
    orderEventsPay?.some(
      (orderEventPay) =>
        orderEventPay.status === OrderEventsPayStatusTypes.ORDER_PAY_DONE ||
        orderEventPay.status === OrderEventsPayStatusTypes.ORDER_PAY_RETURNED
    ) ||
    orderPaymentMethod !== "CARD"
  );

  const isPayAvailable =
    orderPaymentMethod === "CARD" && isCancelOrderAvailable;

  const isConvertToOrderAvailable =
    !orderEventsOrder?.length ||
    orderEventsOrder?.some(
      (orderEventPay) =>
        !(orderEventPay.status === OrderEventsOrderStatusTypes.QUOTE_USED)
    );

  return (
    <OrderHeaderWrapper>
      <OrderHeaderInfoWrapper>
        <Link
          className="order-page__back-link"
          to={backToOrdersLink}
          disableDecoration
          data-test="back-to-orders-link"
        >
          Back to orders
        </Link>
        <OrderTitleWrapper>
          <Typography variant="h1">
            {isQuote ? "Quote" : "Order"} #
            <span data-test="order-id">{orderId}</span>
          </Typography>
        </OrderTitleWrapper>
        <TimeStatusWrapper>
          {!isLoading && (
            <DateTimeWrapper>{convertToDateAndTime(orderTime)}</DateTimeWrapper>
          )}
          {orderPayStatusLabel && (
            <OrderPayStatusChip
              label={orderPayStatusLabel}
              variant="outlined"
              color={orderPayStatusChipColor}
              size="small"
            />
          )}
        </TimeStatusWrapper>
      </OrderHeaderInfoWrapper>

      <OrderHeaderControlsWrapper>
        {!isQuote && isCancelOrderAvailable && (
          <Button variant="outlined" onClick={onCancelOrder} color="error">
            Cancel order
          </Button>
        )}
        <Button variant="outlined" onClick={onDownloadPdf}>
          Download PDF
        </Button>
        {orderRelated && (
          <Button variant="contained" onClick={handleGoRelatedOrderQuote}>
            Connected {orderRelated?.type?.toLowerCase()}
          </Button>
        )}
        {!isQuote && isPayAvailable && (
          <Button variant="contained" onClick={onPayOrder} color="success">
            Pay Now
          </Button>
        )}
        {isQuote && isConvertToOrderAvailable && (
          <Button variant="contained" onClick={onConvertToOrder}>
            Convert to Order
          </Button>
        )}
      </OrderHeaderControlsWrapper>
    </OrderHeaderWrapper>
  );
};

export default OrderHeader;
