import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { events, useAmplitudeState } from "context/AmplitudeContext";
import { FormProvider, useForm } from "react-hook-form";
import { makeParams } from "utils";
import rootPages from "constants/rootPages";
import { analyzeCompounds } from "redux/catalog/api";
import { SearchForm } from "./SearchForm";
import { getTypeSearchByWithEncodedPhrase } from "./SearchForm.utils";
import { SearchByTypes } from "./SearchForm.types";

export const SearchFormContainer = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: {
      search: "",
      includeAdditionalResults: true,
    },
  });
  const { amplitude } = useAmplitudeState();

  const watcherSearch = methods.watch("search");

  const showIncludeAdditionalResults = useMemo(() => {
    return watcherSearch?.[0] && watcherSearch?.split(" ").length === 1;
  }, [watcherSearch]);

  const submitHandler = useCallback(
    async (values) => {
      const upperCasePhrase = decodeURIComponent(values?.search).toUpperCase();

      setLoading(true);

      /**
       * For all databases except RealDB, the search is done in uppercase.
       * For RealDB, the search is done in lowercase.
       * Compound IDs for RealDB at this moment start with 's_' or 'm_'.
       */

      const adjustedSearchPhrase = /^[A-Z]_/.test(upperCasePhrase)
        ? upperCasePhrase.toLowerCase()
        : upperCasePhrase;
      const { analysedCompounds } = await analyzeCompounds(
        adjustedSearchPhrase
      );

      const {
        encodedTypeSearchByPhrase,
        selectedTypeSearchBy,
        foundEntitiesByTypeSearchBy,
      } = getTypeSearchByWithEncodedPhrase(analysedCompounds);

      setLoading(false);

      const isSingleResult = foundEntitiesByTypeSearchBy?.length === 1;

      const logEventData = {
        type: isSingleResult ? "exact_text" : "bulk_text",
        query: encodedTypeSearchByPhrase,
      };

      amplitude.logEvent(events.search, logEventData);

      if (values?.includeAdditionalResults) {
        amplitude.logEvent(events.include_salts);
      }

      if (selectedTypeSearchBy === SearchByTypes.codes) {
        if (isSingleResult) {
          const url = values?.includeAdditionalResults
            ? `/${rootPages.CATALOG}?q=${encodedTypeSearchByPhrase}&inclusions=true`
            : `/${rootPages.CATALOG}/${foundEntitiesByTypeSearchBy[0]}`;
          history.push(url);
        } else {
          history.push(`/${rootPages.CATALOG}?q=${encodedTypeSearchByPhrase}`);
        }
      } else {
        const queryParams = makeParams({
          q: encodedTypeSearchByPhrase,
          searchBy: selectedTypeSearchBy,
        });

        const addInclusions =
          isSingleResult &&
          values?.includeAdditionalResults &&
          selectedTypeSearchBy !== SearchByTypes.text;
        history.push(
          `/${rootPages.SEARCH}?${queryParams}${
            addInclusions ? "&inclusions=true" : ""
          }`
        );
      }
    },
    [amplitude, history]
  );

  const structureSearchHandler = useCallback(
    () => history.push(`/${rootPages.DRAW_SEARCH}`),
    [history]
  );

  return (
    <FormProvider>
      <form onSubmit={methods.handleSubmit(submitHandler)}>
        <SearchForm
          isLoading={loading}
          control={methods.control}
          onStructureSearch={structureSearchHandler}
          submitHandler={methods.handleSubmit(submitHandler)}
          showIncludeAdditionalResults={showIncludeAdditionalResults}
        />
      </form>
    </FormProvider>
  );
};
