import React, { ReactNode, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SearchInput } from "./SearchInput";
import {
  HintWrapper,
  IncludeAdditionalWrapper,
  InputWrapper,
  SearchHint,
} from "./SearchInputWithHint.styles";
import { Controller } from "react-hook-form";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";

type SearchInputWithHintProps = {
  children: ReactNode;
  control: any;
  onEnter: () => void;
  isLoading: boolean;
  showIncludeAdditionalResults: boolean;
};

export const SearchInputWithHint = ({
  children,
  control,
  onEnter,
  isLoading,
  showIncludeAdditionalResults,
}: SearchInputWithHintProps) => {
  const [isHintShown, showHint] = useState(false);

  return (
    <>
      <InputWrapper>
        <SearchInput
          isLoading={isLoading}
          control={control}
          onEnter={onEnter}
          onFocus={() => showHint(true)}
          onBlur={() => showHint(false)}
        />
        {children}
      </InputWrapper>
      {showIncludeAdditionalResults && (
        <IncludeAdditionalWrapper>
          <Controller
            control={control}
            name="includeAdditionalResults"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FormControlLabel
                checked={value}
                onChange={onChange}
                control={<Switch sx={{ marginRight: "8px" }} />}
                label="Include salts and tautomers in search results"
                sx={{ marginLeft: 0, marginTop: "18px", fontSize: "18px" }}
              />
            )}
          />
        </IncludeAdditionalWrapper>
      )}

      {isHintShown && (
        <HintWrapper>
          <TransitionGroup>
            <CSSTransition
              key="search_hint"
              timeout={200}
              classNames="search-hint"
              unmountOnExit
              mountOnEnter
            >
              <SearchHint>
                Your search will automatically include the results from our
                Stock, REAL Database and MADE Building Blocks catalogs.
              </SearchHint>
            </CSSTransition>
          </TransitionGroup>
        </HintWrapper>
      )}
    </>
  );
};
