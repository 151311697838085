import React from "react";
import { useSelector } from "react-redux";
import { currencySelectors } from "redux/currency";
// @TODO Check dependencies
import { addZeroes } from "utils";

type SummaryItemProps = {
  price?: string;
  currencyAbbr?: string;
};

const SummaryItem = ({ price, currencyAbbr }: SummaryItemProps) => {
  const currency = useSelector(currencySelectors.selectCurrent);

  return (
    <>
      {/* @TODO Rethink null / undefined check */}
      {typeof price === "number" ? (
        <span>
          {addZeroes(price)} {currencyAbbr ?? currency?.abbr}
        </span>
      ) : (
        "-"
      )}
    </>
  );
};

export default SummaryItem;
