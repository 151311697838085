import React from "react";
import {
  Group,
  Title,
  TextRow,
  Header,
  AdditionalWrapper,
} from "./CheckOrder.styled";
import { StepNumber } from "../StepperCheckout/StepperCheckout.styled";
// @TODO Check logic
import { LikeLinkButton } from "../../../../../components";
import { SectionWrapper } from "../../../Checkout.styled";
import AdditionalInfoContainer from "./AdditionalInfo/AdditionalInfo.container";

type CheckOrderProps = {
  contactPersonShippingInfo: string;
  contactPersonBillingInfo: string;
  billingAddressInfo: string;
  shippingAddressInfo: string;
  shippingOptionsInfo: string;
  paymentMethodInfo: string;
  handleEditStep: (stepKey: string) => void;
};

const CheckOrder = ({
  contactPersonShippingInfo,
  contactPersonBillingInfo,
  billingAddressInfo,
  shippingAddressInfo,
  shippingOptionsInfo,
  paymentMethodInfo,
  handleEditStep,
}: CheckOrderProps) => {
  return (
    <>
      <SectionWrapper>
        <Group>
          <Header>
            <StepNumber open={true}>1</StepNumber>
            <Title variant="h2">Contact Person for Shipping</Title>
            <LikeLinkButton onClick={() => handleEditStep("step1")}>
              Edit
            </LikeLinkButton>
          </Header>
          <TextRow>{contactPersonShippingInfo}</TextRow>
        </Group>

        <Group>
          <Header>
            <StepNumber open={true}>2</StepNumber>
            <Title variant="h2">Contact Person for Billing</Title>
            <LikeLinkButton onClick={() => handleEditStep("step2")}>
              Edit
            </LikeLinkButton>
          </Header>
          <TextRow>{contactPersonBillingInfo}</TextRow>
        </Group>

        <Group>
          <Header>
            <StepNumber open={true}>3</StepNumber>
            <Title variant="h2">Billing Address</Title>
            <LikeLinkButton onClick={() => handleEditStep("step3")}>
              Edit
            </LikeLinkButton>
          </Header>
          <TextRow>{billingAddressInfo}</TextRow>
        </Group>

        <Group>
          <Header>
            <StepNumber open={true}>4</StepNumber>
            <Title variant="h2">Shipping Address</Title>
            <LikeLinkButton onClick={() => handleEditStep("step4")}>
              Edit
            </LikeLinkButton>
          </Header>
          <TextRow>{shippingAddressInfo}</TextRow>
        </Group>

        <Group>
          <Header>
            <StepNumber open={true}>5</StepNumber>
            <Title variant="h2">Shipping Options</Title>
            <LikeLinkButton onClick={() => handleEditStep("step5")}>
              Edit
            </LikeLinkButton>
          </Header>
          <TextRow>{shippingOptionsInfo}</TextRow>
        </Group>

        <Group>
          <Header>
            <StepNumber open={true}>6</StepNumber>
            <Title variant="h2">Payment Type</Title>
            <LikeLinkButton onClick={() => handleEditStep("step6")}>
              Edit
            </LikeLinkButton>
          </Header>
          <TextRow>{paymentMethodInfo}</TextRow>
        </Group>
      </SectionWrapper>

      <AdditionalWrapper>
        <AdditionalInfoContainer />
      </AdditionalWrapper>
    </>
  );
};

export default CheckOrder;
