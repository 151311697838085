import React from "react";
import { BucketItemWithImage } from "shared/types/cartItem";
import { Currency } from "shared/types/pricing";
import CartItem from "./CartItem";

type CartItemContainerProps = {
  item: BucketItemWithImage;
  handleChangeWeight: (item: BucketItemWithImage) => Promise<void>;
  handleDelete: (uuid: string) => Promise<void>;
  currency: Currency;
  handleChangeCount: (item: BucketItemWithImage) => Promise<void>;
  cartInfoLoading: boolean;
};

const CartItemContainer = ({
  item,
  handleChangeWeight,
  handleChangeCount,
  handleDelete,
  currency,
  cartInfoLoading,
}: CartItemContainerProps) => {
  const handleItemCount = (value: number) => {
    handleChangeCount({
      ...item,
      addedToBucket: {
        ...item.addedToBucket,
        count: value,
      },
    });
  };

  return (
    <CartItem
      cartItem={item}
      handleItemWeight={handleChangeWeight}
      handleItemRemove={handleDelete}
      currency={currency}
      handleItemCount={handleItemCount}
      cartInfoLoading={cartInfoLoading}
    />
  );
};

export default CartItemContainer;
