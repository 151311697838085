import { ProductTypesV2 } from "../../../../../constants/productTypes";
import { fetchImage } from "../../../../../redux/util/api";

export async function fetchOrderItemImages(
  codesWithCatalogs: { catalog: ProductTypesV2; code: string }[]
) {
  const requests = [];
  const images = [];

  if (codesWithCatalogs?.length) {
    codesWithCatalogs.forEach((codeWithCatalog) => {
      requests.push(
        fetchImage({
          code: codeWithCatalog.code,
          catalog: codeWithCatalog.catalog,
        })
      );
    });
  }

  return Promise.all(requests).then((imagesData) => {
    imagesData.forEach(([response, error]) => {
      images.push({
        imageUrl: response?.image || "/not-found-product.png",
      });
    });

    return images;
  });
}
