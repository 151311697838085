import React, { useMemo } from "react";
import { DotsSpinner, Modal } from "../../../ui";
import DebouncedInput from "../DebouncedInput/DebouncedInput";
import {
  CatalogTitle,
  Loader,
  OptionWrapper,
  WrapperActionButtons,
  WrapperGroupUpdate,
} from "./ModalGroupUpdate.styled";
import { NormalizedCatalogName } from "../../../constants/productTypes";
import SelectWeight from "./SelectWeight/SelectWeight";
import { ModalModes } from "./ModalGroupUpdate.model";
import Thank from "./thank";
import { Button } from "@mui/material";

type ModalGroupUpdateProps = {
  open: boolean;
  handleClose: () => void;
  formik: any;
  modes: typeof ModalModes;
  mode: ModalModes;
};

const ModalGroupUpdate = ({
  open,
  handleClose,
  formik,
  mode,
  modes,
}: ModalGroupUpdateProps) => {
  const catalogs = useMemo(() => {
    if (formik.values) {
      return Object.keys(formik.values);
    } else {
      return [];
    }
  }, [formik.values]);

  const hasChangedGroupUpdate = useMemo(() => {
    return (
      formik.dirty &&
      Object.keys(formik.values).some(
        (groupKey) =>
          formik.values[groupKey]?.count &&
          formik.values[groupKey]?.weight?.amount
      )
    );
  }, [formik.dirty, formik.values]);

  return (
    <Modal open={open} setOpen={handleClose} title="Group Update">
      {catalogs.map((catalog, index) => (
        <WrapperGroupUpdate key={index}>
          <CatalogTitle>{NormalizedCatalogName[catalog]}</CatalogTitle>
          <OptionWrapper>
            <SelectWeight
              options={formik.values[catalog]["availableWeightOptions"]}
              catalog={catalog}
              formik={formik}
            />
            <DebouncedInput
              label="Quantity"
              callback={(value: number) => {
                formik.setFieldValue(`${catalog}.count`, value);
              }}
              hideButtons
              min={0}
              disabled={formik.isSubmitting}
            />
          </OptionWrapper>
        </WrapperGroupUpdate>
      ))}

      <WrapperActionButtons>
        <Button
          variant="outlined"
          disabled={formik.isSubmitting}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={formik.isSubmitting || !hasChangedGroupUpdate}
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </WrapperActionButtons>

      {mode === modes.SENDING && (
        <Loader show={formik.isSubmitting}>
          <DotsSpinner />
        </Loader>
      )}

      {mode === modes.SUCCESS && <Thank handleClose={handleClose} />}
    </Modal>
  );
};

export default ModalGroupUpdate;
