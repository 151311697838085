import { useDispatch, useSelector } from "react-redux";
import { currencySelectors } from "redux/currency";
import { userSelectors } from "redux/user";
import { BucketItem, BucketItemWithImage } from "shared/types/cartItem";
import superCartActions from "../redux/actions";
import cartAPI from "../redux/api";
import { prepareUpdatedItem } from "./useAllToCart/useCartHandlers.utils";
import { toastNoCartModify } from "utils";
import { CurrencyType } from "../../../shared/types/pricing";

const useCartHandlers = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);

  const handleDelete = async (uuid: string): Promise<void> => {
    if (isAuth) {
      dispatch(superCartActions.setSuperCartLoading(true));
      const response = await cartAPI.fetchDeleteItemFromCart(uuid);

      if (response[2] === 200) {
        dispatch(superCartActions.deleteItem(uuid));
      } else {
        toastNoCartModify();
      }
      dispatch(superCartActions.setSuperCartLoading(false));
    } else {
      dispatch(superCartActions.deleteItem(uuid));
    }
  };

  const handleUpdate = async (
    apiCall: (
      data: BucketItemWithImage,
      currency: CurrencyType
    ) => Promise<{ changed: BucketItem[] }>,
    cartItem: BucketItemWithImage,
    propertyToUpdate: string
  ): Promise<void> => {
    if (!isAuth) {
      const updatedItem = prepareUpdatedItem(cartItem, propertyToUpdate);
      dispatch(superCartActions.updateItem(updatedItem));
      return;
    }

    dispatch(superCartActions.setSuperCartLoading(true));
    const response = await apiCall(cartItem, currency.abbr);

    if (response[0]?.changed?.length) {
      dispatch(superCartActions.updateItem(response[0].changed[0]));
    } else if (response[2] === 400) {
      toastNoCartModify();
    }

    dispatch(superCartActions.setSuperCartLoading(false));
  };

  const handleChangeCount = (data: BucketItem): Promise<void> =>
    handleUpdate(cartAPI.fetchUpdateCartItemCount, data, "count");

  const handleChangeWeight = (data: BucketItem): Promise<void> =>
    handleUpdate(cartAPI.fetchUpdateCartItemWeight, data, "weight");

  const handleClearCart = async (): Promise<void> => {
    if (isAuth) {
      const response = await cartAPI.fetchClearCart();
      if (response[2] === 200) {
        dispatch(superCartActions.clear());
      } else {
        toastNoCartModify();
      }
    } else {
      dispatch(superCartActions.clear());
    }
  };

  return {
    handleDelete,
    handleChangeCount,
    handleChangeWeight,
    handleClearCart,
  };
};

export default useCartHandlers;
