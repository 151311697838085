import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { CartMessage } from "ui/cart-message/cart-message";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { customFetchDelayedMiddleware } from "../../../../../context/sseContext";
import { RequestMethods } from "../../../../../utils/customFetch";
import { rootPages } from "../../../../../constants";
import { CHECKOUT_MODES } from "../../../model/Checkout.contants";
import { CheckoutContext } from "../../../lib/Checout.context";
import superCartActions from "../../../../../features/super-cart/redux/actions";
import { checkoutActions } from "../../../../../redux/checkout";
import { AmplitudeEvents } from "../../../../../context/AmplitudeEvents";
import { useAmplitudeState } from "../../../../../context/AmplitudeContext";

// @TODO Refactoring
const ThankPay = () => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const { pathname } = useLocation();
  const { setCurrentMode } = useContext(CheckoutContext);
  const dispatch = useDispatch();

  const handlePayNow = async () => {
    setCurrentMode(CHECKOUT_MODES.SENDING_ORDER);

    // @TODO Temporary solutions, rethink
    const orderId = pathname.match(/([^/]+$)/)[0];

    const paymentTokenResponse = await customFetchDelayedMiddleware({
      method: RequestMethods.POST,
      url: `/customers/current/purchases/by/uuid/${orderId}/payments/lock`,
    });

    const paymentToken = paymentTokenResponse[0]?.paymentToken;

    localStorage.setItem("payment-token", paymentToken);

    history.replace(`/${rootPages.SUBMIT_PAYMENT_FORM}`);

    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_TO_PAY);

    dispatch(superCartActions.clear());
    dispatch(checkoutActions.setInitial());
  };

  return (
    <CartMessage variant="success">
      <CartMessage.Title>
        Thank you, your order has been placed successfully
      </CartMessage.Title>
      <CartMessage.Description>
        <strong>
          Please note that the payment is still pending for this order.
        </strong>
        <br />
        Select "Pay Now" button to proceed with payment and finalise it.
        <br />
        If there’s an issue with your payment, you can try paying later via your
        Profile.
      </CartMessage.Description>
      <CartMessage.ActionWrapper>
        <Button variant="contained" onClick={handlePayNow}>
          Pay Now
        </Button>
      </CartMessage.ActionWrapper>
    </CartMessage>
  );
};

export default ThankPay;
