import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import { utilActions } from "../../redux/util";
import CurrencySelect from "./currency-select";
import { common, currencies } from "constants/index";
import { currencyActions, currencySelectors } from "redux/currency";

const CurrencySelectContainer = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const storeCurrency = useSelector(currencySelectors.selectCurrent);
  const defaultCurrency = currencies[common.DEFAULT_CURRENCY];
  const currency = storeCurrency;
  const list = Object.keys(currencies).map((el) => currencies[el]);
  const isDisabled = useSelector(currencySelectors.selectDisableSelect);

  const handleClickOption = (abbr) => {
    const selectedCurrentCurrency = currencies[abbr];
    dispatch(currencyActions.setCurrent(selectedCurrentCurrency));
    amplitude.logEvent(events.currency_select, {
      currency: selectedCurrentCurrency?.abbr,
    });
  };

  useEffect(() => {
    if (!currency) {
      dispatch(currencyActions.setCurrent(defaultCurrency));
    }
  }, []);

  return (
    <CurrencySelect
      currency={currency}
      list={list}
      handleClickOption={handleClickOption}
      disabled={isDisabled}
    />
  );
};

export default CurrencySelectContainer;
