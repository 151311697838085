import { CartItemWithImage } from "../../../../../shared/types/cart";
import { RestrictedCartItem } from "./RestrictionDialog.types";

export const mapRestrictedCartItems = (
  // @TODO Specify type
  restrictedItems: any[],
  cartItemsWithImages: CartItemWithImage[]
): RestrictedCartItem[] => {
  if (restrictedItems?.length) {
    return restrictedItems.reduce((acc, next) => {
      const foundItem = cartItemsWithImages.find(
        (cartItem) => cartItem?.addedToBucket?.uuid === next?.uuid
      );

      return [
        ...acc,
        ...(foundItem
          ? [
              {
                code: foundItem.product.code,
                image: foundItem.image,
                quantity: foundItem?.addedToBucket.count,
                weight: foundItem?.addedToBucket.weight,
                restrictionReasons: next.reasons,
                price: foundItem?.addedToBucket.pay.pricePerOneCount,
                currency: foundItem?.prices.currency,
              },
            ]
          : []),
      ];
    }, []);
  }

  return [];
};
