import React from "react";
import { useDispatch } from "react-redux";
import PostalCodeIssueModal from "./PostalCodeIssueDialog";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import { checkoutActions } from "../../../../../redux/checkout";

type PostalCodeIssueDialogContainerProps = Partial<DialogProps>;

const PostalCodeIssueDialogContainer = ({
  ...rest
}: PostalCodeIssueDialogContainerProps) => {
  const dispatch = useDispatch();

  const handleClickChangeAddress = () => {
    // @TODO Rethink this solution
    rest.onClose(null, null);
    dispatch(checkoutActions.openAccordion("step4"));
  };

  return (
    <PostalCodeIssueModal
      onCancel={() => rest.onClose(null, null)}
      onChangeAddress={handleClickChangeAddress}
      {...rest}
    />
  );
};

export default PostalCodeIssueDialogContainer;
