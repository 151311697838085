// @ts-check
import { prepareData } from "./utils";
import { customFetch } from "../../utils";
import { endpoints } from "../../constants";
import { customFetchDelayedMiddleware } from "../../context/sseContext";
import { RequestMethods } from "../../utils/customFetch";

export const fetchCosts = async (data, currency) => {
  const newData = prepareData(data);
  newData.currency = currency.abbr;

  return customFetch({
    method: RequestMethods.POST,
    url: `${endpoints.CART_ADDITIONAL_COSTS}`,
    data: newData,
  });
};

export const fetchAvailablePaymentMethods = async (currency) => {
  return customFetch({
    method: RequestMethods.GET,
    url: `${endpoints.CART_PAYMENT_METHODS}`,
    data: null,
    config: {
      params: {
        currency: currency.abbr,
      },
    },
  });
};

export const fetchCalculations = async (data) => {
  const response = await customFetchDelayedMiddleware({
    method: RequestMethods.POST,
    url: endpoints.CHECKOUT_CALCULATIONS,
    data,
  });

  return response[0];
};

export const fetchConditionsByAddressShipping = async (
  addressesShippingUuid: string
) => {
  const response = await customFetchDelayedMiddleware({
    method: RequestMethods.POST,
    url: `${endpoints.CONDITIONS_BY_SHIPPING_ADDRESS}${addressesShippingUuid}`,
  });

  return response[0];
};
