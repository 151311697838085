import { endpoints } from "../../constants";
import { decode } from "../../utils";
import productTypes, { ProductTypesV2 } from "../../constants/productTypes";
import { customFetchDelayedMiddleware } from "../../context/sseContext";
import { RequestMethods } from "../../utils/customFetch";

export const fetchProduct = async ({ code, cat, currencyAbbr, includeAdditionalResults}) => {
  const res = await customFetchDelayedMiddleware({
    method: RequestMethods.POST,
    url: `${endpoints.CATALOG}/search/in/${ProductTypesV2[cat]}/by/codes`,
    data: {
      compounds: decode(code),
      ...(currencyAbbr && { currency: currencyAbbr }),
      ...(cat !== productTypes.REALDB && includeAdditionalResults && { include: ["ANALOGS", "EQUIVALENTS", "FORMS"]}),
    },
  });

  const updatedResponse = [...res];

  if (updatedResponse[0]) {
    const parsedDelayedResult = updatedResponse[0]?.delayedResult
      ? JSON.parse(updatedResponse[0]?.delayedResult)?.results[0]
      : updatedResponse[0]?.results[0];


    updatedResponse[0] = parsedDelayedResult;

    return updatedResponse;
  }
};
