import React from "react";
import {
  Step,
  StepLabel,
  StepLine,
  StepperContainer,
} from "./OrderStatusesStepper.styled";

interface StepperProps {
  steps: { filledPercent: number; label: string }[];
}

const OrderStatusesStepper = ({ steps }: StepperProps) => {
  return (
    <StepperContainer>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLine filledPercent={step.filledPercent} />
          <StepLabel active={Boolean(step.filledPercent)}>
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </StepperContainer>
  );
};

export default OrderStatusesStepper;
