import { Alert, FormControl, TextField } from "@mui/material";
import { getCountriesOrStatesOptions } from "pages/CheckoutV2/lib/Checkout.utils";
import BaseNativeSelect from "pages/CheckoutV2/ui/Content/StepperCheckout/AddressesContent/BaseNativeSelect/BaseNativeSelect";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { utilSelectors } from "../../../redux/util";
import { Button } from "../../../ui";
import {
  ErrorWrapper,
  FormRow,
  InputLabelTypography,
  InputWrapper,
  SubmitWrapper,
} from "./CreateAddress.styled";
// @TODO Check this hook and optimise logic if possible
import { userSelectors } from "redux/user";
import { AddressEntity, AddressPayload } from "shared/types/user";
import useAddressForm from "../../hooks/useAddressForm";

type CreateAddressFormProps = {
  onSubmit: (formData: any) => void;
  formValues?: AddressEntity | AddressPayload;
};

const CreateAddressForm = ({
  onSubmit,
  formValues,
}: CreateAddressFormProps) => {
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);
  const addressesError = useSelector(userSelectors.selectAddressesError);

  const [countryOptions, setCountryOptions] = useState<
    { value: string; title: string }[]
  >([]);
  const [stateOptions, setStateOptions] = useState<
    { value: string; title: string }[]
  >([]);

  useEffect(() => {
    setCountryOptions(getCountriesOrStatesOptions(countries));
  }, [countries]);

  useEffect(() => {
    setStateOptions(getCountriesOrStatesOptions(usStates));
  }, [usStates]);

  const handleSubmit = (values) => {
    onSubmit(values);
  };
  const { formik, vatPattern, showStateSelect } = useAddressForm({
    handleSubmit,
    formValues,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">Country</InputLabelTypography>
          <FormControl
            error={formik.touched.country && Boolean(formik.errors.country)}
            sx={{ width: "100%" }}
          >
            <BaseNativeSelect
              fieldName="country"
              formik={formik}
              options={countryOptions}
            />
          </FormControl>
        </InputWrapper>
      </FormRow>

      {showStateSelect && (
        <FormRow>
          <InputWrapper>
            <InputLabelTypography variant="body1">States</InputLabelTypography>
            <FormControl
              error={formik.touched.usaState && Boolean(formik.errors.usaState)}
              sx={{ width: "100%" }}
            >
              <BaseNativeSelect
                fieldName="usaState"
                formik={formik}
                options={stateOptions}
                label="Select state"
              />
            </FormControl>
          </InputWrapper>
        </FormRow>
      )}

      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">City</InputLabelTypography>
          <TextField
            fullWidth
            id="city"
            name="city"
            placeholder="Enter city"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabelTypography variant="body1">
            Postal Code
          </InputLabelTypography>
          <TextField
            fullWidth
            id="zip"
            name="zip"
            placeholder="Enter postal code"
            value={formik.values.zip}
            onChange={formik.handleChange}
            error={formik.touched.zip && Boolean(formik.errors.zip)}
            helperText={formik.touched.zip && formik.errors.zip}
          />
        </InputWrapper>
      </FormRow>

      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">Address</InputLabelTypography>
          <TextField
            fullWidth
            id="address"
            name="address"
            placeholder="Enter address"
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </InputWrapper>
      </FormRow>

      <FormRow>
        <InputWrapper>
          <InputLabelTypography variant="body1">
            Phone Number
          </InputLabelTypography>
          <TextField
            fullWidth
            id="phone"
            name="phone"
            placeholder="Enter phone number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </InputWrapper>

        <InputWrapper>
          <InputLabelTypography variant="body1">Company</InputLabelTypography>
          <TextField
            fullWidth
            id="company"
            name="company"
            placeholder="Enter company"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </InputWrapper>
      </FormRow>

      {vatPattern && (
        <FormRow>
          <InputWrapper>
            <InputLabelTypography variant="body1">
              VAT number
            </InputLabelTypography>
            <TextField
              fullWidth
              id="europeVAT"
              name="europeVAT"
              placeholder="Enter VAT number"
              value={formik.values.europeVAT}
              onChange={formik.handleChange}
              error={
                formik.touched.europeVAT && Boolean(formik.errors.europeVAT)
              }
              helperText={formik.touched.europeVAT && formik.errors.europeVAT}
            />
          </InputWrapper>
        </FormRow>
      )}
      {addressesError && (
        <ErrorWrapper>
          <Alert variant="outlined" severity="error">
            {addressesError}
          </Alert>
        </ErrorWrapper>
      )}

      <SubmitWrapper>
        <Button fullWidth size="small" type="submit">
          Save
        </Button>
      </SubmitWrapper>
    </form>
  );
};

export default CreateAddressForm;
