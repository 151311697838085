import { StepperStep } from "../model/Checkout.types";
import { USER_TITLES } from "../model/Checkout.contants";

const getUserTitlesOptions = () => {
  return USER_TITLES.map((userTitle) => ({
    // Standard format for form options select
    value: userTitle,
    title: userTitle,
  }));
};

export const getCountriesOrStatesOptions = (countriesObj: {
  [key: string]: string;
}) => {
  if (countriesObj) {
    return Object.keys(countriesObj)
      .map((countryKey) => ({
        value: countryKey,
        title: countriesObj[countryKey],
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  return [];
};

export default getUserTitlesOptions;

export const checkAllStepsAreClosed = (steps: StepperStep[]) => {
  return Object.keys(steps).every(
    (stepKey) => !(steps[stepKey].open || steps[stepKey].disabled)
  );
};
