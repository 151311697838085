import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  STEPPER_CHECKOUT_STEPS,
  CHECKOUT_MODES,
} from "../../../model/Checkout.contants";
import StepperCheckout from "./StepperCheckout";
// @TODO Check path to redux, probably we have to make it scoped and place it into shared folder or leave it as it is
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../redux/checkout";
import { CheckoutContext } from "../../../lib/Checout.context";
import { checkAllStepsAreClosed } from "../../../lib/Checkout.utils";
import { userActions, userSelectors } from "../../../../../redux/user";
import superCartSelectors from "../../../../../features/super-cart/redux/selectors";
import { useHistory } from "react-router-dom";
import { rootPages } from "../../../../../constants";
// import { MODES } from "../../../../checkout/constants";

type StepperCheckoutContainerProps = {
  modeRegulator?: {
    mode: typeof CHECKOUT_MODES;
    modes: CHECKOUT_MODES;
    setMode: Dispatch<SetStateAction<CHECKOUT_MODES>>;
  };
};

const StepperCheckoutContainer = ({
  modeRegulator,
  ...rest
}: StepperCheckoutContainerProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkoutModesContext = useContext(CheckoutContext);

  // @TODO Recheck logic
  const profile = useSelector(userSelectors.selectProfile);

  const cartItemsWithImages = useSelector(
    superCartSelectors.selectCartItemsWithImages
  );

  // @TODO Rewrite in store to selectStepperCheckout
  const stepperCheckoutData = useSelector(checkoutSelectors.selectAccordion);

  const [stepperCheckoutItems, setStepperCheckoutItems] = useState([]);

  const handleEdit = (id: string) => {
    dispatch(checkoutActions.openAccordion(id));
  };

  useEffect(() => {
    /*
      SECTION.
      create items array to show steps on the page
    */
    const stepperCheckoutItems = STEPPER_CHECKOUT_STEPS.map((step, index) => {
      const accordionItemKey = `step${index + 1}`;

      return {
        ...step,
        ...stepperCheckoutData[accordionItemKey],
      };
    });

    setStepperCheckoutItems(stepperCheckoutItems);
    /* === End === */

    /*
      SECTION.
      check if all accordion closed and show "Submit Order" button
    */
    // const isAllAccordionsClosed = checkAllAccordionsClosed(accordionData);

    const isAllStepperStepsClosed = checkAllStepsAreClosed(stepperCheckoutData);

    if (isAllStepperStepsClosed) {
      checkoutModesContext.setIsReadyToConfirm(true);
      checkoutModesContext.setCurrentMode(CHECKOUT_MODES.CHECK_ORDER);
    } else {
      checkoutModesContext.setIsReadyToConfirm(false);
    }
    /* === End === */
  }, [checkoutModesContext, stepperCheckoutData]);

  useEffect(() => {
    dispatch(userActions.requestAddressesAndPersons());
  }, [dispatch]);

  // @TODO Recheck logic
  useEffect(() => {
    if (!profile) {
      dispatch(userActions.requestProfile());
    }
  }, [dispatch, profile]);

  /*
  SECTION.
  redirect to cart page if empty cartItems
*/
  useEffect(() => {
    if (
      !cartItemsWithImages?.length &&
      checkoutModesContext.currentMode !== CHECKOUT_MODES.THANK
    ) {
      history.push(`/${rootPages.CART}`);
    }
  }, [history, cartItemsWithImages, checkoutModesContext.currentMode]);
  /* === End === */

  return (
    <StepperCheckout
      stepperCheckoutItems={stepperCheckoutItems}
      handleEdit={handleEdit}
      {...rest}
    />
  );
};

export default StepperCheckoutContainer;
