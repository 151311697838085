import React from "react";
import {
  Step,
  StepNumber,
  StepTitle,
  Header,
  Content,
  Wrapper,
} from "./StepperCheckout.styled";
// @TODO Check logic and move into shared or leave it inside content
import { LikeLinkButton } from "../../../../../components";
import { StepperCheckoutStepItem } from "../../../model/Checkout.types";

type StepperCheckoutProps = {
  stepperCheckoutItems: StepperCheckoutStepItem[];
  handleEdit: (stepId: string) => void;
};

const StepperCheckout = ({
  stepperCheckoutItems,
  handleEdit,
  ...rest
}: StepperCheckoutProps) => (
  <Wrapper>
    {stepperCheckoutItems.map((stepperCheckoutItem, index) => {
      return (
        <Step
          key={index}
          last={index + 1 === stepperCheckoutItems?.length}
          disabled={stepperCheckoutItem.disabled}
          open={stepperCheckoutItem.open}
        >
          <Header isOpen={stepperCheckoutItem.open}>
            <StepNumber
              open={stepperCheckoutItem.open || !stepperCheckoutItem.disabled}
              disabled={stepperCheckoutItem.disabled}
            >
              {index + 1}
            </StepNumber>
            <StepTitle variant="h2">{stepperCheckoutItem.title}</StepTitle>
            {stepperCheckoutItem.open === false &&
              stepperCheckoutItem.disabled !== true && (
                <LikeLinkButton
                  onClick={() => {
                    handleEdit(`step${index + 1}`);
                  }}
                  style={{ marginLeft: "auto" }}
                >
                  Edit
                </LikeLinkButton>
              )}
          </Header>

          <Content open={stepperCheckoutItem.open}>
            {stepperCheckoutItem?.component({
              index: index,
              ...rest,
            })}
          </Content>
        </Step>
      );
    })}
  </Wrapper>
);

export default StepperCheckout;
