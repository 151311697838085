import api from "../../axios/api";
import { endpoints } from "../../constants";
import { customFetchDelayedMiddleware } from "../../context/sseContext";
import { customFetch } from "../../utils";
import { RequestMethods } from "../../utils/customFetch";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

export async function fetchUser() {
  const res = await customFetch(
    {
      url: endpoints.USER_ME,
    },
    initParams
  );

  return res[0];
}

export async function fetchProfile() {
  //@TODO: Check the logic of request, rewrite using customFetchDelayedMiddleware
  const res = await api.get(endpoints.USER_PROFILE, initParams);

  return res?.data;
}

export const fetchLogout = async () => {
  return await customFetch({
    method: "post",
    url: endpoints.AUTH_LOGOUT,
  });
};

// V2

export async function fetchAddressesAndPersons() {
  const response = await customFetchDelayedMiddleware({
    url: endpoints.CURRENT_ADDRESSES_AND_PERSONS,
  });

  return response[0];
}

export const fetchAddresses = async () => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: endpoints.CURRENT_ADDRESSES,
  });
};

export const fetchAddAddress = async (data) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.PUT,
    url: endpoints.CURRENT_ADDRESSES,
    data,
  });
};

export const fetchDeleteAddress = async (id) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.DELETE,
    url: `${endpoints.CURRENT_ADDRESSES}/${id}`,
  });
};

export const fetchEditAddress = async (data) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.PATCH,
    url: `${endpoints.CURRENT_ADDRESSES}/${data.uuid}`,
    data,
  });
};

export const fetchPersons = async () => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.GET,
    url: endpoints.CURRENT_PERSONS,
  });
};

export const fetchAddPerson = async (data) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.PUT,
    url: endpoints.CURRENT_PERSONS,
    data,
  });
};

export const fetchDeletePerson = async (id) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.DELETE,
    url: `${endpoints.CURRENT_PERSONS}/${id}`,
  });
};

export const fetchEditPerson = async (data) => {
  return await customFetchDelayedMiddleware({
    method: RequestMethods.PATCH,
    url: `${endpoints.CURRENT_PERSONS}/${data.uuid}`,
    data,
  });
};
