import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../../../../../redux/user";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../../redux/checkout";
import { useAmplitudeState } from "../../../../../../context/AmplitudeContext";
import PersonContent from "../PersonContent/PersonContent";
import { AmplitudeEvents } from "../../../../../../context/AmplitudeEvents";

const StepPersonBillingContainer = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const contactPersonsWithUserProfile = useSelector(
    userSelectors.selectContactPersonsWithUserProfile
  );

  const selectedPersonUuid = useSelector(
    checkoutSelectors.selectPersonBillingUuid
  );

  const handleChangeBillingPersonUuid = (personUuid?: string) => {
    dispatch(checkoutActions.setBillingPersonUuid(personUuid));
  };

  const handleNextStep = () => {
    dispatch(checkoutActions.closeAccordion("step2"));
    dispatch(checkoutActions.openAccordion("step3"));
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_CONTACT_BILLING);
  };

  return (
    <PersonContent
      type={"Billing"}
      selectedPersonUuid={selectedPersonUuid}
      contactPersonsWithUserProfile={contactPersonsWithUserProfile}
      onChangePersonUuid={handleChangeBillingPersonUuid}
      onNextStep={handleNextStep}
    />
  );
};

export default StepPersonBillingContainer;
