// @ts-check

/**
 *
 * @param {string} q
 * @returns {Array.<string>}
 */
const getArrayFromBulkSearch = (q) => {
  const decoded = decodeURIComponent(q);

  const stereochemistryCheck = (decoded.match(/\|/g) || []).length;

  if (stereochemistryCheck < 2) {
    return decoded.split(/[;,\s]+/).filter(Boolean);
  }

  const result = decoded
    .split(/\s*,\s*(?=\()/)
    .map((item) => item.trim())
    .filter(Boolean);

  return result;
};

export default getArrayFromBulkSearch;
