import React from "react";
import { ContactPersonEntity } from "../../../../../../shared/types/user";
import RadioCardV2 from "../../../../../../shared/ui/RadioCardV2/RadioCardV2";
import { Typography } from "@mui/material";
import CreatePerson from "./CreatePerson";
import { Button } from "../../../../../../ui";
import { PersonWrapper } from "./PersonContent.styled";

type PersonContentProps = {
  type: "Shipping" | "Billing";
  contactPersonsWithUserProfile: ContactPersonEntity[];
  selectedPersonUuid: string | null;
  onChangePersonUuid: (personUuid?: string) => void;
  onNextStep: VoidFunction;
};

const PersonContent = ({
  type,
  contactPersonsWithUserProfile,
  selectedPersonUuid,
  onChangePersonUuid,
  onNextStep,
}: PersonContentProps) => {
  return (
    <>
      {contactPersonsWithUserProfile?.map((contactPerson, index) => {
        const isActive = contactPerson?.uuid === selectedPersonUuid;

        const contactPersonCombinedInfo = `
        ${contactPerson?.title ? contactPerson?.title + ". " : ""}${
          contactPerson.firstName
        } ${contactPerson?.lastName}, ${contactPerson.email},
        ${contactPerson.phone}, ${contactPerson.company}
        `;

        return (
          <PersonWrapper key={index}>
            <RadioCardV2
              isActive={isActive}
              handleChange={() => onChangePersonUuid(contactPerson?.uuid)}
              hintText={index === 0 && "YOUR PROFILE"}
            >
              <Typography variant="body1">
                {contactPersonCombinedInfo}
              </Typography>
            </RadioCardV2>
          </PersonWrapper>
        );
      })}

      {/* @TODO Communicate to understand do we really need this condition */}
      {/*{Boolean(contactPersons?.length < 5) && <CreatePerson />}*/}
      <CreatePerson type={type} />

      {/* @TODO Temporary solution, change to mui button */}
      <Button
        onClick={onNextStep}
        size="small"
        fullWidth
        style={{ marginTop: "18px" }}
      >
        Next
      </Button>
    </>
  );
};

export default PersonContent;
