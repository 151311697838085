import { ProductTypesV2 } from "constants/productTypes";
import { SearchAsTypes, SearchAsTypesV2 } from "constants/searchAsTypes";
import { events, useAmplitudeState } from "context/AmplitudeContext";
import superCartActions from "features/super-cart/redux/actions";
import cartAPI from "features/super-cart/redux/api";
import { FilterOption } from "pages/catalog/parts/FilterLocation/FilterLocation.types";
import { FilterTypes } from "pages/catalog/types";
import { SearchByTypes } from "pages/home/parts/search-form/SearchForm.types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchProducts } from "redux/catalog/api";
import { currencySelectors } from "redux/currency";
import { userSelectors } from "redux/user";
import { CartItem } from "shared/types/cart";
import { decode } from "utils";
import { prepareList } from "./useAllToCart.utils";

type CatalogFilters = {
  [key in FilterTypes]?:
    | string
    | number
    | {
        from?: number;
        to?: number;
      }
    | string[];
};

type UseAllToCartProps = {
  searchBy?: SearchByTypes;
  code: string;
  searchedType?: string;
  cat: keyof typeof ProductTypesV2;
  list?: CartItem[];
  catalogFilters: CatalogFilters;
  total: number;
};

const useAllToCart = ({
  searchBy,
  code,
  searchedType,
  cat,
  list = [],
  catalogFilters,
  total,
}: UseAllToCartProps) => {
  const { amplitude } = useAmplitudeState();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { sstype, location, similarity, ...appliedFilters } = catalogFilters;

  const handleAllToCart = async () => {
    amplitude.logEvent(events.all_to_cart);
    setLoading(true);
    setError(false);

    if (isAuth) {
      const searchParams = searchBy
        ? `in/${ProductTypesV2[cat]}/by/${searchBy}`
        : searchedType
        ? `in/${ProductTypesV2[cat]}/by/SMILEs/${SearchAsTypesV2[sstype]}${
            sstype === SearchAsTypes.SIM && similarity ? `/${similarity}` : ""
          }`
        : `by/codes`;

      const allToCartResponse = await cartAPI.fetchAllToCart({
        searchParams,
        currency: currency.abbr,
        data: {
          compounds: decode(code),
          currency: currency?.abbr,
          criterias: {
            ...appliedFilters,
            ...(location !== FilterOption.ALL && location && { location }),
          },
        },
      });

      if (allToCartResponse?.[0]) {
        const cb = () => {
          runSuccessToast();
          setLoading(false);
        };

        const fetchedItems = await cartAPI.fetchCartInfo(currency.abbr);

        dispatch(superCartActions.allToCart(fetchedItems[0].items));

        cb();
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      let finalList = [];

      if (total > list?.length) {
        const productsResponse = await fetchProducts({
          code,
          cat,
          type: searchedType,
          sstype,
          curPage: 1,
          pageSize: total,
        });
        finalList = productsResponse?.searchResults;
      } else {
        finalList = list;
      }
      const preparedList = prepareList(finalList);

      dispatch(superCartActions.allToCart(preparedList));

      runSuccessToast();
      setLoading(false);
    }
  };

  return {
    handleAllToCart,
    isLoading,
    error,
  };
};

export const runSuccessToast = () => {
  toast("Products were added to cart!");
};

export default useAllToCart;
