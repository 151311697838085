import React from "react";
import Thank from "./Thank";

const ThankQuote = () => {
  return (
    <Thank
      title="Thank you, your quote is placed"
      description={`We’ve sent a confirmation to your email address.\n
You can also  download a PDF with all the details of your quote below.`}
    />
  );
};

export default ThankQuote;
