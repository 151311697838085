import styled from "styled-components";
import { accentColor, lineColor } from "ui/colors";
import { Typography } from "@mui/material";

export const Wrapper = styled.div``;

export const Step = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${lineColor};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  ${(props) => {
    if (props.last) {
      return `
        border-bottom: none;
      `;
    }
  }};
`;

export const StepTitle = styled(Typography)`
  margin-bottom: 0;
  // @TODO Remove after resolve global styles
  font-size: 20px;
`;

export const StepNumber = styled.div`
  min-width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 46px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${accentColor};
  border: 2px solid ${accentColor};

  margin-right: 16px;

  ${({ open }) => {
    if (open) {
      return `
        background-color: ${accentColor};
        color: white;
      `;
    }
  }}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  ${({ isOpen }) => {
    if (isOpen) {
      return `
          margin-bottom: 24px;
      `;
    }
  }};
`;

export const Content = styled.div`
  display: none;

  ${(props) => {
    if (props.open) {
      return `
        display: block;
      `;
    }
  }};
`;
