import React from "react";
import { addZeroes } from "../../../../utils";
import { useSelector } from "react-redux";
import { currencySelectors } from "../../../../redux/currency";

type SummaryItemProps = {
  price?: string;
};

const SummaryItem = ({ price }: SummaryItemProps) => {
  const currency = useSelector(currencySelectors.selectCurrent);

  return (
    <>
      {/* @TODO Rethink null / undefined check */}
      {typeof price === "number" ? (
        <span>
          {addZeroes(price)} {currency?.abbr}
        </span>
      ) : (
        "-"
      )}
    </>
  );
};

export default SummaryItem;
