import * as yup from "yup";

const FIELD_MAX_LENGTH = 255;

const personSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(
      FIELD_MAX_LENGTH,
      `First name must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(
      FIELD_MAX_LENGTH,
      `Last name must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required")
    .max(
      FIELD_MAX_LENGTH,
      `Email must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  phone: yup
    .string()
    .required("Phone number is required")
    .max(
      FIELD_MAX_LENGTH,
      `Phone number must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  company: yup
    .string()
    .required("Company is required")
    .max(
      FIELD_MAX_LENGTH,
      `Company must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
});

export default personSchema;
