import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import endpoints from "../../../../../constants/endpoints";
import { customFetchDelayedMiddleware } from "../../../../../context/sseContext";
import { RequestMethods } from "../../../../../utils/customFetch";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { OrderType } from "../../../../../shared/types/Orders.types";

export const fetchOrderByUuid = (orderUuid: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery({
    queryKey: [`${endpoints.ORDERS}/by/storeId/${orderUuid}`],
    queryFn: async () => {
      const response = await customFetchDelayedMiddleware({
        method: RequestMethods.GET,
        url: `${endpoints.ORDERS}/by/storeId/${orderUuid}?includeItems=true&includeEvents=true&includeItemTrackings=true&includePayments=true&includeItemEvents=true&includeRelatedPurchase=true`,
      });

      if (response[1]) {
        toast.error(response[1]);
      }

      return response;
    },
    enabled: Boolean(orderUuid),
    select: (data) => data[0],
  });
};

export const fetchCancelOrderByUuid = (orderUuid: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMutation({
    mutationKey: [`${endpoints.ORDER_BY_UUID}/${orderUuid}/as-order/cancel`],
    mutationFn: () => {
      return customFetchDelayedMiddleware({
        method: RequestMethods.PATCH,
        url: `${endpoints.ORDER_BY_UUID}/${orderUuid}/as-order/cancel`,
      });
    },
    onSuccess: (response) => {
      if (response[0]) {
        // @TODO Check this solution
        queryClient.invalidateQueries(`${endpoints.ORDERS}/by/storeId`);
      }
      if (response[1]) {
        toast.error(response[1]);
      }
    },
  });
};

export const fetchConvertQuoteToOrderByUuid = (orderUuid: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMutation({
    mutationKey: [`${endpoints.ORDER_BY_UUID}/${orderUuid}/as-quote/to-order`],
    mutationFn: (formValues: { purchaseOrderNumber: string }) => {
      return customFetchDelayedMiddleware({
        method: RequestMethods.POST,
        url: `${endpoints.ORDER_BY_UUID}/${orderUuid}/as-quote/to-order`,
        data: formValues,
      });
    },
    onSuccess: (response) => {
      if (response[0]) {
        toast.success("Order successfully created");
        queryClient.invalidateQueries(`${endpoints.ORDERS}`);
      }
      if (response[1]) {
        toast.error(response[1]);
      }
    },
  });
};

export const fetchDownloadQuotePdfByUuid = (
  orderUuid: string,
  storeId: string,
  orderType: OrderType
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMutation({
    mutationKey: [`${endpoints.ORDER_BY_UUID}/${orderUuid}/as-file/PDF`],
    mutationFn: () => {
      return customFetchDelayedMiddleware({
        method: RequestMethods.GET,
        url: `${endpoints.ORDER_BY_UUID}/${orderUuid}/as-file/PDF`,
        config: {
          responseType: "blob",
        },
      });
    },
    onSuccess: (response) => {
      if (response[0]) {
        fileDownload(response[0], `${orderType.toLowerCase()}_${storeId}.pdf`);
      }
      if (response[1]) {
        toast.error(response[1]);
      }
    },
  });
};
