import styled from "styled-components";
import { accentColor, darkBlue, lineColor } from "ui/colors";
import { Box } from "@mui/material";

export const SubtotalWithHintWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    path {
      fill: ${darkBlue};
    }

    &:hover {
      path {
        fill: ${accentColor};
      }
    }
  }
`;

export const OrderDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  border-bottom: 1px solid ${lineColor};
  color: ${darkBlue};
  &:last-child {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 18px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const OrderDetailsItemTitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
`;

export const OrderDetailsWrapper = styled.div`
  ${OrderDetailsItem} {
    &:last-child {
      border-bottom: none;
    }
  }
`;
