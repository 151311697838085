import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { PropsWithChildren } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import {
  CloseButtonStyled,
  DialogContentStyled,
  DialogTitleStyled,
} from "./DialogCore.styled";

type DialogCoreProps = {
  title?: string;
  hideClose?: boolean;
} & DialogProps;

const DialogCore = ({
  title,
  children,
  onClose,
  open,
  hideClose,
  ...rest
}: PropsWithChildren<DialogCoreProps>) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        {...rest}
      >
        {title && (
          <DialogTitleStyled id="alert-dialog-title">{title}</DialogTitleStyled>
        )}

        {!hideClose && (
          <CloseButtonStyled
            onClick={onClose}
            size="medium"
            data-test="close-modal-button"
          >
            <CloseIcon />
          </CloseButtonStyled>
        )}

        <DialogContentStyled>{children}</DialogContentStyled>
      </Dialog>
    </>
  );
};

export default DialogCore;
