import React from "react";
import { Box } from "@mui/material";
import { CustomizedTooltip } from "../../../ui";
import { InfoIcon } from "../../../ui/icons";
import ZeroCostsCalculatedTooltip from "../../../pages/profile/pages/ArchivedOrder/parts/ZeroCostsCalculatedTooltip";
import {
  OrderDetailsItem,
  OrderDetailsWrapper,
  SubtotalWithHintWrapper,
} from "./OrderPricesDetails.styled";
import SummaryItem from "./SummaryItem/SummaryItem";
import { BoldWrapper } from "./SummaryItem/SummaryItem.styled";

type OrderDetailsProps = {
  price: any;
  currency?: string;
  isAnyItemInquery?: boolean;
  type?: string;
};

const OrderPricesDetails = ({
  price,
  isAnyItemInquery,
  type,
}: OrderDetailsProps) => {
  const showZeroCostsTooltip =
    price?.costsCalculated === false && type === "QUOTE";

  return (
    <OrderDetailsWrapper>
      <OrderDetailsItem>
        <Box>
          <SubtotalWithHintWrapper>
            <span>Subtotal</span>
            {isAnyItemInquery && (
              <CustomizedTooltip
                title="Order includes feasible compounds which will be confirmed by our sales representative"
                placement="right"
              >
                <InfoIcon />
              </CustomizedTooltip>
            )}
          </SubtotalWithHintWrapper>
          <SummaryItem price={price?.subtotal} />
        </Box>
      </OrderDetailsItem>
      <OrderDetailsItem>
        <Box>
          <span>
            Handling
            {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
          </span>
          <SummaryItem price={price?.handling} />
        </Box>

        <Box>
          <span>
            Shipping
            {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
          </span>
          <SummaryItem price={price?.shipping} />
        </Box>
      </OrderDetailsItem>

      <OrderDetailsItem>
        <Box>
          <span>Tax</span>
          <SummaryItem price={price?.tax} />
        </Box>
        <Box>
          <span>Discount</span>
          <SummaryItem price={price?.discount} />
        </Box>
      </OrderDetailsItem>
      <OrderDetailsItem className="last">
        <Box>
          <span>
            <b>Total price</b>
          </span>
          <BoldWrapper>
            <SummaryItem price={price?.total} />
          </BoldWrapper>
        </Box>
      </OrderDetailsItem>
    </OrderDetailsWrapper>
  );
};

export default OrderPricesDetails;
