import React, { ChangeEvent, PropsWithChildren } from "react";
import Radio from "@mui/material/Radio";
import { HintWrapper, RadioCardWrapper } from "./RadioCardV2.styled";

type RadioCardV2Props = {
  isDisabled?: boolean;
  isActive: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  hintText?: string;
};

const RadioCardV2 = ({
  isDisabled,
  isActive,
  handleChange,
  hintText,
  children,
}: PropsWithChildren<RadioCardV2Props>) => {
  return (
    <RadioCardWrapper
      isDisabled={isDisabled}
      isActive={isActive}
      onClick={handleChange}
    >
      <Radio disabled={isDisabled} disableRipple checked={isActive} />
      {children}
      {hintText && <HintWrapper>{hintText}</HintWrapper>}
    </RadioCardWrapper>
  );
};

export default RadioCardV2;
