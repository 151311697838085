import styled from "styled-components";
import { Button, Link } from "ui/index";
import { blue, darkBlue, lightGray } from "ui/colors";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { Box } from "@mui/material";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  color: ${darkBlue};
  gap: 28px;
  padding: 18px 16px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const InfoChipWrapper = styled(Box)`
  min-height: 26px;
  display: flex;
  justify-content: flex-end;

  & span {
    font-size: 12px;
    font-weight: 500;
    padding: 0 12px;
  }
`;

export const Image = styled(Link)`
  width: 180px;
  height: 180px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`;

export const Code = styled(Link)`
  color: ${darkBlue};
  box-sizing: border-box;
  min-width: 160px;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
`;
export const PriceList = styled.div`
  max-width: 354px;
  width: 100%;
  flex-grow: 1;
`;
export const AddToCartButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
  & svg {
    width: 22px;
    height: 22px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ProductInfoItem = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 20px;
  justify-content: space-between;
  color: ${blue};
`;

export const TableCellCatalogList = styled(TableCell)`
  // @TODO Mui theme colors / sizes overwrite this part, check how to make it work without important
  color: ${blue} !important;
  font-size: 12px !important;
  padding: 8px 12px !important;
`;
export const TableHeadCatalogList = styled(TableHead)`
  background: ${lightGray};
`;
