import React from "react";
import { BoxButton, Wrapper } from "./PaginationBE.styled";
import { makeParams } from "../../utils";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../hooks";

type PaginationBEProps = {
  pagination: {
    page: number;
    pageSize: number;
    pagesCount: number;
  };
};

const PaginationBE = ({ pagination: { pagesCount } }: PaginationBEProps) => {
  const query = useQuery();
  const history = useHistory();

  const activePage = query.get("page") ? parseInt(query.get("page")) : 0;

  const onHandleClickPaginationPage = (page: string | number) => {
    const queryParams = makeParams({
      page: page,
    });

    history.push(`${history.location.pathname}?${queryParams}`);
  };

  return (
    <Wrapper>
      {[...Array(pagesCount)].map((_, index) => {
        return (
          <BoxButton
            key={index}
            active={activePage === index}
            onClick={() => onHandleClickPaginationPage(index)}
          >
            {index + 1}
          </BoxButton>
        );
      })}
    </Wrapper>
  );
};

export default PaginationBE;
