import React from "react";
import {
  Content,
  Header,
  InfoCell,
  List,
  ListItem,
  Scrollable,
  Title,
} from "./CartItemsList.styled";
import { CartItemWithImage } from "../../../../../shared/types/cart";
import { CustomizedTooltip } from "../../../../../ui";
import { InfoIcon2 } from "../../../../../ui/icons";
import CheckoutCartItemContainer from "./CheckoutCartItem/CheckoutCartItem.container";

type CartItemsListProps = {
  title: string;
  cartItems: CartItemWithImage[];
  isSynthesis?: boolean;
};

const CartItemsList = ({
  title,
  cartItems,
  isSynthesis,
}: CartItemsListProps) => {
  return (
    <>
      <Header>
        <InfoCell>
          <Title>{title}</Title>
          {isSynthesis && (
            <CustomizedTooltip
              title="Our chemists will evaluate the synthesis and get 
back to you with price and lead time confirmation 
within 1-2 days. No worries, it’s free of charge."
              placement="right"
            >
              <InfoIcon2 />
            </CustomizedTooltip>
          )}
        </InfoCell>
      </Header>
      <Content>
        <Scrollable>
          <List>
            {cartItems.map((cartItem, index) => {
              return (
                <ListItem key={index}>
                  <CheckoutCartItemContainer cartItem={cartItem} />
                </ListItem>
              );
            })}
          </List>
        </Scrollable>
      </Content>
    </>
  );
};

export default CartItemsList;
