import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import addressSchema from "shared/ui/CreateAddress/CreateAddress.validation";
import * as yup from "yup";
import { utilSelectors } from "../../redux/util";

type InitialValues = {
  [key: string]: string;
};

const initialValues: InitialValues = {
  country: "",
  city: "",
  address: "",
  zip: "",
  company: "",
  europeVAT: "",
  usaState: "",
  phone: "",
};

export const getVatPattern = ({ vatPatterns, country }) => {
  return vatPatterns?.[country];
};

// @TODO Refactoring
const useAddressForm = (props) => {
  const vatPatterns = useSelector(utilSelectors.selectVatPatterns);

  const [schema, setSchema] = useState(addressSchema);
  const [vatPattern, setVatPattern] = useState("");
  const [showStateSelect, setShowStateSelect] = useState(false);

  const formInitialValues = props.formValues || initialValues;

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      const updatedValues = { ...values };

      // @TODO Temporary solution
      if (!values?.europeVAT) {
        delete updatedValues.europeVAT;
      }

      if (values.country !== "US") {
        delete updatedValues.usaState;
      }
      props?.handleSubmit(updatedValues, formik);
    },
  });

  useEffect(
    () => {
      const pattern = getVatPattern({
        vatPatterns,
        country: formik.values.country,
      });

      setVatPattern(pattern);

      if (pattern) {
        const newSchema = yup.object().shape({
          ...addressSchema.fields,
          europeVAT: yup
            .string()
            .required("Europe VAT is required")
            .matches(new RegExp(pattern), "Invalid VAT format"),
        });
        setSchema(newSchema);
      } else {
        if (formik.values.country === "US") {
          setShowStateSelect(true);
          const newSchema = yup.object().shape({
            ...addressSchema.fields,
            usaState: yup.string().required("State is required for US"),
          });
          setSchema(newSchema);
        } else {
          setShowStateSelect(false);
          setSchema(addressSchema);
        }
      }

      if (!pattern) {
        formik.setValues((prev) => {
          const updatedFormValues = { ...prev };
          delete updatedFormValues.europeVAT;

          return {
            ...updatedFormValues,
          };
        }, false);
      }

      if (formik.values.country !== "US") {
        setShowStateSelect(false);

        formik.setValues((prev) => {
          const updatedFormValues = { ...prev };
          delete updatedFormValues.usaState;
          return {
            ...prev,
            europeVAT: !pattern ? "" : prev.europeVAT,
          };
        }, false);
      }
    },
    // eslint-disable-next-line
    [vatPatterns, formik.values.country, formik.values.usaState]
  );

  return {
    formik,
    vatPattern,
    showStateSelect,
    initialValues: formInitialValues,
  };
};

export default useAddressForm;
