import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import rootPages from "constants/rootPages";
import { useAmplitudeState, events } from "context/AmplitudeContext";

import { handleSubmit, getValidationSchema } from "./SignIn.utils";
import {
  LoginSignupPanel,
  MessageWithLink,
  TextLink,
  Button,
  InputWrapper,
  Checkbox,
  Section,
  ErrorMessage,
  PasswordStrength,
} from "ui";
import { LinkWrapper, CheckboxWrapper, ErrorWrapper } from "./SignIn.styled";
import { Email, Password } from "parts/form-inputs";
import { currencySelectors } from "redux/currency";
import { useQuery } from "hooks";
import superCartSelectors from "features/super-cart/redux/selectors";
import { passwordStrengthModes } from "ui/password-strength/password-strength";
import useLoaderFullPage from "../../features/Loaders/hooks/useLoaderFullPage";

const SignIn = () => {
  const query = useQuery();
  const redirectTo = query.get("redirectTo");

  const history = useHistory();
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();
  const superCart = useSelector(superCartSelectors.selectSuperCart);
  const currency = useSelector(currencySelectors.selectCurrent);
  const [error, setError] = useState("");

  const cartItems = superCart?.itemsWithImages;

  const handleForgotPasswordClick = () => {
    amplitude.logEvent(events.forgot_password, { click: true });
  };

  const logLoginSuccess = () => {
    amplitude.logEvent(events.login_success);
  };

  const logLoginFailure = () => {
    amplitude.logEvent(events.login_failure);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      if (values.remember) {
        amplitude.logEvent(events.remember_me);
      }

      showLoaderFillPage();

      await handleSubmit(values, {
        cartItems,
        history,
        redirectTo,
        formik,
        dispatch,
        setError,
        logLoginSuccess,
        logLoginFailure,
        currency,
      });

      hideLoaderFillPage();
    },
  });

  return (
    <Section ySpacing fullHeight>
      <LoginSignupPanel
        title="Login"
        footerContent={
          <MessageWithLink
            message={`Don't have an account?`}
            linkText="Register"
            linkHref="/signup"
          />
        }
      >
        <form onSubmit={formik.handleSubmit}>
          <InputWrapper>
            <Email formik={formik} />
          </InputWrapper>
          <InputWrapper>
            <Password formik={formik} />
            <PasswordStrength mode={passwordStrengthModes[0]} />
          </InputWrapper>

          <CheckboxWrapper>
            <Checkbox name="remember" formik={formik} text={"Remember me"} />
          </CheckboxWrapper>

          {formik.errors.common || error ? (
            <ErrorWrapper>
              <ErrorMessage>{formik.errors.common || error}</ErrorMessage>
            </ErrorWrapper>
          ) : null}

          <Button
            type="submit"
            fullWidth
            disabled={formik.isSubmitting}
            data-test="signin_submit_button"
            isLoading={formik.isSubmitting}
          >
            Login
          </Button>
          <LinkWrapper>
            <TextLink
              to={`/${rootPages.RESET_PASSWORD}`}
              onClick={handleForgotPasswordClick}
            >
              Forgot password?
            </TextLink>
          </LinkWrapper>
        </form>
      </LoginSignupPanel>
    </Section>
  );
};

export default SignIn;
