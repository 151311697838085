import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import { usePathname } from "../../hooks";

import Link from "../link/Link";

const NotFound = () => {
  const { amplitude } = useAmplitudeState();
  const pathname = usePathname();

  useEffect(() => {
    amplitude.logEvent(events.error_page);
  }, [pathname]);

  return (
    <Box p={5} textAlign="center">
      <h1>404 - Not Found!</h1>
      <Link to="/">Go Home</Link>
    </Box>
  );
};

export default NotFound;
