import * as yup from "yup";

const FIELD_MAX_LENGTH = 255;

const addressSchema = yup.object().shape({
  city: yup
    .string()
    .required("City is required")
    .max(
      FIELD_MAX_LENGTH,
      `City must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  address: yup
    .string()
    .required("Address is required")
    .max(
      FIELD_MAX_LENGTH,
      `Address must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  zip: yup
    .string()
    .required("ZIP code is required")
    .max(
      FIELD_MAX_LENGTH,
      `ZIP code must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  country: yup.string().required("Country is required"),
  company: yup
    .string()
    .required("Company is required")
    .max(
      FIELD_MAX_LENGTH,
      `Company must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
  phone: yup
    .string()
    .required("Phone number is required")
    .max(
      FIELD_MAX_LENGTH,
      `Phone number must not exceed ${FIELD_MAX_LENGTH} characters`
    ),
});

export default addressSchema;
