import React, {
  Context,
  createContext,
  PropsWithChildren,
  useState,
} from "react";
import { CHECKOUT_MODES } from "../model/Checkout.contants";

export const CheckoutContext: Context<{
  currentMode: CHECKOUT_MODES;
  modes: typeof CHECKOUT_MODES;
  setCurrentMode: (mode: CHECKOUT_MODES) => void;

  isReadyToConfirm: boolean;
  setIsReadyToConfirm: (isReady: boolean) => void;
}> = createContext(null);

export const CheckoutContextProvider = ({ children }: PropsWithChildren) => {
  const [currentMode, setCurrentMode] = useState<CHECKOUT_MODES>(
    CHECKOUT_MODES.CHECKOUT
  );

  const [isReadyToConfirm, setIsReadyToConfirm] = useState(false);

  const setMode = (mode: CHECKOUT_MODES) => {
    setCurrentMode(mode);
  };

  const setConfirm = (isReady: boolean) => {
    setIsReadyToConfirm(isReady);
  };

  return (
    <CheckoutContext.Provider
      value={{
        currentMode: currentMode,
        setCurrentMode: setMode,
        modes: CHECKOUT_MODES,
        isReadyToConfirm,
        setIsReadyToConfirm: setConfirm,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
