// @ts-check

import React, { useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NewsletterSubscribe } from "./NewsletterSubscribe";
import { toast } from "react-toastify";
import jsonp from "jsonp";
import common from "constants/common";
import { NewsletterToast } from "./components/NewsletterToast";
import { events, useAmplitudeState } from "context/AmplitudeContext";

const url = (email) =>
  `https://${common.MAILCHIMP_DOMAIN}/subscribe/post-json?u=${
    common.MAILCHIMP_ID
  }&id=${common.MAILCHIMP_LIST_ID}&EMAIL=${encodeURIComponent(email)}`;

const subscribeToNewsletter = (email) => {
  if (email) {
    return new Promise((res, rej) => {
      jsonp(url(email), { param: "c" }, function (err, data) {
        if (err) {
          return rej(err);
        } else if (data.result === "success") {
          return res();
        } else rej();
      });
    });
  } else {
    return Promise.reject();
  }
};

export const NewsletterSubscribeContainer = () => {
  const methods = useForm({
    defaultValues: { email: "" },
  });

  const { reset } = methods;

  const { amplitude } = useAmplitudeState();

  const submitHandler = useCallback(
    (values) => {
      subscribeToNewsletter(values?.email).then(
        () => {
          reset({ email: "" });
          amplitude.logEvent(events.subscription_email, {
            page: "Filters",
            status: "Success",
          });

          toast.success(
            <NewsletterToast
              title="You have successfully subscribed to our newsletter"
              description={
                "You've been added to our mailing list and will hear from us soon."
              }
            />
          );
        },
        () => {
          amplitude.logEvent(events.subscription_email, {
            page: "Filters",
            status: "Fail",
          });
          toast.error(
            <NewsletterToast
              title="Failed to subscribe to our newsletter"
              description="Please check your email and try again."
            />
          );
        }
      );
    },
    [amplitude]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)}>
        <NewsletterSubscribe />
      </form>
    </FormProvider>
  );
};
