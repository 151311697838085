import React from "react";
import { DrawSearchIcon } from "ui/icons";
import { SearchInputWithHint } from "./SearchInputWithHint";
import {
  Wrapper,
  StructureSearchButton,
  SectionWrapper,
} from "./SearchForm.styled";

type SearchFormProps = {
  isLoading: boolean;
  control: any;
  onStructureSearch: () => void;
  submitHandler: () => void;
  showIncludeAdditionalResults: boolean;
};

export const SearchForm = ({
  isLoading,
  control,
  onStructureSearch,
  submitHandler,
  showIncludeAdditionalResults,
}: SearchFormProps) => {
  return (
    <>
      <SectionWrapper>
        <Wrapper>
          <SearchInputWithHint
            isLoading={isLoading}
            control={control}
            onEnter={submitHandler}
            showIncludeAdditionalResults={showIncludeAdditionalResults}
          >
            <StructureSearchButton
              onClick={onStructureSearch}
              type="outlined-white"
              iconLeft={<DrawSearchIcon />}
            >
              Structure Search
            </StructureSearchButton>
          </SearchInputWithHint>
        </Wrapper>
      </SectionWrapper>
    </>
  );
};
