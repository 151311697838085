import { OvernightDeliveryMappedData } from "./RushDeliveryModal.types";
import { CartItemWithImage } from "../../../../../../../shared/types/cart";

const mapOvernightDeliveryItems = (
  type: "allowed" | "notAllowed",
  overnightDeliveryKeys: string[],
  overnightDelivery: any,
  cartItemsWithImages: CartItemWithImage[]
) => {
  if (overnightDeliveryKeys?.length) {
    return overnightDeliveryKeys.reduce((acc, next) => {
      const foundItem = cartItemsWithImages.find(
        (cartItemWithImage: CartItemWithImage) =>
          cartItemWithImage?.addedToBucket?.uuid === next
      );
      if (foundItem) {
        return [
          ...acc,
          {
            code: foundItem?.product?.code,
            amount: `${foundItem?.addedToBucket?.count} x ${foundItem?.addedToBucket?.weight?.amount} ${foundItem?.addedToBucket?.weight?.measure}`,
            deliveryTime: overnightDelivery?.[type]?.deliveryTime,
            packing: `${overnightDelivery?.[type]?.bucketItems?.[next]?.packing?.abbreviation} ${overnightDelivery?.[type]?.bucketItems?.[next]?.packing?.description}`,
          },
        ];
      }

      return acc;
    }, []);
  }

  return [];
};

export const getOvernightDeliveryData = (
  overnightDelivery: any,
  cartItemsWithImages: CartItemWithImage[]
): OvernightDeliveryMappedData => {
  const allowedBucketItemKeys = Object.keys(
    overnightDelivery?.allowed?.bucketItems || {}
  );
  const notAllowedBucketItemKeys = Object.keys(
    overnightDelivery?.notAllowed?.bucketItems || {}
  );

  return {
    allowed: mapOvernightDeliveryItems(
      "allowed",
      allowedBucketItemKeys,
      overnightDelivery,
      cartItemsWithImages
    ),
    notAllowed: mapOvernightDeliveryItems(
      "notAllowed",
      notAllowedBucketItemKeys,
      overnightDelivery,
      cartItemsWithImages
    ),
  };
};
