import React from "react";
import { useHistory } from "react-router";
import { encode, makeParams } from "../../utils/index";

import SearchCard from "./SearchCard";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import { searchAsTypes } from "../../constants";

type SearchCardContainerProps = {
  title: string;
  cat: string;
  q: string;
  type: string;
  sstype: searchAsTypes;
  drawSearch: string;
  dataTestAttr: string;
  searchBy?: string;
  inclusions?: string;
};

const SearchCardContainer = ({
  cat,
  q,
  type,
  sstype,
  title,
  drawSearch,
  dataTestAttr,
  searchBy,
  inclusions,
}: SearchCardContainerProps) => {
  const history = useHistory();
  const { amplitude } = useAmplitudeState();

  const onHandleClick = () => {
    amplitude.logEvent(events.search_category, { category_type: cat });

    const queryParams = makeParams({
      q: encode(q),
      cat,
      ...(!searchBy && {
        type,
        sstype,
        drawSearch,
      }),
      searchBy,
      inclusions,
    });

    history.push(`/catalog?${queryParams}`);
  };

  return (
    <SearchCard
      title={title}
      isExists={true}
      isLoading={false}
      handleClick={onHandleClick}
      dataTestAttr={dataTestAttr}
    />
  );
};

export default SearchCardContainer;
