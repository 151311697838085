export const SET_DATA = "CHECKOUT/SET_DATA";
export const CLOSE_ACCORDION = "CHECKOUT/CLOSE_ACCORDION";
export const OPEN_ACCORDION = "CHECKOUT/OPEN_ACCORDION";
export const SET_INITIAL = "CHECKOUT/SET_INITIAL";
export const CHANGE_INPUT = "CHANGE_INPUT";

export const SET_ADDITIONAL_COSTS = "CHECKOUT/SET_ADDITIONAL_COSTS";
export const SET_ADDITIONAL_COSTS_LOADING =
  "CHECKOUT/SET_ADDITIONAL_COSTS_LOADING";
export const SET_ADDITIONAL_COSTS_ERROR = "CHECKOUT/SET_ADDITIONAL_COSTS_ERROR";
export const REQUEST_ADDITIONAL_COSTS = "CHECKOUT/REQUEST_ADDITIONAL_COSTS";

export const SET_PAYMENT_METHODS = "CHECKOUT/SET_PAYMENT_METHODS";
export const SET_PAYMENT_METHODS_LOADING =
  "CHECKOUT/SET_PAYMENT_METHODS_LOADING";
export const SET_PAYMENT_METHODS_ERROR = "CHECKOUT/SET_PAYMENT_METHODS_ERROR";

export const REQUEST_PAYMENT_METHODS = "CHECKOUT/REQUEST_ADDITIONAL_COSTS";

// V2
// @TODO Check if we have to use one updated or split it partially like SET_SHIPPING_PERSON_UUID
export const SET_REQUEST_DATA = "CHECKOUT/SET_REQUEST_DATA";

export const REQUEST_CALCULATIONS = "CHECKOUT/REQUEST_CALCULATIONS";
export const REQUEST_PROMO_CODE_CALCULATIONS =
  "CHECKOUT/REQUEST_PROMO_CODE_CALCULATIONS";
export const REQUEST_CONDITIONS_BY_SHIPPING_ADDRESS =
  "CHECKOUT/REQUEST_CONDITIONS_BY_SHIPPING_ADDRESS";

export const SET_CALCULATIONS = "CHECKOUT/SET_CALCULATIONS";

export const SET_CONDITIONS_BY_SHIPPING_ADDRESS =
  "CHECKOUT/SET_CONDITIONS_BY_SHIPPING_ADDRESS";

export const SET_SHIPPING_PERSON_UUID = "CHECKOUT/SET_SHIPPING_PERSON_UUID";
export const SET_BILLING_PERSON_UUID = "CHECKOUT/SET_BILLING_PERSON_UUID";

export const SET_ADDRESS_SHIPPING_UUID = "CHECKOUT/SET_ADDRESS_SHIPPING_UUID";
export const SET_ADDRESS_BILLING_UUID = "CHECKOUT/SET_ADDRESS_BILLING_UUID";
