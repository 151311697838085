import styled from "styled-components";
import { accentColor } from "../../../../../../ui/colors";

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  margin-bottom: 18px;
  padding: 8px 0;
`;

export const Step = styled.div<{
  active: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
    align-items: center;
    &:first-child {
        align-items: flex-start;
    }
    &:last-child {
        align-items: flex-end;
    }
  }
`;

export const StepLabel = styled.div`
  color: ${({ active }) => (active ? accentColor : "#51607A")};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

export const StepLine = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  margin-bottom: 8px;
  background-color: #d0e2ff;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  &:before {
    content: "";
    position: absolute;
    width: ${({ filledPercent }) => `${filledPercent}%`};
    height: 100%;
    background: ${accentColor};
    border-radius: 8px;
  }
`;
