import { ProductTypesV2 } from "constants/productTypes";
import { AvailabilityStatus } from "shared/types/delivery";
import { PriceDetails } from "shared/types/pricing";

type GetButtonLabelProps = {
  catalog: ProductTypesV2;
  currentPrice: PriceDetails;
};

const getButtonLabel = ({ catalog, currentPrice }: GetButtonLabelProps) => {
  if (catalog === ProductTypesV2.MADE || catalog === ProductTypesV2.REALDB) {
    return "Add to cart";
  }

  const isInStock =
    currentPrice?.delivery?.available === AvailabilityStatus.IN_STOCK;

  return isInStock ? "Add to cart" : "Synthesis";
};

export default getButtonLabel;
