import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getValidationSchema } from "./utils";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../../redux/checkout";
import AdditionalInfo from "./AdditionalInfo";

const AdditionalInfoContainer = () => {
  const dispatch = useDispatch();
  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);

  const formik = useFormik({
    initialValues: {
      purchaseOrderNumber: checkoutRequestData?.purchaseOrderNumber,
      comment: checkoutRequestData?.comment,
    },
    validationSchema: getValidationSchema(),
    onSubmit: () => {},
  });

  useEffect(
    () => {
      const updatedCheckoutRequestData = {
        ...checkoutRequestData,
        ...formik.values,
      };
      dispatch(
        checkoutActions.setRequestData({ data: updatedCheckoutRequestData })
      );
    },
    // eslint-disable-next-line
    [dispatch, formik.values]);

  return <AdditionalInfo formik={formik} />;
};

export default AdditionalInfoContainer;
