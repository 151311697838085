import React from "react";
import {
  Wrapper,
  Image,
  Row,
  Info,
  InfoWrapper,
  Title,
  CaptionWrapper,
  TitleWrapper,
} from "./CheckoutCartItem.styled";
import { CustomizedTooltip } from "../../../../../../ui";
import { DangerMarkIcon } from "../../../../../../ui/icons";

type CheckoutCartItemProps = {
  hasNoPrice: boolean;
  title: string;
  image: string;
  count: string;
  amount: string;
  price: string;
  isDangerousGood: boolean;
};

const CheckoutCartItem = ({
  hasNoPrice,
  title,
  image,
  count,
  amount,
  price,
  isDangerousGood,
}: CheckoutCartItemProps) => {
  return (
    <Wrapper>
      <Image style={{ backgroundImage: `url(${image})` }} />

      <InfoWrapper>
        <TitleWrapper>
          <Title variant="h3">{title}</Title>
          {isDangerousGood && (
            <CustomizedTooltip
              title="This compounds is considered a Dangerous good for shipping.
Our team will contact you with details on lead time and costs "
              placement="bottom"
              fullWidth
              tooltipStyleOverrides={{ maxWidth: "350px" }}
            >
              <DangerMarkIcon />
            </CustomizedTooltip>
          )}
        </TitleWrapper>

        <Info>
          <Row>{`${count} x ${amount} ${price ? "x " + price : ""}`}</Row>
          {hasNoPrice && (
            <CaptionWrapper>
              Unfortunately there's no price available for this product right
              now. You can proceed with your order and our team will contact you
              with pricing details.
            </CaptionWrapper>
          )}
        </Info>
      </InfoWrapper>
    </Wrapper>
  );
};

export default CheckoutCartItem;
