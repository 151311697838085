import { useDispatch } from "react-redux";
import { events, useAmplitudeState } from "../context/AmplitudeContext";
import { fetchLogout } from "../redux/user/api";
import { userActions } from "../redux/user";

const useHandleLogout = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(
      userActions.logout({
        redirectTo: "/",
        cb: async () => {
          amplitude.logEvent(events.logout);
          await fetchLogout();
        },
      })
    );
  };

  return handleLogout;
};

export default useHandleLogout;
