import styled from "styled-components";
import { lineColor } from "ui/colors";

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  & > img {
    width: 120px;
    height: auto;
  }
`;

export const OrderItemsWrapper = styled.div`
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const OrderItemWrapper = styled.div`
  border: 1px solid ${lineColor};
  border-bottom: none;
  &:last-child {
    border: 1px solid ${lineColor};
  }
`;
