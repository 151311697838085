import fetchImage from "../../../../utils/fetch/fetchImage";
import { ProductDTO } from "../../../../typings/DTO/data-contracts";

export const fetchProductImages = async (products: ProductDTO[]) => {
  const imageFetchers = products?.map((product) =>
    fetchImage({
      code: product.code,
      catalog: product.catalog as any,
    })
  );

  if (imageFetchers?.length) {
    const imagesResponse = await Promise.all(imageFetchers);

    return imagesResponse.map((imageItem: any) => ({
      url: imageItem[0].image || "/not-found-product.png",
    }));
  }

  return [];
};
