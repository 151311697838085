import { BucketItem } from "shared/types/cartItem";

const getPricePerOneCount = (item, weightAmount) => {
  return item.prices.all.find(
    (priceItem) => priceItem.weight.amount === weightAmount
  ).price;
};

export const prepareUpdatedItem = (item: BucketItem, propertyToUpdate) => {
  if (propertyToUpdate === "count") {
    return {
      ...item,
      addedToBucket: {
        ...item.addedToBucket,
        pay: {
          ...item.addedToBucket.pay,
          total:
            item.addedToBucket.count * item.addedToBucket.pay.pricePerOneCount,
        },
      },
    };
  } else if (propertyToUpdate === "weight") {
    const pricePerOneCount = getPricePerOneCount(
      item,
      item.addedToBucket.weight.amount
    );
    return {
      ...item,
      addedToBucket: {
        ...item.addedToBucket,
        pay: {
          ...item.addedToBucket.pay,
          pricePerOneCount: pricePerOneCount,
          total: item.addedToBucket.count * pricePerOneCount,
        },
      },
    };
  }
  return item;
};
