import React from "react";
import DialogCore from "shared/ui/DialogCore/DialogCore";
import { ButtonWrapper } from "./DeleteDialog.styled";
import { Button } from "ui";

type DeleteDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  handleDelete: () => void;
};

const DeleteDialog = ({
  open,
  setOpen,
  title,
  handleDelete,
}: DeleteDialogProps) => {
  return (
    <DialogCore
      open={open}
      onClose={() => setOpen(false)}
      title={title}
      maxWidth="sm"
    >
      <ButtonWrapper>
        <Button
          onClick={() => setOpen(false)}
          size="small"
          type="outlined"
          dataTestAttr="cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            handleDelete();
          }}
          type="outlined-danger"
          size="small"
          dataTestAttr="delete-button"
        >
          Delete
        </Button>
      </ButtonWrapper>
    </DialogCore>
  );
};

export default DeleteDialog;
