import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";

import { endpoints, rootPages, searchAsTypesEvents } from "../../constants";
import { useQuery, useLocalStorage } from "../../hooks";
import { encode, customFetch, makeParams } from "../../utils";

import MarvinFrame from "./MarvinFrame";
import { marvinImageSizes, marvinImageStorage } from "features/marvin-image";
import { events, useAmplitudeState } from "../../context/AmplitudeContext";
import { ConvertedSmartFromMrv } from "./types";
import MarvinFrameForm from "./MarvinFrameForm";
import { catalogActions } from "../../redux/catalog";
import { useDispatch } from "react-redux";

const MarvinFrameContainer = ({ url, width, height }) => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const query = useQuery();
  const searchPhrase = query.get("q");
  const [sketcher, setSketcher] = useState(null);
  const [smartCode, saveSmartCode] = useLocalStorage("marvin_smart");
  const imageExporter = useRef(null);
  const dispatch = useDispatch();

  const onLoad = (e) => {
    const marvinWindow = e.target.contentWindow.marvin;

    marvinWindow.onReady(() => {
      imageExporter.current = marvinWindow.ImageExporter;
      setSketcher(marvinWindow.sketcherInstance);
    });
  };

  const handleFormSubmit = async (values) => {
    const res = await sketcher.exportStructure("mrv");

    const [convertedSmartFromMrv] = await customFetch<ConvertedSmartFromMrv>({
      url: `${endpoints.UTIL_CONVERT_FROM_MRV}`,
      method: "post",
      data: res,
      config: {
        headers: {
          "Content-Type": "text/xml",
        },
      },
    });

    if (convertedSmartFromMrv && convertedSmartFromMrv.data) {
      const encodedPhrase = encode(convertedSmartFromMrv.data);
      saveSmartCode(convertedSmartFromMrv.data);

      const image = imageExporter.current.mrvToDataUrl(
        res,
        "image/png",
        marvinImageSizes
      );
      marvinImageStorage.setItem(convertedSmartFromMrv.data, image);

      const queryParams = makeParams({
        q: encodedPhrase,
        drawSearch: true,
        sstype: values.sstype,
        ...(values.includeAdditionalResults && {
          inclusions: values.includeAdditionalResults,
        }),
      });

      dispatch(catalogActions.setCatalogFilters(values));

      if (values.includeAdditionalResults) {
        amplitude.logEvent(events.include_salts);
      }

      amplitude.logEvent(events.search, {
        type: searchAsTypesEvents[values.sstype],
        query: convertedSmartFromMrv.data,
      });

      history.push(`/${rootPages.SEARCH}?${queryParams}`);
    }
  };

  useEffect(() => {
    if (searchPhrase && sketcher) {
      sketcher.importStructure("MRV", searchPhrase);
    } else if (smartCode && sketcher) {
      sketcher.importStructure("MRV", smartCode);
    }
  }, [searchPhrase, sketcher, smartCode]);

  return (
    <>
      <MarvinFrame url={url} width={width} height={height} onLoad={onLoad} />
      <MarvinFrameForm onSubmit={handleFormSubmit} />
    </>
  );
};

export default MarvinFrameContainer;
