import { toast } from "react-toastify";
import { all, call, put, select } from "redux-saga/effects";
import {
  fetchCarriers,
  fetchCatalogs,
  fetchCatalogWeights,
  fetchCountries,
  fetchImages,
  fetchUsStates,
  fetchVatPatterns,
} from "./api";
import { utilActions } from "./index";
import { GeoGroup } from "./types";

export function* requestUtils() {
  const state = yield select();

  const config = {
    ...(!state.util.countries && {
      countries: {
        request: call(fetchCountries),
        action: utilActions.setCountries,
      },
    }),
    ...(!state.util.usStates && {
      usStates: {
        request: call(fetchUsStates),
        action: utilActions.setUsStates,
      },
    }),
    ...(!state.util.vatPatterns && {
      vatPatterns: {
        request: call(fetchVatPatterns, GeoGroup.ValidatableVAT),
        action: utilActions.setVatPatterns,
      },
    }),
    ...(!state.util.carriers && {
      carriers: {
        request: call(fetchCarriers),
        action: utilActions.setCarriers,
      },
    }),
    ...(!state.util.catalogs && {
      catalogs: {
        request: call(fetchCatalogs),
        action: utilActions.setCatalogs,
      },
    }),
    ...(!state.util.catalogWeights && {
      catalogWeights: {
        request: call(fetchCatalogWeights),
        action: utilActions.setCatalogWeights,
      },
    }),
  };

  try {
    const utilsResponses = yield all(
      Object.keys(config).map((key) => config[key].request)
    );

    for (let i = 0; i < Object.keys(config).length; i++) {
      const key = Object.keys(config)[i];
      const [data, errorMsg] = utilsResponses[i];
      if (data) {
        yield put(config[key].action(data));
      } else {
        toast.error(errorMsg);
      }
    }
  } catch (e) {
    console.log("Something went wrong! Utils", e);
  }
}

export function* requestImages({ payload }) {
  try {
    const state = yield select();
    const storeImages = state.util.images;
    const items = [];

    payload.forEach((el) => {
      const image = storeImages?.find((item) => item.code === el.code);

      if (!image) {
        items.push(el);
      }
    });

    if (items.length) {
      const result = yield call(() => fetchImages({ items }));

      yield put(utilActions.setImages(result));
    }
  } catch (e) {
    console.log(e);
    console.log("Something went wrong!");
  }
}
