import React from "react";
import {
  Item,
  SearchCategoriesTitle,
  Wrapper,
} from "./SearchCategories.styled";
import { SearchCard } from "../index";
import { searchCategoriesOrder } from "../../constants";

const SearchCategories = ({
  list,
  q,
  type,
  theme,
  cat,
  sstype,
  drawSearch,
  searchBy,
  inclusions,
}) => {
  return (
    <Wrapper>
      <SearchCategoriesTitle>
        Choose a category to proceed:
      </SearchCategoriesTitle>
      {list?.length ? (
        <>
          {searchCategoriesOrder.map((item) => {
            const el = list.find((listItem) => listItem.key === item);
            const dataTestAttr = `category_${item.toLowerCase()}_button`;

            return (
              <Item key={el.key}>
                <SearchCard
                  title={el.title}
                  cat={el.key}
                  currentCat={cat}
                  q={q}
                  type={type}
                  sstype={sstype}
                  theme={theme}
                  drawSearch={drawSearch}
                  dataTestAttr={dataTestAttr}
                  searchBy={searchBy}
                  inclusions={inclusions}
                />
              </Item>
            );
          })}
        </>
      ) : null}
    </Wrapper>
  );
};

export default SearchCategories;
