import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { blue, darkBlue } from "../../../../../../../ui/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  word-break: break-all;
  color: ${blue};
`;

export const Title = styled(Typography)`
  max-width: 250px;
  font-weight: 600;
  font-size: 16px;
  margin-right: 8px;
  color: ${darkBlue};
`;

export const DescriptionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const Image = styled.div`
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
  margin-right: 18px;
`;

export const Row = styled.div`
  min-width: 200px;
`;
