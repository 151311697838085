import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 28px;
  &.paper {
    &--marginBottom {
      margin-bottom: 28px;
    }

    &--scrollableX {
      max-width: 100%;
      overflow-x: auto;
    }

    &--smallPaddings {
      padding: 24px;
    }
  }
`;
