import styled from "styled-components";
import { Box } from "@mui/material";

export const CarrierImageWrapper = styled(Box)`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  & > * {
    height: 100%;
    max-width: 60px;
  }
`;

export const CarrierLabelWrapper = styled(Box)`
  margin-bottom: 2px;
`;
