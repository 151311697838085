import { AddressEntity } from "../types/user";

export const combineAddressInfo = (
  address: AddressEntity,
  // @TODO Specify types
  countries: any[],
  usStates: any[]
) => {
  return address
    ? `
        ${address.country ? countries?.[address.country] || "" : ""}, ${
        address.usaState ? usStates?.[address.usaState] + "," : ""
      } ${address.city} ${address?.address}, ${address.zip},
        ${address.phone}, ${address.company}${
        address.europeVAT ? ", " + address.europeVAT : ""
      }
        `
    : "";
};
