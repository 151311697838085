import { Box, IconButton } from "@mui/material";
import styled from "styled-components";
import { accentColor, borderColor, darkBlue } from "ui/colors";

export const CardContainer = styled.li`
  border: 1px solid ${borderColor};
  border-radius: 8px;
  padding: 20px 28px;
  max-width: 100%;
  & > * {
    margin-bottom: 12px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 28px;
`;

export const FullWidthBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.span`
  font-size: 13px;
  line-height: 24px;
  color: ${darkBlue};
`;

export const Value = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue};
  word-break: break-all;
`;

export const IconColumn = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
`;

export const StyledButton = styled(IconButton)`
  padding: 0;
  &:hover {
    background-color: transparent;
    svg {
      path {
        fill: ${accentColor};
      }
    }
  }
`;
