import styled from "styled-components";
import { accentColor, darkBlue, lineColor } from "../../../../../ui/colors";
import { Box } from "@mui/material";

export const Content = styled.div``;

export const List = styled.div``;

export const ListItem = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${lineColor};
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${darkBlue};
`;

export const Scrollable = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const InfoCell = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  svg {
    path {
      fill: ${darkBlue};
    }

    &:hover {
      path {
        fill: ${accentColor};
      }
    }
  }
`;
