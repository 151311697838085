import { ProductWeight } from "../../../../../shared/types/product";

export type RestrictedCartItem = {
  code: string;
  image: { url: string };
  quantity: string;
  weight: ProductWeight;
  // @TODO Probably we have to specify type
  restrictionReasons: string[];
  price: string;
  currency: string;
};

export enum RestrictionReasons {
  NOT_ALLOWED_FOR_USA_BY_DEA = "Restricted by US customs regulations.",
  NOT_ALLOWED_FOR_USA_CALIFORNIA_BY_EX65 = "Restricted by California Proposition 65 list.",
}
