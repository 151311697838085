import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CheckOrder from "./CheckOrder";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../redux/checkout";
import { utilSelectors } from "../../../../../redux/util";
import { userSelectors } from "../../../../../redux/user";
import { CheckoutContext } from "../../../lib/Checout.context";
import { CHECKOUT_MODES } from "../../../model/Checkout.contants";

const CheckOrderContainer = () => {
  const dispatch = useDispatch();
  const { setCurrentMode } = useContext(CheckoutContext);
  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);

  const contactPersonsWithUserProfile = useSelector(
    userSelectors.selectContactPersonsWithUserProfile
  );
  const addresses = useSelector(userSelectors.selectAddresses);

  // @TODO Duplicated code below, optimise logic
  const contactPersonShippingInfo = useMemo(() => {
    const contactPerson =
      contactPersonsWithUserProfile.find((contactPerson) => {
        return (
          contactPerson.uuid === checkoutRequestData?.persons?.shippingUuid
        );
      }) || contactPersonsWithUserProfile[0];

    return contactPerson
      ? `
     ${contactPerson?.title ? contactPerson?.title + ". " : ""}${
          contactPerson.firstName
        } ${contactPerson?.lastName}, ${contactPerson.email},
       ${contactPerson.phone}, ${contactPerson.company}
       `
      : "";
  }, [
    contactPersonsWithUserProfile,
    checkoutRequestData?.persons?.shippingUuid,
  ]);
  // @TODO Duplicated code below, optimise logic
  const contactPersonBillingInfo = useMemo(() => {
    const contactPerson =
      contactPersonsWithUserProfile.find((contactPerson) => {
        return contactPerson.uuid === checkoutRequestData?.persons?.billingUuid;
      }) || contactPersonsWithUserProfile[0];

    return contactPerson
      ? `
     ${contactPerson?.title ? contactPerson?.title + ". " : ""}${
          contactPerson.firstName
        } ${contactPerson?.lastName}, ${contactPerson.email},
       ${contactPerson.phone}, ${contactPerson.company}
       `
      : "";
  }, [
    contactPersonsWithUserProfile,
    checkoutRequestData?.persons?.billingUuid,
  ]);
  // @TODO Duplicated code below, optimise logic
  const billingAddressInfo = useMemo(() => {
    const billingAddress = addresses.find((address) => {
      return address.uuid === checkoutRequestData?.addresses?.billingUuid;
    });

    return billingAddress
      ? `
        ${countries?.[billingAddress?.country]}, ${
          billingAddress?.usaState
            ? usStates?.[billingAddress.usaState] + ","
            : ""
        } ${billingAddress.city} ${billingAddress?.address}, ${
          billingAddress.zip
        },
        ${billingAddress.phone}, ${billingAddress.company}
        ${billingAddress.europeVAT ? "," + billingAddress.europeVAT : ""}
        `
      : "";
  }, [
    addresses,
    checkoutRequestData?.addresses?.billingUuid,
    countries,
    usStates,
  ]);
  // @TODO Duplicated code below, optimise logic
  const shippingAddressInfo = useMemo(() => {
    const shippingAddress = addresses.find((address) => {
      return address.uuid === checkoutRequestData?.addresses?.shippingUuid;
    });

    return shippingAddress
      ? `
        ${countries?.[shippingAddress?.country]}, ${
          shippingAddress?.usaState
            ? usStates?.[shippingAddress.usaState] + ","
            : ""
        } ${shippingAddress.city} ${shippingAddress?.address}, ${
          shippingAddress.zip
        },
        ${shippingAddress.phone}, ${shippingAddress.company} ${
          shippingAddress.europeVAT ? "," + shippingAddress.europeVAT : ""
        }
        `
      : "";
  }, [
    addresses,
    checkoutRequestData?.addresses?.shippingUuid,
    countries,
    usStates,
  ]);

  const shippingOptionsInfo = useMemo(() => {
    return `${
      checkoutRequestData?.shippingService?.name ||
      checkoutRequestData?.shippingService?.title
    },
    ${
      checkoutRequestData?.shippingService?.carrierId
        ? "Carrier ID:" + checkoutRequestData?.shippingService?.carrierId + ","
        : ""
    }
    
    ${
      checkoutRequestData?.partialShipping
        ? "Partial Shipment"
        : "Standard Shipment"
    },
    
    ${
      checkoutRequestData?.overnightDelivery
        ? "Rush Delivery (1-3 days)"
        : "Standard Delivery (2-14 days)"
    }`;
  }, [checkoutRequestData]);

  const paymentMethodInfo = useMemo(() => {
    const paymentMethodKey = checkoutRequestData?.paymentMethod;
    switch (paymentMethodKey) {
      case "WIRE":
        return "Wire transfer";
      case "CARD":
        return "Bank card";
      case "CHECK":
        return "Check";
      default:
        return "";
    }
  }, [checkoutRequestData?.paymentMethod]);

  const handleEditStep = (stepKey: string) => {
    setCurrentMode(CHECKOUT_MODES.CHECKOUT);
    dispatch(checkoutActions.openAccordion(stepKey));
  };

  useEffect(() => {
    window?.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <CheckOrder
      contactPersonShippingInfo={contactPersonShippingInfo}
      contactPersonBillingInfo={contactPersonBillingInfo}
      billingAddressInfo={billingAddressInfo}
      shippingAddressInfo={shippingAddressInfo}
      shippingOptionsInfo={shippingOptionsInfo}
      paymentMethodInfo={paymentMethodInfo}
      handleEditStep={handleEditStep}
    />
  );
};

export default CheckOrderContainer;
