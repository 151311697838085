import React from "react";
import { OrderItemsWrapper, OrderItemWrapper } from "./OrderItems.styled";
import OrderItem from "./OrderItem/OrderItem";
import { OrderItemEntity } from "../../lib/Order.types";

type OrderItemsProps = {
  orderItems: OrderItemEntity[];
  currency: string;
};

const OrderItems = ({ orderItems, currency }: OrderItemsProps) => {
  return (
    <OrderItemsWrapper>
      {Boolean(orderItems?.length) &&
        orderItems.map((orderItem, index) => {
          return (
            <OrderItemWrapper key={index}>
              <OrderItem orderItem={orderItem} currency={currency} />
            </OrderItemWrapper>
          );
        })}
    </OrderItemsWrapper>
  );
};

export default OrderItems;
