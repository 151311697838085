import React from "react";

import {
  Wrapper,
  Image,
  Code,
  InfoWrapper,
  ImageWrapper,
  ProductInfo,
  ProductInfoItem,
  InfoChipWrapper,
} from "./CatalogListCard.styled";
import Spinner from "../../spinner/Spinner";
import { Box, Chip } from "@mui/material";
import { NormalizedCatalogName } from "../../../constants/productTypes";
import BeautifyValue from "../../../utils/beautifyValue";
import CatalogListTable from "./CatalogListTable/CatalogListTable";

export const CatalogListCard = ({
  catalogItem,
  image,
  code,
  handleClick,
  href,
  isAddingToCart,
  currency,
  handleAddListItemToCart,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        {image ? (
          <Image
            style={{ backgroundImage: `url(${image?.data || image?.url})` }}
            onClick={handleClick}
            to={href}
          />
        ) : (
          <Spinner size={36} isLoading />
        )}
        <InfoChipWrapper>
          {catalogItem?.inclusion?.bindWith && (
            <Chip
              label={`SALT OF ${catalogItem?.inclusion?.bindWith}`}
              size="small"
              color="primary"
            />
          )}
        </InfoChipWrapper>
      </ImageWrapper>
      <InfoWrapper>
        <Code to={href} onClick={handleClick}>
          {code}
        </Code>
        <ProductInfo>
          <ProductInfoItem>
            <Box>Product class</Box>
            <Box>{NormalizedCatalogName[catalogItem?.product?.catalog]}</Box>
          </ProductInfoItem>
          <ProductInfoItem>
            <Box>Name</Box>
            <Box>{catalogItem?.product?.name}</Box>
          </ProductInfoItem>
          <ProductInfoItem>
            <Box>Formula</Box>
            <Box>{BeautifyValue("formula", catalogItem?.product?.formula)}</Box>
          </ProductInfoItem>
        </ProductInfo>

        <CatalogListTable
          catalogItem={catalogItem}
          currency={currency}
          handleAddListItemToCart={handleAddListItemToCart}
          isAddingToCart={isAddingToCart}
        />
      </InfoWrapper>
    </Wrapper>
  );
};
