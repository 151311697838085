import React, { useEffect, useState } from "react";
import {
  Content,
  Header,
  InfoCell,
  Title,
} from "../CartItemsList/CartItemsList.styled";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../redux/checkout";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircleCheckIcon, CrossIcon } from "../../../../../ui/icons";
import IconButton from "@mui/material/IconButton";
import { TitleWrapper } from "./PromoCode.styled";
import LikeLinkButton from "../../../../../components/like-link-button/LikeLinkButton";

import { PromoCodeRejectionReasonsMessages } from "../../../model/PromoCode.constants";
import { currencySelectors } from "../../../../../redux/currency";
import currencies from "../../../../../constants/currencies";
import {
  events,
  useAmplitudeState,
} from "../../../../../context/AmplitudeContext";

const PromoCode = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const checkoutCalculations = useSelector(
    checkoutSelectors.selectCalculations
  );
  const currency = useSelector(currencySelectors.selectCurrent);

  const [addPromoCodeVisible, setAddPromoCodeVisible] = useState(false);

  const onSuccess = () => {
    toast.success("Promo code successfully applied", {
      icon: <CircleCheckIcon />,
      autoClose: 5000,
    });
  };

  const formik = useFormik({
    initialValues: {
      promoCode: "",
    },
    onSubmit: (formValues) => {
      amplitude.logEvent(events.promocode_apply, {
        code: formValues.promoCode,
      });

      dispatch(
        checkoutActions.requestPromoCodeCalculations({
          promoCode: formValues,
          onSuccess,
        })
      );
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const handleAddPromoCode = () => {
    setAddPromoCodeVisible(true);
    amplitude.logEvent(events.promocode_add);
  };

  useEffect(
    () => {
      const promoCode = checkoutCalculations?.pay?.promoCode;
      const currencySymbol = currency?.symbol || currencies["USD"]?.symbol;

      if (!promoCode?.applied && promoCode?.rejection?.reason) {
        const errorMessage =
          typeof PromoCodeRejectionReasonsMessages[
            promoCode?.rejection?.reason
          ] === "string"
            ? PromoCodeRejectionReasonsMessages[promoCode?.rejection?.reason]
            : PromoCodeRejectionReasonsMessages[promoCode?.rejection?.reason](
                promoCode.rejection.limit,
                currencySymbol
              );

        formik.setFieldError("promoCode", errorMessage);
      }
    },
    // eslint-disable-next-line
    [checkoutCalculations?.pay?.promoCode]);

  return (
    <>
      <Header
        sx={{
          marginBottom: `${addPromoCodeVisible ? "10px" : "0"}  !important`,
        }}
      >
        <InfoCell>
          <TitleWrapper>
            <Title>Promo Code</Title>
            {!addPromoCodeVisible && (
              <LikeLinkButton onClick={handleAddPromoCode}>Add</LikeLinkButton>
            )}
          </TitleWrapper>
        </InfoCell>
      </Header>
      {addPromoCodeVisible && (
        <Content>
          <TextField
            fullWidth
            id="promoCode"
            name="promoCode"
            placeholder="Enter promo code"
            value={formik.values.promoCode}
            onChange={formik.handleChange}
            error={formik.touched.promoCode && Boolean(formik.errors.promoCode)}
            helperText={formik.touched.promoCode && formik.errors.promoCode}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      aria-label={"remove value"}
                      onClick={() => {
                        formik.setFieldValue("promoCode", "");
                        handleSubmit();
                      }}
                      edge="end"
                      size="small"
                    >
                      {formik.values.promoCode && (
                        <CrossIcon style={{ width: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            inputProps={{ maxLength: 40 }}
          />

          {!checkoutCalculations?.pay?.promoCode?.applied &&
            formik.values.promoCode && (
              <Button
                disabled={!formik.values.promoCode}
                fullWidth
                variant="contained"
                sx={{ marginTop: "18px" }}
                onClick={handleSubmit}
              >
                Apply
              </Button>
            )}
        </Content>
      )}
    </>
  );
};

export default PromoCode;
