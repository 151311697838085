import { ContactPersonEntity } from "../types/user";

export const combinePersonInfo = (contactPerson: ContactPersonEntity) => {
  return `
        ${contactPerson?.title ? contactPerson?.title + ". " : ""}${
    contactPerson.firstName
  } ${contactPerson?.lastName}, ${contactPerson.email},
        ${contactPerson.phone}, ${contactPerson.company}
        `;
};
