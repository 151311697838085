import styled from "styled-components";
import { Box, Chip, Typography } from "@mui/material";

export const PageWrapper = styled.div`
  padding-bottom: 140px;
  padding-top: 30px;
`;

export const OrderCode = styled.div`
  display: inline;
  font-weight: 600;
`;

export const SectionWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 28px;
  min-height: 150px;
`;

export const SectionContent = styled(Box)`
  margin-top: 18px;
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
`;

export const Subtitle = styled(Typography)`
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
`;

export const LoaderWrapper = styled(Typography)`
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderTypesWrapper = styled(Box)`
  display: flex;
  gap: 12px;
`;

export const OrderTypeChip = styled(Chip)`
  text-transform: uppercase;
  font-weight: 500;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TrackingNumbersWrapper = styled(Typography)`
  display: inline;
  font-weight: 500;
`;
