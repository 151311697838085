import React, { useEffect } from "react";
import { useAmplitudeState } from "../../../../../../context/AmplitudeContext";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors } from "../../../../../../redux/user";
import {
  checkoutActions,
  checkoutSelectors,
} from "../../../../../../redux/checkout";
import AddressesContent from "../AddressesContent/AddressesContent";
import { AmplitudeEvents } from "../../../../../../context/AmplitudeEvents";

const StepAddressShippingContainer = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const addresses = useSelector(userSelectors.selectAddresses);

  const selectedAddressShippingUuid = useSelector(
    checkoutSelectors.selectAddressShippingUuid
  );

  const handleChangeAddressShippingUuid = (addressUuid?: string) => {
    dispatch(checkoutActions.setAddressShippingUuid(addressUuid));
  };

  const handleNextStep = () => {
    dispatch(checkoutActions.requestConditionsByShippingAddress());
    amplitude.logEvent(AmplitudeEvents.Checkout.CHECKOUT_SHIPPING);
  };

  useEffect(
    () => {
      if (!selectedAddressShippingUuid && addresses?.length) {
        dispatch(checkoutActions.setAddressShippingUuid(addresses?.[0]?.uuid));
      }
    },
    // eslint-disable-next-line
  [addresses, selectedAddressShippingUuid]);

  return (
    <AddressesContent
      type={"Shipping"}
      selectedAddressUuid={selectedAddressShippingUuid}
      addresses={addresses}
      onChangeBillingAddressUuid={handleChangeAddressShippingUuid}
      onNextStep={handleNextStep}
    />
  );
};

export default StepAddressShippingContainer;
