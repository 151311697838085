import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAmplitudeState } from "context/AmplitudeContext";
import { checkoutActions, checkoutSelectors } from "redux/checkout";
import { BankCardsIcon, BankIcon, MoneyIcon } from "ui/icons";

import Payment from "./Payment";
import {
  DefaultPaymentMethod,
  PaymentMethodIds,
} from "../../../../model/Checkout.types";
import { AmplitudeEvents } from "../../../../../../context/AmplitudeEvents";
import { CanNotCalculateReasons } from "../../../../../../shared/types/Orders.types";

const defaultPaymentMethods: DefaultPaymentMethod[] = [
  {
    id: PaymentMethodIds.WIRE,
    default: true,
    title: "Wire transfer",
    text: "When selecting this payment method make sure that the Purchase Order number is filled during final step of the checkout to help speed up the processing.",
    icon: <BankIcon />,
    disabled: false,
  },
  {
    id: PaymentMethodIds.CARD,
    title: "Bank card",
    text: "Enamine does not keep credit card numbers used for purchase. This information is transferred directly to the processing agency and cannot be accessed by Enamine.",
    icon: <BankCardsIcon />,
    disabled: false,
  },
  {
    id: PaymentMethodIds.CHECK,
    default: true,
    title: "Check",
    text: "When selecting this payment method make sure that the Purchase Order number is filled during final step of the checkout to help speed up the processing.",
    icon: <MoneyIcon />,
    disabled: false,
  },
];

const StepPaymentContainer = () => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  // const checkoutData = useSelector(checkoutSelectors.selectData);

  const checkoutRequestData = useSelector(checkoutSelectors.selectRequestData);
  const checkoutCalculations = useSelector(
    checkoutSelectors.selectCalculations
  );
  const paymentMethodsAvailable = useSelector(
    checkoutSelectors.selectCalculatedPaymentMethods
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodIds>(PaymentMethodIds.WIRE);

  const [paymentMethods, setPaymentMethods] = useState(
    defaultPaymentMethods.filter((item) => item.default)
  );

  const handleSubmit = () => {
    dispatch(
      checkoutActions.setRequestData({
        data: {
          paymentMethod: selectedPaymentMethod,
        },
      })
    );
    dispatch(checkoutActions.closeAccordion("step6"));

    amplitude.logEvent(AmplitudeEvents.Payment.CHECKOUT_PAYMENT, {
      parameter: selectedPaymentMethod,
    });
  };

  const handleSelect = (paymentMethod: PaymentMethodIds) => {
    setSelectedPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    if (checkoutRequestData.paymentMethod) {
      setSelectedPaymentMethod(checkoutRequestData.paymentMethod);
    }
  }, [checkoutRequestData.paymentMethod]);

  useEffect(() => {
    if (paymentMethodsAvailable) {
      let updatedPaymentMethods = [...paymentMethodsAvailable];

      if (
        (checkoutRequestData?.type === "ORDER" &&
          checkoutCalculations?.pay?.cannotCalculate?.reason ===
            CanNotCalculateReasons.DANGEROUS_GOODS_REQUIRE_MANAGER_DECISION) ||
        checkoutCalculations?.pay?.cannotCalculate?.reason ===
          CanNotCalculateReasons.SOME_PRODUCTS_PRICES_NOT_FOUND
      ) {
        updatedPaymentMethods = [
          ...paymentMethodsAvailable,
          PaymentMethodIds.CARD,
        ];
      }

      const paymentMethodsConfigs = updatedPaymentMethods.map(
        (paymentMethod) => {
          const foundPaymentMethodConfig = defaultPaymentMethods.find(
            (defaultPaymentMethodConfig) =>
              defaultPaymentMethodConfig.id === paymentMethod
          );

          return {
            ...foundPaymentMethodConfig,
            ...(foundPaymentMethodConfig?.id === PaymentMethodIds.CARD &&
              (checkoutCalculations?.pay?.cannotCalculate?.reason ===
                CanNotCalculateReasons.DANGEROUS_GOODS_REQUIRE_MANAGER_DECISION ||
                checkoutCalculations?.pay?.cannotCalculate?.reason ===
                  CanNotCalculateReasons.SOME_PRODUCTS_PRICES_NOT_FOUND) && {
                disabled: true,
              }),
          };
        }
      );

      setPaymentMethods(paymentMethodsConfigs);
    }
  }, [
    paymentMethodsAvailable,
    checkoutCalculations,
    checkoutRequestData?.type,
  ]);

  return (
    <Payment
      onSubmit={handleSubmit}
      paymentMethods={paymentMethods}
      selectedPaymentMethod={selectedPaymentMethod}
      handleSelect={handleSelect}
      isLoading={paymentMethodsAvailable?.loading}
    />
  );
};

export default StepPaymentContainer;
