import { DeliveryOptions } from "./delivery";
import { ProductWeight } from "./product";

export type PaymentDetails = {
  discountPercent: null | number;
  discountToPricePerOneCount: null | number;
  discountToTotal: null | number;
  priceExists: boolean;
  pricePerOneCount: number;
  total: number;
};

export type Currency = {
  abbr: string;
  symbol: string;
};

export type PricingDetails = {
  currency: CurrencyType;
  all: PriceDetails[];
  default: DefaultPrice;
};

export type PriceDetails = {
  weight: ProductWeight;
  price: number;
  delivery: DeliveryOptions;
};

export type DefaultPrice = {
  weight: ProductWeight;
  price: number;
  delivery: DeliveryOptions;
};

export enum CurrencyType {
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
}
